import React, { useEffect, useContext, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import MainTemplate from "../templates/MainTemplate"
import { List } from '@material-ui/core'
import InfoList from './InfoList'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as infoActions } from "../../hooks/useInfoReducer"
import CustomModal from "../common/CustomModal"
import FixedInfoList from './FixedInfoList'
import PageTitle from '../common/PageTitle'
import Pagination from '@material-ui/lab/Pagination'
import SITEINFO from "../../constants/siteInfo"

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const Info = React.memo((props) => {
  const history = useHistory()
  const location = useLocation()

  const { state: { info, loginUser }, dispatch } = useContext(GlobalContext)
  const { infoes, inputComments, comments, commentDisabled, } = info

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const perPage = 20
  const startNum = (page - 1) * perPage

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin) {
      // お知らせ・コメント取得
      infoActions.getInfos(dispatch)
      infoActions.getComments(dispatch)
    }
    return () => { dispatch({ type: 'COMMON_RESET' }) }
  }, [dispatch, history, loginUser.isLogin])

  useEffect(() => {
    if (loginUser.isLogin) {
      setTotalPages(Math.ceil(infoes.filter(info => info.type === 'info').length / perPage))
    }
  }, [infoes, loginUser.isLogin])


  return (
    <MainTemplate customTheme='info'>

      {/* モーダル */}
      <CustomModal />

      <PageTitle title={`${SITEINFO.adminName}からのお知らせ`} />

      <List>

        {/* 固定投稿 */}
        {
          infoes && infoes.filter(info => info.type === 'fixedInfo').map((content, key) => {
            return <FixedInfoList
              key={key}
              content={content}
              handleDispatch={(e) => dispatch(e)}
            />
          })
        }

        {/* 事例投稿 */}
        {
          infoes && infoes.filter(info => info.type === 'info').slice(startNum, startNum + perPage).map((content, key) => {
            const cmnts = comments.filter((cmnt) => cmnt.infoKey === content.key)
            return <InfoList
              hash={location.hash}
              key={key}
              content={content}
              inputComment={inputComments[content.key]}
              disabled={commentDisabled[content.key] === undefined ? true : commentDisabled[content.key]}
              comments={cmnts}
              handleDispatch={(e) => dispatch(e)}
            />
          })
        }

        <Pagination count={totalPages} size="large" page={page} onChange={(e, newPage) => setPage(newPage)} />

      </List>
    </MainTemplate >
  )
})
export default Info
