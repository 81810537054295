import React, { useEffect, useContext } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import MainTemplate from "../templates/MainTemplate"
import { Card, Checkbox, Container, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as shareCaseActions } from "../../hooks/useShereCaseReducer"
import CustomModal from "../common/CustomModal"

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

// const useStyles = makeStyles((theme) => ({
// }))

const AdminTags = React.memo((props) => {
  // const classes = useStyles()
  const history = useHistory()

  const { state: { loginUser, shareCase, admin }, dispatch } = useContext(GlobalContext)
  const { otherTagList, newTags } = shareCase
  const { checkNewTags } = admin

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin) {
      if (otherTagList.length <= 0) { shareCaseActions.getOtherTags(dispatch) }
      if (!newTags) { shareCaseActions.getNewTags(dispatch) }
    }
    return () => { dispatch({ type: 'COMMON_RESET' }) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loginUser.isLogin, otherTagList, newTags])

  return (
    <MainTemplate>

      {/* モーダル */}
      <CustomModal />

      <Container
        component={Card}
        style={{ margin: '40px 0' }}
      >

        <Typography
          variant='h6'
          style={{ margin: '20px 0 0' }}
        >
          タグの追加リクエスト一覧
        </Typography>

        <List>
        {
          newTags && Object.entries(newTags).map(([id, newTag], _i) => {
            return newTag.tag.map((_tag, i) => {

              return <ListItem id={id} key={i + _i}>
                <ListItemText
                  primary={_tag.label}
                  secondary={'user id: ' + newTag.userId}
                />
                <Checkbox
                  checked={checkNewTags[`${id}___${i}`]}
                  // checked={addMember.admin}
                  onClick={(e) => dispatch({
                    type: 'CHANGE_CHECK_NEW_TAGS',
                    data: e.target.checked,
                    id: id,
                    number: i,
                  })}
                />
                {/* <Tags tags={{ otherTagList }} customStyle='account' /> */}
              </ListItem>

            })
          })
        }
        </List>

      </Container>

    </MainTemplate >
  )
})
export default AdminTags
