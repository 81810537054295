import { functions } from '../firebase'
import anchorme from 'anchorme'
import { filterXSS } from 'xss'
import { vimeo } from "../config/vimeo"

import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

export const initialState = {
  common: {
    validation: {
      email: { isShowError: false, errorMsg: '', error: false, submit: false },
      name: { isShowError: false, errorMsg: '', error: false, submit: false },
      password: { isShowError: false, errorMsg: '', error: false, submit: false },
      companyName: { isShowError: false, errorMsg: '', error: false, submit: false },
      industries: { isShowError: false, errorMsg: '', error: false, submit: false },
      prefectures: { isShowError: false, errorMsg: '', error: false, submit: false },
      memberNum: { isShowError: false, errorMsg: '', error: false, submit: false },
      // companyUrl: { errorMsg: '', error: false, submit: false },
    },
    errors: {
      isError: false,
      message: '',
    },
    isLoading: false,
    progress: 0,
    disabled: true,
    isOpen: false,
    modalState: '', // 概要 : ../constants/modal
    deletePath: '',
    deleteUid: '',
    scrollTo: '',
    deleteVideo: null,
    searchTag: null, // 事例共有の検索タグ
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      console.log("RESET")
      return initialState.common
    case 'COMMON_RESET':
      return initialState.common
    case 'ERROR_RESET':
      return {
        ...state,
        errors: initialState.common.errors,
      }
    case 'ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          message: action.data,
          isError: true,
        },
      }
    case 'IS_LOADING':
      return {
        ...state,
        isLoading: action.data,
      }
    case 'PROGRESS':
      return {
        ...state,
        progress: action.data,
      }
    case 'PROGRESS_RESET':
      return {
        ...state,
        progress: 0,
        isLoading: false,
      }
    case 'CHANGE_DISABLED':
      return {
        ...state,
        disabled: action.data,
      }
    // モーダル
    case 'IS_OPEN':
      let deleteUid = ''
      if (!!action.data.deleteUid) { deleteUid = action.data.deleteUid }
      return {
        ...state,
        isOpen: action.data.bool,
        modalState: action.data.stage,
        deletePath: action.data.path,
        deleteUid: deleteUid,
        deleteVideo: !!action.data.video ? action.data.video : null,
      }
    case 'IS_CLOSE':
      return {
        ...state,
        isOpen: false,
        modalState: '',
        deletePath: '',
      }
    case 'CHANGE_MODAL_STAGE':
      return {
        ...state,
        modalState: action.data,
      }
    case 'CHANGE_SCROLL_TO':
      return {
        ...state,
        scrollTo: action.data,
      }
    /***************** ▽ バリデーション ▽ ******************/
    case 'VALIDATE_RESET':
      return {
        ...state,
        validation: {
          ...state.validation,
          [action.field]: {
            ...state.validation[action.field],
            isShowError: false,
          }
        },
      }
    case 'VALIDATE_SHOW_ERROR':
      // NOTE: フォーカスが外れたとき、エラーを表示させる
      return {
        ...state,
        validation: {
          ...state.validation,
          [action.field]: {
            ...state.validation[action.field],
            isShowError: state.validation[action.field].error,
          }
        },
      }
    // 常に
    case 'VALIDATE_NAME':
      const validateName = !action.data ?
        { error: true, errorMsg: '名前を入力してください', submit: false } :
        { error: false, errorMsg: '', submit: true }
      return {
        ...state,
        validation: {
          ...state.validation,
          name: {
            ...state.validation.name,
            ...validateName
          },
        },
      }
    case 'VALIDATE_EMAIL':
      const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

      const validateEmail = (!action.data || !regex.test(action.data)) ?
        { error: true, errorMsg: '正しい形式でメールアドレスを入力してください', submit: false } :
        { error: false, errorMsg: '', submit: true }
      return {
        ...state,
        validation: {
          ...state.validation,
          email: {
            ...state.validation.email,
            ...validateEmail
          },
        },
      }
    case 'VALIDATE_PASSWORD':
      let validatePassword = {}
      if (action.data.length < 6) {
        validatePassword = { error: true, errorMsg: 'パスワードは６文字以上で入力してください', submit: false }
      } else if (!action.confirm) {
        // NOTE: password || confirm が空のときは、エラーは出さない
        validatePassword = initialState.common.validation.password
      } else if (action.data !== action.confirm) {
        validatePassword = { error: true, errorMsg: 'パスワードが一致しません', submit: false }
      } else {
        validatePassword = { error: false, errorMsg: '', submit: true }
      }
      return {
        ...state,
        validation: {
          ...state.validation,
          password: {
            ...state.validation.password,
            ...validatePassword
          },
        },
      }
    case 'VALIDATE_COMPANY_NAME':
      const validateCompanyName = !action.data ?
        { error: true, errorMsg: '会社名を入力してください', submit: false } :
        { error: false, errorMsg: '', submit: true }
      return {
        ...state,
        validation: {
          ...state.validation,
          companyName: {
            ...state.validation.companyName,
            ...validateCompanyName
          },
        },
      }
    case 'VALIDATE_INDUSTRIES':
      const validateIndustries = action.data.length <= 0 ?
        { error: true, errorMsg: '業界・業種を1つ以上選択してください', submit: false } :
        { error: false, errorMsg: '', submit: true }
      return {
        ...state,
        validation: {
          ...state.validation,
          industries: {
            ...state.validation.industries,
            ...validateIndustries
          },
        },
      }
    case 'VALIDATE_PREFECTURES':
      const validatePrefectures = action.data.length <= 0 ?
        { error: true, errorMsg: '所在地を1つ以上選択してください', submit: false } :
        { error: false, errorMsg: '', submit: true }
      return {
        ...state,
        validation: {
          ...state.validation,
          prefectures: {
            ...state.validation.prefectures,
            ...validatePrefectures
          },
        },
      }
    case 'VALIDATE_MEMBER_NUM':
      const validateMemberNum = Object.keys(action.data).length <= 0 ?
        { error: true, errorMsg: '社員数を選択してください', submit: false } :
        { error: false, errorMsg: '', submit: true }
      console.log("validateMemberNum", validateMemberNum);
      return {
        ...state,
        validation: {
          ...state.validation,
          memberNum: {
            ...state.validation.memberNum,
            ...validateMemberNum
          },
        },
      }
    /***************** △ バリデーション △ ******************/
    case 'CHANGE_SEARCH_TAG':
      return {
        ...state,
        searchTag: action.data,
      }
    default: return state
  }
}

export const actions = {
  sendMail: (data, type, doNotify ) => {
    // NOTE: 事務局が全体の通知を許可してない場合、何もしない
    if (!doNotify) return

    let func = null
    const isMailNameType =
      (type === 'sendUserCreatedMail') ||
      (type === 'sendUserChangeMail')
    if (isMailNameType) {
      func = functions.httpsCallable(type)
      const { email, name } = data
      func({ name: name, email: email })
        .then(res => { console.log(res) })
        .catch(e => { console.log(e) })
    }
  },
  editable: (contentUser, loginUser) => {
    const isLoginUser = contentUser.uid === loginUser.uid
    const isPajaposs = loginUser.pajaposs

    const contetCompanyId = contentUser.companyId || contentUser.companyInfo.companyId
    const isCompanyAdmin = loginUser.admin && (contetCompanyId === loginUser.companyInfo.companyId)

    // if (process.env.NODE_ENV === 'development') {
    //   console.log("contentUser", contentUser)
    //   console.log("loginUser", loginUser)
    //   console.log("isLoginUser", isLoginUser)
    //   console.log("isCompanyAdmin", isCompanyAdmin)
    //   console.log("isPajaposs", isPajaposs)
    // }

    return isLoginUser || isCompanyAdmin || isPajaposs
  },
  changeToURL: (text, is_change = true, forcedChangeURL = false) => {
    // NOTE: https://1k6a.com/blog/react-with-safety-anchor-tag#anchorme
    if (!text) return ''

    if (!is_change && !forcedChangeURL) return { __html: text }

    const htmlText = anchorme({
      input: text,
      options: {
        attributes: () => {
          const attributes = {
            target: '_blank',
            rel: 'noopener noreferrer'
          }
          return attributes
        }
      }
    })

    const _xss = {
      __html: filterXSS(htmlText, {
        whiteList: {
          a: ['href', 'title', 'target', 'rel'],
        }
      })
    }

    return _xss
  },

  uploadVimeo: (dispatch, e, user, contentKey = '', type = 'case') => {
    dispatch({ type: 'IS_LOADING', data: true })

    // https://coders-shelf.com/react-firebase-image-upload/
    e.preventDefault()

    const movie = e.target.files[0]
    if (!movie) {
      console.log("not select")
      dispatch({ type: 'PROGRESS_RESET' })
      dispatch({ type: 'ERROR', data: 'ファイルが存在しません。' })
      return
    }

    // ファイル名
    const now = dayjs().tz().format('YYYYMMDDHHmmss')
    const companyName = user.company
    const fileName = `${now}_${type}_${companyName}_${movie.name}`

    // NOTE:　エラーテストする時、movieの代わり使う
    // eslint-disable-next-line no-unused-vars
    const errorTest = "error"

    // vimeoアップロード
    vimeo.upload(
      movie,
      {
        'name': fileName
      },
      (uri) => {
        // complete
        console.log('Your video URI is: ' + uri)

        // 閲覧権限変更
        vimeo.request({
          method: 'PATCH',
          path: uri,
          query: { 'privacy': { 'view': 'disable' } }
        }, (error, body, status_code, headers) => {
          if (error) {
            console.log("error", error)
            // TODO: error.errorはおかしいのでは？
            dispatch({ type: 'PROGRESS_RESET' })
            dispatch({ type: 'ERROR', data: error.error })
          } else {
            console.log(uri + ' will now change auth to be viewed on Vimeo.')
            if (type === 'case') dispatch({ type: 'SET_VIMEO_URL', data: uri })
            if (type === 'caseComment') dispatch({ type: 'SET_CASE_COMMENT_VIMEO_URL', data: uri, field: contentKey })
          }
        })
        // start(uri)
        if (type === 'case') dispatch({ type: 'SET_THUMBNAIL_URL', data: "https://i.vimeocdn.com/video/default_100x75?r=pad" })
        if (type === 'caseComment') dispatch({ type: 'SET_CASE_COMMENT_THUMBNAIL_URL', data: "https://i.vimeocdn.com/video/default_100x75?r=pad", field: contentKey })
        dispatch({ type: 'PROGRESS_RESET' })
      },
      (bytes_uploaded, bytes_total) => {
        // progress
        var percentage = (bytes_uploaded / bytes_total * 100).toFixed(2)
        console.log(bytes_uploaded, bytes_total, percentage + '%')
      },
      (error) => {
        console.log('Failed because: ' + error)
        dispatch({ type: 'PROGRESS_RESET' })
        dispatch({ type: 'ERROR', data: error })
      }
    )
  },

  deleteVimeo: (dispatch, uri, video_num, contentKey = '', type = 'case') => {
    vimeo.request({
      method: 'DELETE',
      path: uri
    }, (error, body, status_code, headers) => {
      if (error) {
        console.log('error', error)
        dispatch({ type: 'ERROR', data: error })
      } else {
        if (type === 'case') dispatch({ type: 'RESET_VIDEO', num: video_num })
        if (type === 'caseComment') dispatch({ type: 'RESET_CASE_COMMENT_VIDEO', num: video_num, field: contentKey })
      }
    })
  },

  deleteAllVimeo: (dispatch, video) => {
    video.vimeoUrls.forEach(uri => {
      vimeo.request({
        method: 'DELETE',
        path: uri
      }, (error, body, status_code, headers) => {
        if (error) {
          console.log('error', error)
          dispatch({ type: 'ERROR', data: error })
        }
      })
    })
  },
}
