import React from 'react'
import SITEINFO from "../../constants/siteInfo"
import {
  Box,
  Link as LinkStyle,
  Typography,
} from '@material-ui/core'

const Copyright = React.memo(() => {
  return (
    <Box mt={8}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <LinkStyle
          color="inherit"
          href={SITEINFO.url}
          target="_blank"
          rel="noopener"
        >
          {SITEINFO.campanyName}
        </LinkStyle>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  )
})

export default Copyright
