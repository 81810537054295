import React, { useState, useContext, useEffect } from 'react';
import { parse } from 'papaparse';
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import {
  Paper,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { saveAs } from 'file-saver';
import { GlobalContext } from '../../hooks/reducer';
import { actions as adminActions } from "../../hooks/useAdminReducer";
import MainTemplate from '../templates/MainTemplate'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import PageTitle from '../common/PageTitle'
import { functions } from '../../firebase';

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: '1px rgba(224, 224, 224, 1) solid',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  formControl: {
  },
  companySelect: {
    minWidth: '140px',
    marginRight: '10px',
  },
  detailedPanel: {
    margin: '50 0 20 0', 
    textAlign: 'left',
    padding: '21px',
    borderRadius: '10px',
    backgroundcolor: 'antiquewhite',
  },
}))

const ImportUsers = React.memo((props) => {
  const classes = useStyles()

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [totalUsers, setTotalUsers] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [allUsers, setAllUsers] = useState([]);

  const { state: { admin }, dispatch } = useContext(GlobalContext);
  const { companies } = admin;

  useEffect(() => {
    if (companies.length <= 0) {
      adminActions.getCompanies(dispatch);
    }
  }, [dispatch, companies]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (loading) {
        const message = '処理が終わるまで閉じないでください。途中で閉じると正しく登録できません。';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [loading]);


  useEffect(() => {
    let interval;
    if (loading) {
      let elapsedSeconds = 0;
      interval = setInterval(() => {
        elapsedSeconds += 1;
        setProgress((prevProgress) => {
          if (elapsedSeconds <= 3) {
            return Math.min(prevProgress + 5, 95);
          } else {
            return Math.min(prevProgress + 1, 95);
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [loading]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleCSVUpload = async () => {
    if (file && selectedCompany) {
      setLoading(true); // ローディング状態を開始
      setCompleted(false); // 完了メッセージをリセット
      setTotalUsers(0);
      setAllUsers([]);
      setProgress(0);

      parse(file, {
        header: true,
        complete: async (results) => {
          const users = results.data.map(user => ({
            email: user['メールアドレス'],
            name: user['スタッフ名 or 部署名'],
            password: user['パスワード（半角６文字以上）'],
            admin: user['管理者である'] === 'true' || user['管理者である'] === 'TRUE',
          }));

          setTotalUsers(users.length);

          const callable = functions.httpsCallable('bulkCreateUsers');
          try {
            const result = await callable({ 
              users, 
              companyInfo: companies.find(company => company.companyId === selectedCompany) 
            });

            const { results } = result.data;

            const csvContent = [
              ['管理者である', 'スタッフ名 or 部署名', 'メールアドレス', 'パスワード', 'status', 'errorMessage'],
              ...results.map(user => [user.admin, user.name, user.email, user.password, user.status, user.errorMessage])
            ].map(e => e.join(",")).join("\n");

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'ユーザー一括登録結果.csv');
            
            setAllUsers(results);
            
          } catch (error) {
            console.error("Error in bulk user registration:", error);

            const csvContent = [["失敗しました"]]

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'ユーザー一括登録結果.csv');
            
          } finally {
            
            setLoading(false); // ローディング状態を終了
            setCompleted(true); // 完了メッセージを表示
            setProgress(100);
          }
        },
      });
    }
  };

  const handleSampleDownload = () => {
    const sampleCSV = `No,管理者である,スタッフ名 or 部署名,メールアドレス,パスワード（半角６文字以上）
1,true,User One,user1@example.com,pass1234
2,false,User Two,user2@example.com,pass5678`;

    const blob = new Blob([sampleCSV], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'sample_users.csv');
  };

  return (
    <MainTemplate mainwidth={isWidthUp('sm', props.width) ? 'auto' : ''}>
      <PageTitle title='メンバー一括追加' />

      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant='h6'>▼メンバーを追加する</Typography>
          
          {/* 会社選択プルダウン */}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-company-label">会社を選択</InputLabel>
            <Select
              labelId="select-company-label"
              id="select-company"
              value={selectedCompany}
              onChange={handleCompanyChange}
              label="会社を選択"
              className={classes.companySelect}
            >
              {companies.map((company) => (
                <MenuItem key={company.companyId} value={company.companyId}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* ボタン */}
          <div>
            <input type="file" accept=".csv" onChange={handleFileChange} />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCSVUpload}
              disabled={!file || loading} // ファイルが選択されていないときは無効化
            >
              CSVから一括登録
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSampleDownload}
              style={{ marginLeft: 10 }}
            >
              サンプルCSVをダウンロード
            </Button>
          </div>

          {/* ローディング・完了メッセージ */}
          <div style={{ marginTop: 20, textAlign: 'center' }}>
            {loading && <CircularProgress />}
            {loading && 
              <Typography variant="h6" style={{ color: 'red', marginTop: 10 }}>
                処理が終わるまで閉じないでください。途中で閉じると正しく登録できません。
              </Typography>
            }

            {completed && (
              <Typography variant="h6" style={{ color: 'green', marginTop: 10 }}>
                処理が完了しました。<br></br>
                登録結果は、ダウンロードされたCSVをご参照ください。
              </Typography>
            )}
          </div>

          {totalUsers > 0 && (
            <div className={classes.detailedPanel}>
              <Typography variant="body1" style={{ marginTop: 10, marginBottom: 10, fontWeight: "bold" }}>
                ■ 進捗状況・詳細 ■
              </Typography>

              <div style={{ marginTop: 15, marginBottom: 30 }}>
                <Typography variant="body1" style={{ marginTop: 10 }}>
                  アップロードされたCSV: 全{totalUsers}件
                </Typography>

                <Typography variant="body1" style={{ marginTop: 10 }}>
                  進捗: {Math.round(progress)}%
                </Typography>

                <LinearProgress variant="determinate" value={progress} />
              </div>
              
              <div style={{ marginTop: 15, marginBottom: 15 }}>
                <Typography variant="body1" style={{ marginTop: 10 }}>
                  エラーが発生したユーザー:
                </Typography>

                {allUsers.filter(user => user.status === '失敗').length > 0 && (
                  <ul>
                    {allUsers.filter(user => user.status === '失敗').map((user, index) => (
                      <li key={index}>{user.email} - {user.errorMessage}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}  
          
        </Paper>
      </div>
    </MainTemplate>
  );
});

export default withWidth()(ImportUsers)