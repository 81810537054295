import React, { useEffect, useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import MainTemplate from "../../templates/MainTemplate"
import { Avatar, Grid, Link, List, Typography } from '@material-ui/core'
import CaseList from '../CaseList'
import { GlobalContext } from '../../../hooks/reducer'
import { actions } from "../../../hooks/useLoginUserReducer"
import { actions as shareCaseActions } from "../../../hooks/useShereCaseReducer"
import CustomModal from "../../common/CustomModal"
import Pagination from '@material-ui/lab/Pagination'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '70px',
    height: '70px',
  }
}))

const CompanyPage = React.memo((props) => {
  const { companyId } = useParams()
  if (!companyId) { return <MainTemplate></MainTemplate> }

  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { state: { shareCase, loginUser, common }, dispatch } = useContext(GlobalContext)
  const {
    cases, inputComments, caseComments, company
  } = shareCase
  const { searchTag } = common

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const perPage = 5
  const startNum = (page - 1) * perPage

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin) {
      console.log('companyId', companyId)
      shareCaseActions.moutedGetData(dispatch, loginUser.user.uid, companyId)
      shareCaseActions.getCompany(dispatch, companyId)
    }
    return () => { 
      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'CASE_RESET' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, loginUser.isLogin])

  // useEffect(() => {
  //   console.log("check search")
  //   shareCaseActions.filterCases(dispatch, searchTag, cases)
  // }, [dispatch, searchTag, cases])
  
  useEffect(() => {
    if (loginUser.isLogin && cases) {
      setTotalPages(Math.ceil(cases.length / perPage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser.isLogin, cases])
  
  if (!loginUser.isLogin) { return <MainTemplate></MainTemplate> }

  return (
    <MainTemplate>

      {/* モーダル */}
      <CustomModal />

      <div>
        <Grid container direction='column' alignItems='center'>
          <Grid item component={Avatar} sizes='large' src={company.imageUrl} className={classes.icon} />
          <Grid item component={Typography} variant='h5'>{company.name}</Grid>
        </Grid>
        <Link href={company.url} target='_blank' rel="noopener">
          <Typography component='p' variant='caption' align='center'>
            {company.url}
          </Typography>
        </Link>
        <Typography component='p' variant='caption' className='indention' align='center'>
          {company.description}
        </Typography>
      </div>

      { cases.length > 0 && <Pagination count={totalPages} size="large" page={page} onChange={(e, newPage) => setPage(newPage)} /> }

      <List className={classes.list}>
        {cases && <React.Fragment>
          {
            cases.length <= 0 ? (
              !searchTag ? '' : '検索結果は0件です。'
            ) : (
              cases.slice(startNum, startNum + perPage).map((content, key) => {
                const comments = caseComments.filter((cmnt) => cmnt.caseKey === content.key)
                return <CaseList
                  hash={location.hash}
                  key={key}
                  content={content}
                  inputComment={inputComments[content.key]}
                  comments={comments}
                  handleDispatch={(e) => dispatch(e)}
                />
              })
            )
          }
        </React.Fragment>}
      </List>

      { cases.length > 0 && <Pagination count={totalPages} size="large" page={page} onChange={(e, newPage) => setPage(newPage)} /> }

    </MainTemplate >
  )
})
export default CompanyPage
