import React, { useEffect, useContext } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Avatar,
  Card,
  Container,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import MainTemplate from '../templates/MainTemplate'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as consultActions } from "../../hooks/useConsultReducer"
import CustomModal from '../common/CustomModal'
import PageTitle from '../common/PageTitle'
import Caution from "./common/Caution";

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const content = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const useStyles = makeStyles((theme) => ({
  list: {
    cursor: 'pointer',
    '& > :hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  },
  content: {
    marginBottom: 0,
    '& > span': content,
    '& > p': content,
  },
  capWrap: {
    marginTop: '7px'
  },
  caption: {
    width: '100%',
    margin: 0,
    textAlign: 'right',
    padding: '0 16px',
    lineHeight: 1
  }
}))

const Consult = React.memo((props) => {
  const history = useHistory()
  const classes = useStyles()

  const { state: { loginUser, consult, common }, dispatch } = useContext(GlobalContext)
  const { isOpen } = common
  const { consults } = consult

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    console.log('初回')
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin) {
      if (consults.length <= 0) {
        consultActions.getConsults(dispatch)
        consultActions.getComments(dispatch)
        consultActions.getGoods(dispatch)
      }
    }
    return () => { dispatch({ type: 'COMMON_RESET' }) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser.isLogin])

  if (!loginUser.isLogin) { return <MainTemplate></MainTemplate> }

  return (
    <MainTemplate>
      {isOpen && <CustomModal />}

      <PageTitle title='相談部屋' />

      {/* 注意書き */}
      <Caution />

      <Container
        component={Card}
        style={{ marginTop: '40px' }}
      >

        <List className={classes.list}>
          {
            consults.map((consult, i) => {
              return <li
                key={i}
                onClick={() => {
                  dispatch({ type: 'SET_CONSULT', data: consult })
                  history.push(`/consult/${consult.key}`)
                }}
              >

                {/* タイトル・内容 */}
                <div style={{ paddingBottom: 0 }}>
                  <ListItem
                    style={{ paddingBottom: 0 }}
                    component='div'
                  >
                    <ListItemIcon>
                      <Avatar src={consult.user.imageUrl} />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.content}
                      primary={consult.title}
                      secondary={consult.text}
                    />
                  </ListItem>
                </div>

                {/* 投稿者・日時 */}
                <div className={classes.capWrap}>
                  <Typography
                    component='p'
                    className={classes.caption}
                    variant='caption'
                  >
                    {dayjs(consult.updateAt).tz().format('YYYY年MM月DD日 HH:mm')}
                  </Typography>
                  <p className={classes.caption} style={{ marginBottom: '14px' }}>
                    <Typography variant='caption' style={{ marginRight: '5px' }}>
                      {consult.user.name}
                    </Typography>
                    <Typography variant='caption'>
                      {consult.user.companyInfo.name}
                    </Typography>
                  </p>
                </div>

                <Divider component="div" />
              </li>
            })
          }
        </List>

      </Container>

    </MainTemplate >
  )
})
export default withWidth()(Consult)
