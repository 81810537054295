import React, { useEffect, useRef, useContext } from 'react'
// import STYLES from "../../constants/styles"
// import SITEINFO from "../../constants/siteInfo"
// import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Avatar,
  TextField,
  Grid,
} from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import MainTemplate from '../templates/MainTemplate'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as accountActions } from "../../hooks/useAccountReducer"
import { actions as signupActions } from "../../hooks/useSignUpReducer"
import { actions as commonActions } from "../../hooks/useCommonReducer"
import CustomModal from '../common/CustomModal'
import PageTitle from '../common/PageTitle'
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@material-ui/icons'
import MODAL from '../../constants/modal'
import { commonTheme } from '../../materialui/theme'
import { AvatarLink } from '../common/AvatarLink'
import MemberAdd from './MemberAdd'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: '1px rgba(224, 224, 224, 1) solid',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const EnhancedTableHead = (props) => {
  const { isOpenMemberPassword, add } = props
  const pwMinWidth = isOpenMemberPassword ? '230px' : '110px'

  let headCells = [
    { id: 'imageUrl', align: 'left', label: 'アイコン', style: {}, padding: 'default' },
    { id: 'name', align: 'left', label: 'スタッフ・部署名', style: { minWidth: '170px' }, padding: 'checkbox' },
    { id: 'admin', align: 'left', label: '管理者', style: { minWidth: '72px' }, padding: 'checkbox' },
    { id: 'email', align: 'left', label: 'メールアドレス', style: { minWidth: '250px' }, padding: 'checkbox' },
    { id: 'password', align: 'left', label: 'パスワード', style: { minWidth: pwMinWidth }, padding: 'checkbox' },
  ]

  if (!add) {
    headCells.push(
      { id: 'edit', align: 'left', label: '編集', style: {}, padding: 'checkbox' },
      { id: 'delete', align: 'left', label: '', style: {}, padding: 'checkbox' }
    )
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.padding}
            style={headCell.style}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const AddMemberButton = (props) => {
  const { isAddMember, dispatch } = props
  return <Button
    variant={isAddMember ? "outlined" : "contained"}
    color="secondary"
    onClick={() => dispatch({ type: 'IS_ADD_MEMBER', data: !isAddMember })}
  >
    {
      isAddMember ?
        <React.Fragment><RemoveIcon /> 追加をやめる</React.Fragment> :
        <React.Fragment><AddIcon /> メンバーを追加する</React.Fragment>
    }
  </Button>
}

const Members = React.memo((props) => {
  const ref = useRef()
  const history = useHistory()
  const classes = useStyles()
  const { state: { loginUser, common, account, admin }, dispatch } = useContext(GlobalContext)
  const { validation, isOpen } = common
  const { doNotify } = admin
  const {
    members, editMember, editMemberPassword, isOpenMemberPassword,
    isAddMember, addMemberDisabled, addMember, addMemberPassword, addSubmit
  } = account

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin) {
      if (members.length === 0) {
        accountActions.setMember(dispatch, loginUser.user.companyInfo.companyId)
      }
    }
    return () => dispatch({ type: 'COMMON_RESET' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, loginUser.isLogin, loginUser.user.companyInfo.companyId])

  // input監視
  useEffect(() => {
    if (editMember.isEdit) {
      const unallowed = (!editMember.submit.name || !editMember.submit.email)
      const unallowedPass = isOpenMemberPassword ? !validation.password.submit : false
      if (unallowed || unallowedPass) {
        // ボタン無効
        !editMember.disabled && dispatch({ type: 'CHANGE_MY_DISABLED', data: true, field: 'editMember' })
      } else {
        // ボタン有効
        editMember.disabled && dispatch({ type: 'CHANGE_MY_DISABLED', data: false, field: 'editMember' })
      }
    }

    if (isAddMember) {
      const unallowedAdd = (!addSubmit.name || !addSubmit.email)
      const unallowedPassAdd = !validation.password.submit
      if (unallowedAdd || unallowedPassAdd) {
        // ボタン無効
        !addMemberDisabled && dispatch({ type: 'CHANGE_ADD_MEMBER_DISABLED', data: true })
      } else {
        // ボタン有効
        addMemberDisabled && dispatch({ type: 'CHANGE_ADD_MEMBER_DISABLED', data: false })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch, validation.password.submit, editMember, isOpenMemberPassword,
    isAddMember, addSubmit
  ])

  useEffect(() => {
    if (!isAddMember) { dispatch({ type: 'HIDDEN_ADD_MEMBER' }) }
    if (isAddMember) {
      ref.current.scrollIntoView({ behavior: "smooth" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAddMember])

  if (!loginUser.isLogin || !loginUser.user.admin) { return <MainTemplate></MainTemplate> }

  const handleChangePage = (e, newPage) => { setPage(newPage) }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handlePost = async (currentUser, editUser) => {
    let updateAuth = {}

    // メールアドレス・パスワードは、firebase authを変更
    if (isOpenMemberPassword) {
      updateAuth.password = editMemberPassword.password
    }
    if (currentUser.email !== editUser.email) {
      updateAuth.email = editUser.email
      updateAuth.emailVerified = false
    }

    // 処理
    try {
      dispatch({
        type: 'IS_OPEN',
        data: { bool: true, stage: MODAL.progress, path: '' }
      })
      dispatch({ type: 'PROGRESS', data: 10 })
      await accountActions.updateMember(dispatch, editUser.uid, updateAuth, editUser)

      // 画像を変更した場合、元の画像削除
      if (!!currentUser.imageName && currentUser.imageName !== editUser.imageName) {
        signupActions.deleteImage(`profile/${currentUser.imageName}`)
      }

      if (loginUser.user.uid === editUser.uid) {
        dispatch({ type: 'SET_LOGIN_USER', data: editUser })
      }

      // NOTE: メールアドレス・パスワードが変わったときのみ通知
      if (currentUser.email !== editUser.email || isOpenMemberPassword) {
        commonActions.sendMail({ email: editUser.email, name: editUser.name }, 'sendUserChangeMail', doNotify)
      }

      dispatch({ type: 'PROGRESS', data: 90 })
      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'HIDDEN_EDIT_MEMBER' })
    } catch (error) {
      console.log(error.message)
      dispatch({ type: 'PROGRESS_RESET' })
      dispatch({ type: 'ERROR', data: error.message })
      dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.error })
    }
  }

  return (
    <MainTemplate mainwidth={isWidthUp('sm', props.width) ? 'auto' : ''}>
      {isOpen && <CustomModal />}

      <PageTitle title='メンバー管理' />

      {/* メンバー追加ボタン */}
      <AddMemberButton isAddMember={isAddMember} dispatch={dispatch} />

      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='medium'
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                isOpenMemberPassword={isOpenMemberPassword}
              />
              <TableBody>
                {members
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`
                    const isEdit = !!editMember.uid ? editMember.isEdit : false
                    const editUser = isEdit ? editMember.user : {}
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.uid}
                      >

                        {/* アバター */}
                        <TableCell align='center'>
                          {editMember.uid === row.uid && <React.Fragment>
                            <Avatar src={editUser.imageBase64} className={classes.avatar} />
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => accountActions.setPreview(dispatch, e, 'member')}
                              style={{ margin: '10px 0', display: 'block' }}
                            />
                            <AvatarLink />
                          </React.Fragment>}
                          {editMember.uid !== row.uid && <Avatar src={row.imageUrl} className='comment_avatar' />}
                        </TableCell>

                        {/* 名前 */}
                        <TableCell component="th" id={labelId} scope="row" padding='checkbox'>
                          {editMember.uid === row.uid && <TextField
                            value={editUser.name}
                            onChange={(e) => {
                              dispatch({
                                type: 'CHANGE_EDIT_MEMBER',
                                data: e.target.value,
                                field: 'name',
                              })
                              dispatch({ type: 'VALIDATE_NAME', data: e.target.value })
                            }}
                            onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'name' })}
                            onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'name' })}
                            variant="outlined"
                            required
                            className={classes.form}
                            error={validation.name.isShowError}
                            helperText={validation.name.isShowError ? validation.name.errorMsg : ''}
                          />}
                          {editMember.uid !== row.uid && row.name}
                        </TableCell>

                        {/* 管理者権限 */}
                        <TableCell padding="checkbox">
                          {editMember.uid === row.uid && <Checkbox
                            checked={editUser.admin}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={(e) => dispatch({
                              type: 'CHANGE_EDIT_MEMBER',
                              data: e.target.checked,
                              field: 'admin',
                            })}
                          />}
                          {editMember.uid !== row.uid && (row.admin ? <CheckIcon /> : '')}
                        </TableCell>

                        {/* メールアドレス */}
                        <TableCell padding='checkbox'>
                          {editMember.uid === row.uid && <TextField
                            value={editUser.email}
                            onChange={(e) => {
                              dispatch({
                                type: 'CHANGE_EDIT_MEMBER',
                                data: e.target.value,
                                field: 'email'
                              })
                              dispatch({ type: 'VALIDATE_EMAIL', data: e.target.value })
                            }}
                            onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'email' })}
                            onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'email' })}
                            variant="outlined"
                            required
                            className={classes.form}
                            error={validation.email.isShowError}
                            helperText={validation.email.isShowError ? validation.email.errorMsg : ''}
                          />}
                          {editMember.uid !== row.uid && row.email}
                        </TableCell>

                        <TableCell
                          padding='checkbox'
                          colSpan={editMember.uid !== row.uid ? 1 : 2}
                        >
                          {editMember.uid === row.uid && <Grid container>
                            <Button
                              color="primary"
                              style={{ margin: 0 }}
                              checked={!!row.admin}
                              onClick={() => dispatch({
                                type: 'CHANGE_IS_OPEN_MEMBER_PASSWORD',
                                data: !isOpenMemberPassword,
                                uid: row.uid
                              })}
                            >
                              {isOpenMemberPassword ? 'パスワードの変更をやめる' : 'パスワードを変更する'}
                            </Button>
                            {isOpenMemberPassword && <React.Fragment>
                              <Grid item component={TextField}
                                label="パスワード"
                                type="password"
                                value={editMemberPassword.password}
                                onChange={(e) => {
                                  dispatch({
                                    type: 'CHANGE_EDIT_MEMBER_PASSWORD',
                                    data: e.target.value,
                                    field: 'password',
                                  })
                                  dispatch({ type: 'VALIDATE_PASSWORD', data: e.target.value, confirm: editMemberPassword.passwordConfirm })
                                }}
                                onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'password' })}
                                onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'password' })}
                                variant="outlined"
                                required
                                error={validation.password.isShowError}
                                className={'signform_input'}
                              />
                              <Grid item component={TextField}
                                label="パスワード再入力"
                                type="password"
                                value={editMemberPassword.passwordConfirm}
                                onChange={(e) => {
                                  dispatch({
                                    type: 'CHANGE_EDIT_MEMBER_PASSWORD',
                                    data: e.target.value,
                                    field: 'passwordConfirm'
                                  })
                                  dispatch({ type: 'VALIDATE_PASSWORD', data: e.target.value, confirm: editMemberPassword.password })
                                }}
                                onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'password' })}
                                onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'password' })}
                                variant="outlined"
                                required
                                error={validation.password.isShowError}
                                helperText={validation.password.isShowError ? validation.password.errorMsg : ''}
                                className={'signform_input'}
                              />
                            </React.Fragment>}
                          </Grid>}
                          {editMember.uid !== row.uid && '****'}
                        </TableCell>

                        {/* 編集 */}
                        <TableCell align='right' padding='checkbox'>
                          {editMember.uid !== row.uid && <Button
                            variant="contained"
                            color="primary"
                            onClick={() => dispatch({
                              type: 'IS_SHOW_EDIT_MEMBER', data: row
                            })}
                          >編集</Button>}
                          {editMember.uid === row.uid && <Button
                            variant="contained"
                            color='default'
                            onClick={() => dispatch({ type: 'HIDDEN_EDIT_MEMBER' })}
                          ><CloseIcon /></Button>}
                        </TableCell>

                        {/* 削除ボタン */}
                        {<TableCell>
                          {editMember.uid !== row.uid && <Button
                            variant="contained"
                            style={{
                              backgroundColor: commonTheme.palette.error.main,
                              color: '#ffffff',
                            }}
                            onClick={() => dispatch({
                              type: 'IS_OPEN',
                              data: {
                                bool: true,
                                stage: MODAL.willMemberDelete,
                                path: `users/${row.userId}`,
                                deleteUid: row.uid
                              }
                            })}
                          >削除</Button>}
                          {editMember.uid === row.uid && <Button
                            variant="contained"
                            color="secondary"
                            disabled={editMember.disabled}
                            onClick={() => handlePost(row, editMember.user)}
                          >確定</Button>}
                        </TableCell>}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* テーブルページネーション */}
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            component="div"
            count={members.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </Paper>

        {/* メンバー追加 */}
        {isAddMember && <MemberAdd
          dispatch={dispatch}
          classes={classes}
          addMember={addMember}
          addMemberPassword={addMemberPassword}
          addMemberDisabled={addMemberDisabled}
          loginUser={loginUser}
          validation={validation}
          EnhancedTableHead={EnhancedTableHead}
          doNotify={doNotify}
        />}
        <div ref={ref} id='to_scroll' style={{ width: '100%' }}></div>
        <AddMemberButton isAddMember={isAddMember} dispatch={dispatch} />
      </div>

    </MainTemplate >
  )
})

export default withWidth()(Members)
