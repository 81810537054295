import React from 'react'
// import React, { useContext, useCallback, useEffect, useRef, useState } from 'react'
// import Select from 'react-select'
// import { FormControl, TextField } from '@material-ui/core'
// import { database, functions } from '../firebase/index'
// import { vimeo } from '../config/vimeo'
// import { GlobalContext } from '../hooks/reducer'
// import { actions } from '../hooks/useLoginUserReducer'
// import { useHistory } from 'react-router'
// import { industryTagList, memberNumTagList, prefTagList } from '../constants/tags'

// const style = {
//   cursor: 'pointer',
//   backgroundColor: 'red',
//   margin: '10px',
//   padding: '10px',
//   color: 'white',
// }

export default () => {
  // if (process.env.NODE_ENV !== "development") { return <div></div> }
  // if (process.env.NODE_ENV === "development") { console.log("develop") }

  // const history = useHistory()

  // const [commentKey, setCommentKey] = useState('')
  // const [msg, setMsg] = useState('')
  // const [isLoading, setIsLoading] = useState(true)

  // const { state: { loginUser }, dispatch } = useContext(GlobalContext)
  // const { user } = loginUser

  // const handleFunction = async () => {
  //   //参照？を取得
  //   const func = functions.httpsCallable("helloWorld")
  //   //引数を付けて呼び出し
  //   func({ name: 'hoge' }).then(res => {
  //     console.log(res);
  //   }).catch(e => {
  //     console.log(e);
  //   })
  // }

  // const handleUpdateMember = async () => {
  //   let editMemberPassword = {}
  //   let updateUser = {}
  //   let editUser = {}
  //   let currentUser = {}

  //   const uid = 'TtTc7OgUvKY2eoic49pPHBcr0tv2'
  //   editMemberPassword.password = 'watanabenozomi1231'
  //   editUser.email = 'testtest11111@gmail.com'

  //   // 更新しない
  //   // currentUser.email = editUser.email
  //   // const isOpenMemberPassword = false

  //   // 更新する
  //   currentUser.email = ''
  //   const isOpenMemberPassword = true

  //   if (isOpenMemberPassword) {
  //     updateUser.password = editMemberPassword.password
  //   }
  //   if (currentUser.email !== editUser.email) {
  //     updateUser.email = editUser.email
  //     updateUser.emailVerified = false
  //   }

  //   console.log("updateUser", updateUser)

  //   const func = functions.httpsCallable("updateMember")
  //   //引数を付けて呼び出し
  //   func({ data: updateUser, uid: uid }).then(res => {
  //     console.log(res)
  //   }).catch(e => {
  //     console.log(e)
  //   })
  // }

  // const handleDeleteCmnt = async (key) => {
  //   let deletes = {}
  //   deletes[`${key}`] = null
  //   console.log("deletes", deletes)
  //   // await database.ref('consultComments').update({ [key]: null })
  //   await database.ref('consultComments').update(deletes)
  //   setMsg('完了')
  // }

  // const handleUploadVimeo = (e) => {
  //   e.preventDefault()
  //   const movie = e.target.files[0]
  //   if (!movie) { return console.log("not select") }
  //   const random = Math.random().toString(36).slice(-4)
  //   const fileName = `${movie.lastModified}-${random}-${movie.name}`
  //   vimeo.upload(
  //     movie,
  //     { 'name': fileName },
  //     async (uri) => {
  //       console.log('Your video URI is: ' + uri)
  //       vimeo.request({ method: 'PATCH', path: uri, query: { 'privacy': { 'view': 'disable' } } },
  //         (error, body, status_code, headers) => {
  //           if (error) { console.log("error", error) } else { console.log(uri + ' will now change auth to be viewed on Vimeo.') }
  //         })
  //       start(uri)
  //     },
  //     (bytes_uploaded, bytes_total) => {
  //       var percentage = (bytes_uploaded / bytes_total * 100).toFixed(2)
  //       console.log('progress uploading')
  //       console.log(bytes_uploaded, bytes_total, percentage + '%')
  //     },
  //     (error) => { console.log('Failed because: ' + error) }
  //   )
  // }

  // const handleNotice = async () => {
  //   const token = user.noticeToken
  //   const rid = user.noticeRoomId
  //   const msg = '通知をしました。とどいてますか？改行は？\nできてますか？2行も\n\nできますか？'

  //   const func = functions.httpsCallable("notice")
  //   func({ rid: rid, token: token, msg: msg })
  //     .then(res => {
  //       console.log(res);
  //     }).catch(e => {
  //       console.log(e);
  //     })
  // }


  // const handleTagSearch = async () => {
  //   // industryTagList
  //   // prefTagList
  //   // memberNumTagList
  // }
  // const groupStyles = {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  // }
  // const groupBadgeStyles = {
  //   backgroundColor: '#EBECF0',
  //   borderRadius: '2em',
  //   color: '#172B4D',
  //   display: 'inline-block',
  //   fontSize: 12,
  //   fontWeight: 'normal',
  //   lineHeight: '1',
  //   minWidth: 1,
  //   padding: '0.16666666666667em 0.5em',
  //   textAlign: 'center',
  // };
  // const formatGroupLabel = data => (
  //   <div style={groupStyles}>
  //     <span>{data.label}</span>
  //     <span style={groupBadgeStyles}>{data.options.length}</span>
  //   </div>
  // )

  // const groupedOptions = [
  //   {
  //     label: '業界・業種',
  //     options: industryTagList,
  //   },
  //   {
  //     label: '社員数',
  //     options: memberNumTagList,
  //   },
  //   {
  //     label: '所在地',
  //     options: prefTagList,
  //   },
  // ]


  // const [doneUpload, setDoneUpload] = useState(false)
  // const doneUploadRef = useRef(doneUpload)

  // const start = useCallback((uri) => {
  //   // const uri = video.uri
  //   console.log('start checking', uri);
  //   console.log('doneUploadRef', doneUploadRef);
  //   console.log('doneUploadRef.current', doneUploadRef.current);
  //   // if (intervalRef.current !== null) {
  //   if (doneUploadRef.current) return
  //   // intervalRef.current = setInterval(() => {
  //   doneUploadRef.current = setInterval(() => {
  //     console.log('checking', uri);
  //     // setCount(c => c + 1);
  //     vimeo.request(uri + '?fields=upload.status', (error, body, status_code, headers) => {
  //       if (body.upload.status === 'complete') {
  //         console.log('Your video finished uploading.')
  //         setDoneUpload(true)
  //       } else if (body.upload.status === 'in_progress') {
  //         console.log('Your video is still uploading.')
  //       } else {
  //         console.log('Your video encountered an error during uploading.')
  //         setDoneUpload(true)
  //       }
  //     })
  //   }, 3000);
  // }, []);

  // const stop = useCallback(() => {
  //   console.log('stop cheking')
  //   if (!doneUploadRef.current) return
  //   clearInterval(doneUploadRef.current)
  //   doneUploadRef.current = false
  // }, []);

  // useEffect(() => {
  //   if (doneUpload) {
  //     console.log("wawawa");
  //     stop()
  //   }
  //   return () => {
  //     console.log("mohihiuho");
  //     stop()
  //   }
  // }, [doneUpload])

  return <React.Fragment>
    <div>aaaaa</div>

    {/*
    <div
      onClick={() => handleFunction()}
      style={style}
    >関数実行テスト</div>

    <div
      onClick={() => handleUpdateMember()}
      style={style}
    >メンバー更新</div>

    <FormControl>
      <TextField
        label='コメントのキー'
        value={commentKey}
        onChange={(e) => setCommentKey(e.target.value)}
        variant="outlined"
        required
      />
    </FormControl>
    <div
      onClick={() => handleDeleteCmnt(commentKey)}
      style={style}
    >コメント削除</div>
    <p>{msg}</p>

    <div style={style}>
      <p>動画をアップロードする</p>
      <input type="file" accept="video/*" onChange={e => handleUploadVimeo(e)} />
    </div>

    <div style={style} onClick={() => setIsLoading(!isLoading)}>
      <p>ローディング切り替え</p>
    </div>
    {
      isLoading && <div className="loading-bro">
        <svg id="load" x="0px" y="0px" viewBox="0 0 150 150">
          <circle id="loading-inner" cx="75" cy="75" r="60" />
        </svg>
      </div>
    }

    <div
      onClick={() => handleNotice()}
      style={style}
    >通知送る</div>

    <div
      onClick={() => actions.loginCheck(dispatch, history)}
      style={style}
    >ログイン</div>

    <div
      onClick={() => handleTagSearch()}
      style={style}
    >タグ検索</div>
    <Select
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
    />
    */}

  </React.Fragment>
}
