import React from 'react'
import { Button } from '@material-ui/core'
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons'

export const Back = ({ history, path = '', color, alignLeft }) => {
  if (process.env.NODE_ENV === "development") { console.log("history", history) }
  if (!history) return <React.Fragment></React.Fragment>

  const handleToPage = () => {
    if (!!path) {
      history.push(path)
    } else {
      history.goBack()
    }
  }

  return <Button
    color='primary'
    style={{
      color: !!color ? color : 'inherit',
      marginRight: !!alignLeft ? 'auto' : 0,
    }}
    onClick={() => handleToPage()}
  >
    <ArrowBackIosIcon />
    前のページに戻る
  </Button >
}
