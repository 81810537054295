import React, {
  // useState,
  useContext,
  useEffect,
  useState,
} from 'react'
import firebase, { userRef } from "../../firebase/index"
// import STYLES from "../../constants/styles"
// import SITEINFO from "../../constants/siteInfo"
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Link,
  useHistory,
} from 'react-router-dom'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link as LinkStyle,
  TextField,
  Typography,
} from '@material-ui/core'
import SignInTemplate from '../templates/SignInTemplate'
import { GlobalContext } from '../../hooks/reducer'
import { commonTheme } from "../../materialui/theme"
import { ManualLink } from "../common/ManualLink"
import { getErrorMessage } from "../../lib/errorMsg"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const mainBlue = commonTheme.palette.primary.main
const lightBlue = commonTheme.palette.primary.light
const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    background: '#657c98',
    borderRadius: '4px',
    '& input': {
      background: '#657c98',
    }
  },
  submit: {
    margin: '10px 0',
    background: 'none',
    border: '2px solid',
    borderColor: lightBlue,
    color: lightBlue,
    '&:hover': {
      background: lightBlue,
      color: mainBlue,
    },
  },
  errorMessage: {
    marginBottom: '10px',
    "& p": {
      color: commonTheme.palette.error.light,
      fontWeight: 'bold',
    }
  },
  fogetPasswordLink: {
    margin: '10px 0',
    color: lightBlue,
  },
}))

// エラーメッセージ表示用のコンポーネント
const ErrorMessage = React.memo(({ errors }) => {
  return (
    errors.isError && (
      <Grid item xs className={useStyles().errorMessage}>
        <Typography component="p" align="center">
          ログインに失敗しました
        </Typography>
        <Typography component="p" align="center">
          {getErrorMessage(errors.message)}
        </Typography>
      </Grid>
    )
  );
});

const SignIn = React.memo((props) => {
  const { state: { signin, common }, dispatch } = useContext(GlobalContext)
  const { user } = signin
  const { errors } = common
  const history = useHistory()
  const classes = useStyles()

  const [checked, setChecked] = useState(false)
  const [showPassword, setShowPassword] = useState(false); // パスワードの可視化状態

  useEffect(() => {
    // NOTE: loginしているか確認、自動画面遷移
    firebase.auth().onAuthStateChanged((user) => {
      if (user) history.push('/')
      setChecked(true)
    })
    return () => { dispatch({ type: 'COMMON_RESET' }) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleSubmit = async () => {
    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(user.email, user.password);
      // ユーザー情報取得
      const uid = userCredential.user.uid;
      console.log("uid", uid);
      
      const snapshot = await userRef.orderByChild('uid').equalTo(uid).once('value');
      console.log("login", snapshot.val());
      
      const loginUser = snapshot.val();
      if (loginUser) {
        const userData = Object.values(loginUser)[0];
        const userId = Object.keys(loginUser)[0];
        
        dispatch({
          type: 'SET_LOGIN_USER',
          data: {
            ...userData,
            email: userCredential.user.email,
            userId: userId
          }
        });

        dispatch({ type: 'PASSWORD_RESET' });
        history.push('/');

      } else {
        throw new Error("ユーザー情報が取得できませんでした。");
      }
    } catch (error) {
      dispatch({ type: 'ERROR', data: error.message });
      dispatch({ type: 'PASSWORD_RESET' });
      console.error("error", error);
    }
  };

  if (!checked) return <SignInTemplate></SignInTemplate>

  return (
    <SignInTemplate>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className='signform'
      >

        {/* 利用方法案内 */}
        <ManualLink />

        <ErrorMessage errors={errors} />

        <FormControl variant="outlined" fullWidth={true}>
          <TextField
            label="メールアドレス"
            value={user.email}
            onChange={(e) => dispatch({ type: 'CHANGE_SIGNIN_VALUE', data: e.target.value, field: 'email' })}
            variant="outlined"
            required
            className={clsx(classes.input, 'signform_input')}
          />
          <TextField
            label="パスワード"
            type={showPassword ? 'text' : 'password'} // パスワード可視化
            value={user.password}
            onChange={(e) => dispatch({ type: 'CHANGE_SIGNIN_VALUE', data: e.target.value, field: 'password' })}
            className={clsx(classes.input, 'signform_input')}
            variant="outlined"
            autoComplete="current-password"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)} // トグル切り替え
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleSubmit()}
            className={classes.submit}
          >
            ログイン
          </Button>
        </FormControl>

        <LinkStyle
          component={Link}
          to="/forget-password"
          className={classes.fogetPasswordLink}
          underline="always"
        >
          パスワードを忘れた方はこちら
        </LinkStyle>
      </Grid>
    </SignInTemplate>
  )
})
export default SignIn
