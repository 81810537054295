/** firebase auth エラー */
export const getErrorMessage = (errorCode) => {
    const errorMessages = {
        "The email address is badly formatted.": "メールアドレスの形式が正しくありません。",
        "There is no user record corresponding to this identifier. The user may have been deleted.": "この識別子に該当するユーザーレコードが存在しません。ユーザーが削除された可能性があります。",
        "The password is invalid or the user does not have a password.": "パスワードが無効、またはユーザーがパスワードを持っていません。",
        "The email address is already in use by another account.": "このメールアドレスは既に別のアカウントで使用されています。",
        "The password must be 6 characters long or more.": "パスワードは6文字以上である必要があります。",
        "We have blocked all requests from this device due to unusual activity. Try again later.": "通常とは異なる活動が検出されたため、このデバイスからのすべてのリクエストをブロックしました。後ほど再試行してください。",
        "Password sign-in is disabled for this project.": "このプロジェクトではパスワードによるサインインが無効になっています。",
        "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.": "同じメールアドレスを持つアカウントが存在しますが、サインインの認証情報が異なります。このメールアドレスに関連付けられたプロバイダーを使用してサインインしてください。",
        "Operation not allowed.": "この操作は許可されていません。",
        "Too many unsuccessful login attempts. Please try again later.": "ログイン試行回数が多すぎます。後ほど再試行してください。"
    };

    return errorMessages[errorCode] || '不明なエラーが発生しました。';
}