import React, {
  // useState,
  useEffect,
} from 'react'
// import firebase from '../../firebase/index'
// import STYLES from "../../constants/styles"
// import SITEINFO from "../../constants/siteInfo"
// import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// import {
//   Link,
//   useHistory,
// } from 'react-router-dom'
import {
  Box,
  Typography,
} from '@material-ui/core'
import CardTemplate from '../templates/CardTemplate'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
  },
  subTitle: {
    fontWeight: 'bold',
  },
}))

const CompleteSendMail = React.memo((props) => {
  // const history = useHistory()
  const classes = useStyles()
  // const [values, setValues] = useState({
  // })

  useEffect(() => {
    let isMounted = true
    console.log("completesendmail isMounted", isMounted);
    return () => {
      isMounted = false
      console.log("completesendmail unMounted", isMounted);
    }
  }, [])

  return (
    <CardTemplate>
      <Box>
        <Typography variant="h6" align="center" className={classes.title}>
          パスワード再設定のメールを送信しました。
        </Typography>
      </Box>
    </CardTemplate>
  )
})
export default CompleteSendMail
