// import * as firebase from 'firebase'
// import firebase from 'firebase'
import firebase from 'firebase/app'
// import 'firebase/firestore';
import "firebase/auth"
import "firebase/storage"
import "firebase/database"
import "firebase/functions"

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID
} = process.env

var firebaseConfig = {}

if (process.env.DEV || process.env.DEV_ENV || process.env.REACT_APP_MODE === 'staging') {
  // release テスト環境
  firebaseConfig = {
    apiKey: "AIzaSyD_Q0N6lOeuLz6H4Tsm9sSGiY67QuJHa3A",
    authDomain: "ccs-test-c9d37.firebaseapp.com",
    databaseUrl: REACT_APP_FIREBASE_DATABASE_URL,
    projectId: "ccs-test-c9d37",
    storageBucket: "ccs-test-c9d37.appspot.com",
    messagingSenderId: "33389837807",
    appId: "1:33389837807:web:31cf366bd8ea4294cb8a1a"
  }
} else {
  // release 本番環境
  firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseUrl: REACT_APP_FIREBASE_DATABASE_URL,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSender_id: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID
  }
}

// export const firebaseApp = firebase.initializeApp(firebaseConfig)
firebase.initializeApp(firebaseConfig)

// Storage
export const storage = firebase.storage()

// function
const fun = firebase.functions()

const _database = firebase.database()

const isEmulating = window.location.hostname === "localhost"
if (isEmulating) {
  // NOTE: テスト環境のときは、コメントアウトをはずす。+ functionsのエミュレーターを立ち上げる
  firebase.auth().useEmulator("http://localhost:9099");

  // _database.useEmulator("http://localhost:9000");
  _database.useEmulator("localhost", 9000);
  
  fun.useEmulator("localhost", 5001);
  // fun.useEmulator("localhost", 5000)
}

// DB
export const database = _database
export const caseRef = database.ref('cases')
export const userRef = database.ref('users')
export const companiesRef = database.ref('companies')
export const caseCommentsRef = database.ref('caseComments')
export const goodsRef = database.ref('goods')
export const consultRef = database.ref('consults')
export const consultCommentsRef = database.ref('consultComments')
export const consultGoodsRef = database.ref('consultGoods')
export const infoRef = database.ref('info')
export const infoCommentsRef = database.ref('infoComments')
export const noticeRef = database.ref('notice')
export const adminRef = database.ref('admin')
export const masterRef = database.ref('master')
export const newTagsRef = database.ref('newTags')

export const functions = fun

// ---- 監視

/** 起動タイムスタンプ */
let STARTUP_TIMESTAMP = null

const initialize = () => {
  const tempRef = database.ref("temp")

  // まずタイムスタンプと登録して
  tempRef.set({
    "timestamp": firebase.database.ServerValue.TIMESTAMP
  })

  // 登録したタイムスタンプを取得。
  tempRef.once("value", (tSnapshot) => {
    STARTUP_TIMESTAMP = tSnapshot.val().timestamp
    console.log('### STARTUP_TIMESTAMP: ' + STARTUP_TIMESTAMP)

    // リスナ登録
    newTagsRef.on('child_added', (snapshot, prevChildKey) => {
    console.log('### STARTUP_TIMESTAMP: ' + STARTUP_TIMESTAMP)

      const newPost = snapshot.val()

      // タイムスタンプ比較
      if (newPost.timestamp > STARTUP_TIMESTAMP) {

        // 通知
        // TODO: 通知メール設定フォームを管理画面にセットして、動的に取得。複数取得も可能にしておく。
        // TODO: admin.newTagsNoticeEmailsからループして取得
        const email = 'pan.shoku.mochi@gmail.com'

        if (email) {
          let tags = ''
          newPost.tag.forEach(tag => {

            tags += "\n・"
            tags += tag.label

          })

          console.log('### add tags: ' + tags)

          let func = null

          func = functions.httpsCallable('sendCustamizeMail')

          // const { email, name } = data
          const message = `タグの追加リクエストがありました。

--------------------------------------------------
${tags}

--------------------------------------------------

`

          func({ email, message, title: 'タグ追加リクエストのお知らせ' })
            .then(res => { console.log(res) })
            .catch(e => { console.log(e) })

        }
      }

    })
  })
}

initialize()

// --- 監視 end

export default firebase
