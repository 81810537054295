import firebase, { userRef, functions, caseRef, caseCommentsRef, goodsRef, consultRef, consultCommentsRef, consultGoodsRef, infoRef, infoCommentsRef, noticeRef } from '../firebase'
import { actions as signupActions } from "./useSignUpReducer"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import SITEINFO from '../constants/siteInfo'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

export const initialState = {
  account: {
    my: {
      currentPassword: '',
      editUser: {
        imageBase64: null,
        imageUrl: '',
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
      },
      submit: {
        name: false,
        email: false,
      },
      isOpen: false,
      isOpenPassword: false,
      disabled: true,
    },
    notice: {
      orgNotice: {
        noticeRoomId: '',
        noticeToken: '',
      },
      editNotice: {
        noticeRoomId: '',
        noticeToken: '',
      },
      isOpen: false,
    },
    noticeType: {
      editNoticeType: {
        all: '',
        caseAll: '',
        consultAll: '',
        infoAll: '',
      },
      isOpen: false,
      isAllFalse: true,
    },
    noticeTypes: {
      // master
      case: [],
      info: [{ name: 'infoAll', label: '全てのお知らせ' }],
      consult: [{ name: 'consultAll', label: '全ての相談' }],
    },
    myCompany: {
      editCompany: {
        imageBase64: null,
        imageUrl: '',
        name: '',
        url: '',
        description: '',
        industryTags: [],
        memberNumTags: [],
        prefTags: [],
      },
      submit: {
        name: false,
        email: false,
      },
      isOpen: false,
      disabled: true,
    },
    members: [],
    editMember: {
      user: {},
      isEdit: false,
      uid: '',
      submit: {
        name: true,
        email: true,
      },
      disabled: true,
    },
    editMemberPassword: {
      password: '',
      passwordConfirm: ''
    },
    isOpenMemberPassword: false,
    isAddMember: false,
    addMember: {
      imageUrl: '',
      imageName: '',
      admin: false,
    },
    addMemberPassword: {
      password: '',
      passwordConfirm: '',
    },
    addMemberDisabled: true,
    addSubmit: { email: false, name: false },
  },
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      console.log("RESET")
      return initialState.account
    case 'ACCOUNT_RESET':
      return {
        ...state,
        my: initialState.account.my,
        myCompany: initialState.account.myCompany,
        notice: initialState.account.notice,
        noticeType: initialState.account.noticeType,
      }
    case 'ACCOUNT_USER_RESET':
      return {
        ...state,
        my: initialState.account.my,
      }
    case 'ACCOUNT_COMPANY_RESET':
      return {
        ...state,
        myCompany: initialState.account.myCompany,
      }
    case 'ACCOUNT_NOTICE_RESET':
      return {
        ...state,
        notice: initialState.account.notice,
      }
    case 'ACCOUNT_NOTICE_TYPE_RESET':
      return {
        ...state,
        noticeType: initialState.account.noticeType
      }
    // inputフォーム変更
    case 'CHANGE_CURRENT_PASSWORD':
      return {
        ...state,
        my: {
          ...state.my,
          currentPassword: action.data,
        }
      }
    case 'CHANGE_EDIT_USER_VALUE':
      let submitData = {}
      if ((action.field === 'name') || (action.field === 'email')) {
        submitData = { [action.field]: !!action.data }
      }
      return {
        ...state,
        my: {
          ...state.my,
          editUser: {
            ...state.my.editUser,
            [action.field]: action.data,
          },
          submit: {
            ...state.my.submit,
            ...submitData,
          }
        }
      }
    case 'CHANGE_EDIT_COMPANY_VALUE':
      let submitConpanyData = {}
      if (action.field === 'name') {
        submitConpanyData = { [action.field]: !!action.data }
      }
      return {
        ...state,
        myCompany: {
          ...state.myCompany,
          editCompany: {
            ...state.myCompany.editCompany,
            [action.field]: action.data,
          },
          submit: {
            ...state.myCompany.submit,
            ...submitConpanyData,
          }
        }
      }
    case 'CHANGE_EDIT_NOTICE_VALUE':
      return {
        ...state,
        notice: {
          ...state.notice,
          editNotice: {
            ...state.notice.editNotice,
            [action.field]: action.data,
          }
        }
      }
    case 'CHANGE_EDIT_NOTICE_TYPES_VALUE':
      let caseData = {}
      const caseKeys = Object
        .keys(state.noticeType.editNoticeType)
        .filter(type => {
          if (type === 'caseAll') return false
          return type.slice(0, 4) === 'case'
        })

      // case...がチェックされたらcaseAllをfalse
      const isCase = caseKeys.includes(action.field) && !!action.data
      const caseAll = !!state.noticeType.editNoticeType.caseAll
      if (isCase && caseAll) { caseData.caseAll = false }

      // caseAllがチェックされたら、他のcase...はfalse
      const caseAllChecked = action.field === 'caseAll' && !!action.data
      // case...が全部チェックされたら、caseAllをtrue
      const checkedCaseKeys = caseKeys.filter(key => {
        if (key !== action.field && !!state.noticeType.editNoticeType[key]) return true
        return false
      })
      const chekedAllcases = checkedCaseKeys.length === (caseKeys.length - 1)
      if (caseAllChecked || (isCase && chekedAllcases)) {
        caseKeys.map(key => caseData[key] = false)
        caseData.caseAll = true
      }

      return {
        ...state,
        noticeType: {
          ...state.noticeType,
          editNoticeType: {
            ...state.noticeType.editNoticeType,
            [action.field]: action.data,
            ...caseData,
          },
        }
      }
    // 画像変更
    case 'CHANGE_EDIT_USER_IMAGE':
      return {
        ...state,
        my: {
          ...state.my,
          editUser: {
            ...state.my.editUser,
            ...action.data,
          }
        },
      }
    case 'CHANGE_EDIT_COMPANY_IMAGE':
      return {
        ...state,
        myCompany: {
          ...state.myCompany,
          editCompany: {
            ...state.myCompany.editCompany,
            ...action.data,
          }
        },
      }
    // 編集フォーム表示切り替え
    case 'CHANGE_IS_OPEN_PASSWORD':
      return {
        ...state,
        my: {
          ...state.my,
          isOpenPassword: action.data,
        }
      }
    case 'CHANGE_IS_OPEN_EDIT_USER':
      return {
        ...state,
        my: {
          ...state.my,
          isOpen: action.data,
        }
      }
    case 'CHANGE_IS_OPEN_COMPANY':
      return {
        ...state,
        myCompany: {
          ...state.myCompany,
          isOpen: action.data,
        }
      }
    case 'CHANGE_IS_OPEN_NOTICE_TYPE':
      return {
        ...state,
        noticeType: {
          ...state.noticeType,
          isOpen: action.data,
        }
      }
    case 'CHANGE_IS_OPEN_NOTICE':
      return {
        ...state,
        notice: {
          ...state.notice,
          isOpen: action.data,
        }
      }
    case 'SET_NOTICE_TYPES_CASE':
        return {
          ...state,
          noticeTypes: {
            ...state.noticeTypes,
            case: action.data,
          }
      }
    // 現在の情報セット
    case 'SET_NOTICE_TYPE':
      return {
        ...state,
        noticeType: {
          ...state.noticeType,
          orgNoticeTypes: {
            ...state.noticeType.orgNoticeTypes,
            ...action.data,
          }
        }
      }
    case 'SET_EDIT_USER':
      return {
        ...state,
        my: {
          ...state.my,
          editUser: {
            ...state.my.editUser,
            ...action.data,
            imageBase64: action.data.imageUrl,
            imageUrl: action.data.imageUrl,
          },
          submit: {
            name: true,
            email: true,
          }
        }
      }
    case 'SET_EDIT_COMAPNY':
      return {
        ...state,
        myCompany: {
          ...state.myCompany,
          editCompany: {
            ...state.myCompany.editCompany,
            ...action.data,
            imageBase64: action.data.imageUrl,
            imageUrl: action.data.imageUrl,
          },
          submit: {
            name: true,
          }
        }
      }
    case 'SET_EDIT_NOTICE':
      return {
        ...state,
        notice: {
          ...state.notice,
          editNotice: {
            ...state.notice.editNotice,
            noticeRoomId: action.data.noticeRoomId,
            noticeToken: action.data.noticeToken,
          }
        }
      }
    case 'SET_EDIT_NOTICE_TYPE':
      return {
        ...state,
        noticeType: {
          ...state.noticeType,
          editNoticeType: {
            ...state.noticeType.editNoticeType,
            ...action.data.noticeTypes
          }
        }
      }
    case 'CHANGE_MY_DISABLED':
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          disabled: action.data,
        }
      }
    /****** メンバー *****/
    // 会社の所属メンバーをセット
    case 'SET_MEMBERS':
      return {
        ...state,
        members: action.data,
      }
    // 編集フォームの非表示
    case 'IS_SHOW_EDIT_MEMBER':
      // NOTE: 編集フォームは、１人だけ表示する
      return {
        ...state,
        editMember: {
          user: {
            ...action.data,
            imageBase64: action.data.imageUrl,
          },
          isEdit: true,
          uid: action.data.uid,
          submit: initialState.account.editMember.submit,
          disabled: true,
        },
        isOpenMemberPassword: false,
      }
    case 'HIDDEN_EDIT_MEMBER':
      // NOTE: 編集フォームは、１人だけ表示する
      return {
        ...state,
        editMember: initialState.account.editMember,
        isOpenMemberPassword: initialState.account.isOpenMemberPassword,
      }
    //  inputフォーム変更
    case 'CHANGE_EDIT_MEMBER':
      let submitMemberData = {}
      if ((action.field === 'name') || (action.field === 'email')) {
        submitMemberData = { [action.field]: !!action.data }
      }
      return {
        ...state,
        editMember: {
          ...state.editMember,
          user: {
            ...state.editMember.user,
            [action.field]: action.data,
          },
          submit: {
            ...state.editMember.submit,
            ...submitMemberData,
          }
        }
      }
    // パスワード
    case 'CHANGE_IS_OPEN_MEMBER_PASSWORD':
      return {
        ...state,
        isOpenMemberPassword: action.data,
      }
    case 'CHANGE_EDIT_MEMBER_PASSWORD':
      return {
        ...state,
        editMemberPassword: {
          ...state.editMemberPassword,
          [action.field]: action.data,
        },
      }
    // 変更反映
    case 'CHANGE_MEMBER':
      return {
        ...state,
        members: state.members.map(user => {
          if (user.uid === action.data.uid) return action.data
          return user
        })
      }
    // 画像変更
    case 'CHANGE_EDIT_MEMBER_IMAGE':
      return {
        ...state,
        editMember: {
          ...state.editMember,
          user: {
            ...state.editMember.user,
            ...action.data,
          }
        },
      }
    /****** メンバー追加 *****/
    case 'HIDDEN_ADD_MEMBER':
      return {
        ...state,
        isAddMember: false,
        addMember: initialState.account.addMember,
        addMemberPassword: initialState.account.addMemberPassword,
        addMemberDisabled: true,
        addSubmit: initialState.account.addSubmit,
      }
    case 'IS_ADD_MEMBER':
      return {
        ...state,
        isAddMember: action.data,
      }
    case 'CHANGE_ADD_MEMBER':
      let submitAddMemberData = {}
      if ((action.field === 'name') || (action.field === 'email')) {
        submitAddMemberData = { [action.field]: !!action.data }
      }
      return {
        ...state,
        addMember: {
          ...state.addMember,
          [action.field]: action.data
        },
        addSubmit: {
          ...state.addSubmit,
          ...submitAddMemberData
        }
      }
    case 'CHANGE_ADD_MEMBER_PASSWORD':
      return {
        ...state,
        addMemberPassword: {
          ...state.addMemberPassword,
          [action.field]: action.data
        }
      }
    case 'CHANGE_ADD_MEMBER_DISABLED':
      return {
        ...state,
        addMemberDisabled: action.data,
      }
    case 'ADD_MEMBER':
      return {
        ...state,
        members: state.members.push(action.data),
      }
    case 'DELETE_MEMBER':
      return {
        ...state,
        members: state.members.filter(user => user.uid !== action.data)
      }
    case 'CHANGE_ADD_MEMBER_IMAGE':
      return {
        ...state,
        addMember: {
          ...state.addMember,
          ...action.data,
        },
      }
    default: return state
  }
}

export const actions = {
  sendEmailVerification: (mailUserRef) => {
    mailUserRef.sendEmailVerification({
      url: SITEINFO.appUrl,
      handleCodeInApp: false,
    })
  },
  updateUser: (dispatch, data) => {
    console.log("updateUser start")
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    const { updateData, type, currentUser, editUser } = data
    userRef.child(currentUser.userId).update(updateData)
    if (type === 'login') {
      return dispatch({
        type: 'SET_LOGIN_USER', data: {
          ...currentUser,
          ...updateData,
          email: editUser.email,
          updateAt: now
        }
      })
    } else {
      console.log("updateUser end")
      return dispatch({})
    }
  },
  updateEmail: (editUser) => {
    const currentUser = firebase.auth().currentUser
    return currentUser.updateEmail(editUser.email)
      .then().catch((error) => {
        throw new Error(`更新に失敗しました。 ${error.message}`)
      })
  },
  updateMember: async (dispatch, uid, updateAuth, updateUser) => {
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    updateUser.updateAt = now
    updateUser.imageBase64 = null
    updateUser.imageFile = null

    // email, password
    if (Object.keys(updateAuth).length > 0) {
      const func = functions.httpsCallable("updateMember")
      await func({ data: updateAuth, uid: uid }).then(res => {
        console.log("updateMember success")
        return res.data
      }).catch(e => {
        console.log(e)
        throw new Error(`更新に失敗しました。 ${e.message}`)
      })
    }

    // add user info
    userRef.child(updateUser.userId).update(updateUser)
    dispatch({ type: 'CHANGE_MEMBER', data: updateUser })
    return
  },
  setMember: (dispatch, companyId) => {
    userRef
      .orderByChild('companyInfo/companyId')
      .startAt(companyId).endAt(companyId)
      .once('value', (snapshot) => {
        const members = snapshot.val()
        const membersArr = Object.entries(members).map(member => {
          const [key, content] = member
          return { ...content, userId: key }
        })
        const setMembers = membersArr.sort((a, b) => {
          let comparison = 0
          if (a.createAt > b.createAt) { comparison = 1 }
          else if (a.createAt < b.createAt) { comparison = -1 }
          if (!!a.admin < !!b.admin) { comparison = 1 }
          else if (!!a.admin > !!b.admin) { comparison = -1 }
          return comparison
        })
        dispatch({
          type: 'SET_MEMBERS',
          data: setMembers
        })
      })
  },
  setPreview: (dispatch, e, type, signup = false) => {
    const files = e.target.files
    let actionType = ''
    switch (type) {
      case 'user':
        actionType = 'CHANGE_EDIT_USER_IMAGE'
        break
      case 'mainCompany':
        actionType = 'CHANGE_EDIT_COMPANY_IMAGE'
        break
      case 'member':
        actionType = 'CHANGE_EDIT_MEMBER_IMAGE'
        break
      case 'addMember':
        actionType = 'CHANGE_ADD_MEMBER_IMAGE'
        break
      default:
        actionType = 'CHANGE_EDIT_USER_IMAGE'
        break
    }

    // storegeに保存
    // TODO: infoの画像投稿と同じフローにしたい（postのときにstoreImage）
    signupActions.storeImage(dispatch, files[0], type)

    if (files.length > 0) {
      const file = files[0]
      let reader = new FileReader()
      reader.onload = (e) => {
        if (signup) {
          dispatch({
            type: 'CHANGE_SIGNUP_IMAGE',
            field: type,
            data: {
              imageBase64: e.target.result,
              imageFile: file
            }
          })
        } else {
          dispatch({
            type: actionType,
            data: {
              imageBase64: e.target.result,
              imageFile: files[0]
            }
          })
        }
      }
      reader.readAsDataURL(file)
    } else {
      if (signup) {
        dispatch({
          type: 'CHANGE_SIGNUP_IMAGE',
          field: type,
          data: {
            imageBase64: null,
            imageFile: null
          }
        })
      } else {
        dispatch({
          type: actionType,
          data: {
            imageBase64: null,
            imageFile: null
          }
        })
      }
    }
  },
  updateInPost: async (dispatch, type, data) => {
    console.log("update In Post start")
    // NOTE: data = user or companyInfo
    // TODO: data整理 ※不要なuser情報削除
    let dbPath, at, upPath = ''
    switch (type) {
      case 'user':
        dbPath = 'user/uid'
        at = data.uid
        upPath = 'user'
        break
      case 'company':
        dbPath = 'user/companyId'
        at = data.companyId
        upPath = 'user/companyInfo'
        break
      default:
        break
    }

    let keys, userKeys = []
    const setKeys = (arr) => { return keys = arr }
    const setUserKeys = (arr) => { return userKeys = arr }

    const updateDb = async (ref) => {
      if (process.env.NODE_ENV === "development") { console.log('update', ref) }
      await ref.orderByChild(dbPath)
        .startAt(at).endAt(at)
        .once('value', (snapshot) => {
          const values = snapshot.val()
          if (process.env.NODE_ENV === "development") { console.log("values", values) }
          if (!values) { return setKeys(Object.keys([])) }
          return setKeys(Object.keys(values))
        })
      let updates = {}
      keys.forEach(key => { updates[`/${key}/${upPath}`] = data })
      await ref.update(updates)
    }

    // ユーザー
    if (type === 'company') {
      console.log("at", at);
      await userRef.orderByChild('companyId')
        .startAt(at).endAt(at)
        .once('value', (snapshot) => {
          const users = snapshot.val()
          if (!users) { return setUserKeys(Object.keys([])) }
          return setUserKeys(Object.keys(users))
        })
      let userUpdates = {}
      if (process.env.NODE_ENV === "development") { console.log("userUpdates") }
      userKeys.forEach(key => { userUpdates[`/${key}/companyInfo`] = data })
      await userRef.update(userUpdates)
      actions.setMember(dispatch, data.companyId)
    }

    // 事例
    await updateDb(caseRef)
    await updateDb(caseCommentsRef)
    await updateDb(goodsRef)

    // 相談
    await updateDb(consultRef)
    await updateDb(consultCommentsRef)
    await updateDb(consultGoodsRef)

    // お知らせ
    await updateDb(infoRef)
    await updateDb(infoCommentsRef)

    console.log("update In Post end")
    return
  },
  // バックエンドと同じコード
  setUserData: (user, company = {}) => {
    if (process.env.NODE_ENV === "development") { console.log("set user data", user, company) }
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    const isCompany = Object.keys(company).length > 0
    const companyInfo = isCompany ? company : user.companyInfo
    if (process.env.NODE_ENV === "development") { console.log("companyInfo", companyInfo) }
    let userData = {
      uid: user.uid,
      name: user.name,
      email: user.email,
      imageName: user.imageName || "",
      imageUrl: user.imageUrl || "",
      company: companyInfo.name, // name 破棄
      companyId: companyInfo.companyId,
      companyInfo: companyInfo,
      admin: !!user.admin,
      pajaposs: companyInfo.companyId === SITEINFO.pajapossCompanyId,
      createAt: !!user.createAt ? user.createAt : now,
      updateAt: now,
    }
    if (!!user.userId) { userData.userId = user.userId }
    return userData
  },
  setCompanyData: (company) => {
    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
    let data = {
      companyId: company.companyId,
      createAt: !!company.createAt ? company.createAt : now,
      name: company.name,
      description: company.description,
      url: company.url,
      imageName: company.imageName,
      imageUrl: company.imageUrl,
      industryTags: company.industryTags,
      memberNumTags: company.memberNumTags,
      prefTags: company.prefTags,
      updateAt: now,
    }
    return data
  },
  updateNotice: async (user, notice, types) => {
    // 通知チェック
    const msg = `
通知設定を完了しました。
==========================================
${SITEINFO.title}
`
    const notices = {
      0: {
        userId: user.userId,
        ...notice
      }
    }
    const func = functions.httpsCallable("notice")
    await func({ notices: notices, msg: msg })
      .then(res => {
        console.log(res);
      }).catch(e => {
        console.log("error", e);
        throw new Error(`設定に失敗しました。設定内容をご確認ください。`)
      })

    await userRef.child(user.userId).update(notice)
    let updates = {}
    const data = {
      userId: user.userId,
      ...notice,
    }
    if (!!types) {
      Object.entries(types).forEach(([key, upPath]) => {
        if (!!upPath) { updates[`/${key}/${upPath}`] = data }
      })
      noticeRef.update(updates)
    }
  },
  updateNoticeType: async (dispatch, user, editTypes) => {
    const orgTypes = user.noticeTypes ? user.noticeTypes : initialState.account.noticeType.editNoticeType
    let changeTypes = []
    let newData = {}
    const setData = (key, value) => { newData[key] = value }

    if (!!editTypes.all) {
      // NOTE: 全て通知に切り替える時は、他のnoticeデータを削除する
      console.log("editTypes.all");
      changeTypes = await Promise.all(Object.entries(editTypes).filter(([key, value]) => {
        if (key === 'all') return true
        if (!!orgTypes[key]) return true
        return false
      }).map(([key, value]) => {
        if (key === 'all') return [key, true]
        return [key, false]
      }))
    } else {
      changeTypes = await Promise.all(Object.entries(editTypes).filter(([key, value]) => {
        return !orgTypes[key] !== !value
      }))
    }

    await Promise.all(changeTypes.map(async ([key, value]) => {
      if (!value) {
        // edit false -> remove
        console.log("remove", key, value);
        await noticeRef.child(key).child(orgTypes[key]).remove()
        setData(key, '')
      }
      if (!!value) {
        // edit true -> push
        console.log("push", key, value);
        const newType = await noticeRef.child(key).push({
          noticeRoomId: user.noticeRoomId,
          noticeToken: user.noticeToken,
          userId: user.userId,
        })
        console.log("newType.key", newType.key);
        setData(key, newType.key)
      }
    }))

    // ユーザー情報更新
    await userRef.child(user.userId).child('noticeTypes').update({
      ...orgTypes,
      ...newData,
    })

    return dispatch({
      type: 'SET_LOGIN_USER', data: {
        ...user,
        noticeTypes: {
          ...user.noticeTypes,
          ...newData
        },
      }
    })
  }
}
