/****************************** 青背景 ******************************/
import React, {
  // useState,
  useEffect,
} from 'react'
// import firebase from '../../firebase/index'
// import STYLES from "../../constants/styles"
import SITEINFO from "../../constants/siteInfo"
// import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Link,
  // useHistory,
  useLocation,
} from 'react-router-dom'
import {
  Card,
  Container,
  CssBaseline,
  Link as LinkStyle,
  Typography,
} from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { themeCard } from "../../materialui/theme"
import { ThemeProvider } from "@material-ui/styles"
import Copyright from "../common/Copyright"
import { ManualLink } from "../common/ManualLink"

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    padding: '60px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  title: {
    color: themeCard.palette.secondary.main,
  },
  back: {
    width: '100%',
  },
  link: {
    color: themeCard.palette.primary.light,
  },
}))

// 青背景
const CardTemplate = React.memo((props) => {
  const location = useLocation()
  const classes = useStyles()
  const { manual } = props

  useEffect(() => {
    let isMounted = true
    console.log("cardtmp isMounted", isMounted);
    return () => {
      isMounted = false
      console.log("cardtmp unMounted", isMounted);
    }
  }, [])

  return (
    <ThemeProvider theme={themeCard}>
      <Container component="main" maxWidth="sm" className={classes.paper}>
        <CssBaseline />
        <div className={classes.toolbar} />

        {/* サイトタイトル */}
        <Typography
          component="h1"
          variant={isWidthUp('sm', props.width) ? 'h4' : 'h5'}
          className={classes.title}
        >
          {SITEINFO.title}
        </Typography>

        {/* 利用方法案内 */}
        {manual && <ManualLink />}

        <Card className={classes.card} style={{ overflow: 'unset' }}>
          {props.children}
        </Card>

        {
          location.pathname.includes("forget-password") &&
          <p className={classes.back}>
            <LinkStyle
              color="primary"
              underline="always"
              component={Link}
              to="/login"
              className={classes.link}
            >
              ログインページに戻る
              </LinkStyle>
          </p>
        }

        <Copyright />

      </Container>
    </ThemeProvider>
  )
})

export default withWidth()(CardTemplate)
