import React from 'react'
import { Link as LinkStyle, Typography } from '@material-ui/core'

export const AvatarLink = () => {
  return <LinkStyle
    color="primary"
    href='https://photocombine.net/edit/'
    target="_blank"
    rel="noopener"
    underline='hover'
  >
    <Typography variant="caption">画像の切り抜き・リサイズができるサイトはこちら</Typography>
  </LinkStyle>
}
