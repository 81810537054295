import React, { useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import MainTemplate from "../../templates/MainTemplate"
import { List } from '@material-ui/core'
import CaseList from '../CaseList'
import { GlobalContext } from '../../../hooks/reducer'
import { actions } from "../../../hooks/useLoginUserReducer"
import { actions as shareCaseActions } from "../../../hooks/useShereCaseReducer"
import CustomModal from "../../common/CustomModal"
import PageTitle from '../../common/PageTitle'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '70px',
    height: '70px',
  }
}))

const FbCase = React.memo((props) => {
  const { companyId } = useParams()
  if (!companyId) { return <MainTemplate></MainTemplate> }

  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { state: { shareCase, loginUser }, dispatch } = useContext(GlobalContext)
  const {
    cases, inputComments, caseComments
  } = shareCase

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin) {
      if (cases.length <= 0) {
        shareCaseActions.moutedGetData(dispatch, loginUser.user.uid)
      }
      shareCaseActions.getCompany(dispatch, companyId)
    }
    return () => { dispatch({ type: 'COMMON_RESET' }) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, loginUser.isLogin, cases])

  if (!loginUser.isLogin) { return <MainTemplate></MainTemplate> }

  return (
    <MainTemplate>

      {/* モーダル */}
      <CustomModal />
      <PageTitle title='フィードバック' />

      <List className={classes.list}>
        {cases && <React.Fragment>
          {
            cases.length <= 0 ? ('') : (
              cases.filter(c => {
                if (c.user.companyId !== companyId) return false
                if (!c.isFeedback) return false
                return true
              }).map((content, key) => {
                const comments = caseComments.filter((cmnt) => cmnt.caseKey === content.key)
                return <CaseList
                  hash={location.hash}
                  key={key}
                  content={content}
                  inputComment={inputComments[content.key]}
                  comments={comments}
                  handleDispatch={(e) => dispatch(e)}
                />
              })
            )
          }
        </React.Fragment>}
      </List>

    </MainTemplate >
  )
})
export default FbCase
