// モーダルのstage(段階)によって、内容を入れ替える
const MODAL = {
  willMemberDelete: 'willMemberDelete',
  willDelete: 'will delete',
  deleted: 'deleted',
  error: 'error',
  progress: 'progress',
}

export default MODAL
