import React, { useEffect, useContext, useRef, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import MainTemplate from "../templates/MainTemplate"
import {
  Avatar,
  Paper,
  Table,
  Link,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableHead,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as adminActions } from "../../hooks/useAdminReducer"
import CustomModal from "../common/CustomModal"

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: '1px rgba(224, 224, 224, 1) solid',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tag: {
    marginRight: '10px'
  },
  fixed: {
    position: 'sticky',
    left: 0,
    background: '#ffffff',
    '&::before': {
      content: '',
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      width: '100%',
      height: '100%',
    },
    // '&:hover': {
    //   backgroundColor: 'rgba(0, 0, 0, 0.04)',
    // },
  },
  scrollbar: {
    width: '100%',
    height: '10px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      height: '10px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#e7e7e7',
    }
  },
  scrollbarInner: {
    width: '2000px',
    height: '1px'
  },
  scrollbox: {
    width: '100%',
    marginTop: '5px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      height: '10px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#e7e7e7',
    }
  },
  scrollboxInner: {
    width: '2000px',
    marginBottom: '5px',
    backgroundColor: '#F9F8F6',
  },
  avatar: {
    transition: 'border 0.3s ease',
    cursor: 'pointer', 
    border: '2px solid transparent', /* 初期状態では透明な枠線 */
    '&:hover': { // ホバー時のスタイルを定義
      border: '2px solid rgba(57, 84, 117, 0.7019607843)',
      opacity: 0.8,
    }
  },
  caption: {
    margin: '10px 0',
  }
}))


const EnhancedTableHead = (props) => {
  const { classes } = props
  let headCells = [
    { id: 'name', align: 'left', label: '会社名', style: { minWidth: '200px' }, padding: '', className: classes.fixed },
    { id: 'id', align: 'left', label: '会社ID', style: { minWidth: '250px' }, padding: '', className: '' },
    { id: 'imagePath', align: 'left', label: 'アイコン画像パス', style: {}, padding: '', className: '' },
    { id: 'industryTags', align: 'left', label: '業種・業界', style: { minWidth: '200px' }, padding: '', className: '' },
    { id: 'prefTags', align: 'left', label: '所在地', style: { minWidth: '200px' }, padding: '', className: '' },
    { id: 'memberNumTags', align: 'left', label: '社員数', style: { minWidth: '200px' }, padding: '', className: '' },
    { id: 'description', align: 'left', label: '説明', style: { minWidth: '250px' }, padding: '', className: '' },
    { id: 'createAt', align: 'left', label: '登録日', style: {}, padding: '', className: '' },
  ]

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={i}
            align='left'
            padding={headCell.padding}
            style={headCell.style}
            className={headCell.className}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const Companies = React.memo((props) => {
  const classes = useStyles()
  const history = useHistory()
  const barRef = useRef()
  const boxRef = useRef()

  const { state: { loginUser, admin }, dispatch } = useContext(GlobalContext)
  const { companies } = admin

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const [filterName, setFilterName] = useState('');
  /** NOTE: useStateしない理由
   * フィルタリングの条件が変更されるたびに状態の更新（setFilteredCompanies）をトリガーする必要があります。
   * これは、特にデータセットが大きい場合やフィルタリング操作が頻繁に行われる場合に、
   * 不必要なレンダリングや状態更新のオーバーヘッドを引き起こす可能性があります。
   */
  const filteredCompanies = useMemo(() => {
    return companies.filter(company => company.name.toLowerCase().includes(filterName.toLowerCase()));
  }, [companies, filterName]);
  

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin) {
      if (companies.length <= 0) { adminActions.getCompanies(dispatch) }
    }
    return () => { dispatch({ type: 'COMMON_RESET' }) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loginUser.isLogin, companies])

  useEffect(() => {
    const scrollbar = document.getElementById('scrollbar')
    const scrollbox = document.getElementById('scrollbox')
    scrollbar.addEventListener('scroll', () => {
      scrollbox.scrollLeft = scrollbar.scrollLeft
    })
    scrollbox.addEventListener('scroll', () => {
      scrollbar.scrollLeft = scrollbox.scrollLeft
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = (e, newPage) => { setPage(newPage) }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  return (
    <MainTemplate>

      {/* モーダル */}
      <CustomModal />

      <div className={classes.root}>
      <TextField
        value={filterName}
        onChange={e => setFilterName(e.target.value)}
        label="会社名で検索"
        variant="outlined"
        size="small"
        style={{ marginBottom: 20 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setFilterName('')}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

        <Typography variant='body2' className={classes.caption}>
          ※アイコンをクリックすると、会社ごとの事例共有一覧を見れます。
        </Typography>

        <Paper className={classes.paper}>
          <div className={classes.scrollbar} id="scrollbar"><div ref={barRef} className={classes.scrollbarInner}></div></div>
          <TableContainer className={classes.scrollbox} id="scrollbox">
            <Table
              className={clsx(classes.table, classes.scrollboxInner)}
              aria-labelledby="tableTitle"
              size='medium'
              aria-label="enhanced table"
              ref={boxRef}
            >
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {filteredCompanies
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                      >

                        {/* アバター */}{/* 会社名 */}
                        <TableCell component="th" id={labelId} scope="row" className={classes.fixed}>
                          <Grid container alignItems='center'>
                            <Grid 
                              item 
                              component={Avatar} 
                              src={row.imageUrl} 
                              className={'comment_avatar ' + classes.avatar} 
                              style={{ marginRight: '10px'}}
                              onClick={() => history.push(`/company/${row.companyId}`)}
                            />
                            {
                              !!row.url ? <Grid item component={Link}
                                href={row.url}
                                color='primary'
                                underline='hover'
                                target='_blank'
                              >
                                {row.name}
                              </Grid> : <Grid item component='p'>{row.name}</Grid>
                            }
                          </Grid>
                        </TableCell>

                        {/* 会社ID */}
                        <TableCell component="th" id={labelId} scope="row">
                          {row.companyId}
                        </TableCell>

                        {/* アバターファイルパス */}
                        <TableCell component="th" id={labelId} scope="row">
                          {
                            !!row.imageUrl ? <Link
                              href={row.imageUrl}
                              color='primary'
                              underline='hover'
                              target='_blank'
                            >
                              {`company/${row.imageName}`}
                            </Link> : ''
                          }
                        </TableCell>

                        {/* 業界 */}
                        <TableCell component="th" id={labelId} scope="row">
                          <p>
                            {row.industryTags.map((tag, i) => <span key={i} className={classes.tag}>{tag.label},</span>)}
                          </p>
                        </TableCell>

                        {/* 所在地 */}
                        <TableCell component="th" id={labelId} scope="row">
                          <p>
                            {row.prefTags.map((tag, i) => <span key={i} className={classes.tag}>{tag.label},</span>)}
                          </p>
                        </TableCell>

                        {/* 社員 */}
                        <TableCell component="th" id={labelId} scope="row">
                          <p><span className={classes.tag}>{row.memberNumTags.label}</span></p>
                        </TableCell>

                        {/* 説明 */}
                        <TableCell component="th" id={labelId} scope="row" className='indention'>
                          {row.description}
                        </TableCell>

                        {/* 登録日 */}
                        <TableCell component="th" id={labelId} scope="row">
                          {dayjs(row.createAt).format('YYYY/MM/DD')}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* テーブルページネーション */}
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            component="div"
            count={companies.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </Paper>
      </div>

    </MainTemplate >
  )
})
export default Companies
