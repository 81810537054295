export const initialState = {
  signin: {
    user: {
      email: '',
      password: '',
    },
    sendemail: '',
    disabled: true,
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      console.log("RESET");
      return initialState.signin
    case 'CHANGE_SENDEMAIL_VALUE':
      const disabled = action.data === '' ? true : false
      return {
        ...state,
        sendemail: action.data,
        disabled: disabled,
      }
    case 'CHANGE_SIGNIN_VALUE':
      return {
        ...state,
        user: {
          ...state.user,
          // email, password
          [action.field]: action.data,
        },
      }
    case 'PASSWORD_RESET':
      return {
        ...state,
        user: {
          ...state.user,
          password: '',
        },
      }
    default: return state
  }
}
