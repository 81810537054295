import React, { useContext } from 'react'
import firebase from "../../firebase/index"
import SITEINFO from "../../constants/siteInfo"
import { makeStyles } from '@material-ui/core/styles'
import { NavLink, useLocation } from 'react-router-dom'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  Mail as MailIcon,
  Info as InfoIcon,
  QuestionAnswer as QuestionAnswerIcon,
  PostAdd as PostAddIcon,
  WebAsset as WebAssetIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Business as BusinessIcon,
  Group as GroupIcon,
  Face as FaceIcon,
  Feedback as FeedbackIcon,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons'
import { GlobalContext } from '../../hooks/reducer'
import { commonTheme } from '../../materialui/theme'

const useStyles = makeStyles((theme) => ({
  sidebar: {

  },
  toolbar: {
    ...theme.mixins.toolbar,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '25px'
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    borderTop: `1px solid ${commonTheme.palette.primary.light}`,
    padding: '10px 16px'
  },
  nowButtonText: {
    // backgroundColor: '#354c69',
    // '&:hover': {
    //   backgroundColor: '#354c6994',
    // }
    '& > span': {
      color: commonTheme.palette.secondary.main,
    },
  },
  text: {
    '& > span': {
      color: commonTheme.palette.primary.light,
      fontWeight: 400
    }
  },
  caseText: {
    // color: commonTheme.palette.secondary.main,
  },
  // icon: {
  //   color: commonTheme.palette.primary.light,
  // }
}))

const Sidebar = React.memo((props) => {
  const { state: { loginUser }, dispatch } = useContext(GlobalContext)
  const { user } = loginUser
  const location = useLocation()
  const classes = useStyles()

  // サイドバー項目
  const sidebarItems = [
    {
      link: 'info',
      name: 'お知らせ',
      secondary: null,
      icon: <InfoIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: '',
      name: 'みんなの事例共有',
      secondary: '他社の事例共有が見られます',
      icon: <PostAddIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: `company/${user.companyId}`,
      name: 'わたしの事例共有',
      secondary: 'あなたの会社の事例共有一覧です',
      icon: <BusinessIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: `fb/${user.companyId}`,
      name: 'フィードバック',
      secondary: null,
      icon: <FeedbackIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: 'consult',
      name: '相談部屋',
      secondary: null,
      icon: <QuestionAnswerIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: 'about_site',
      name: 'サイト概要',
      secondary: null,
      icon: <WebAssetIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: 'about_instructors',
      name: '講師紹介',
      secondary: null,
      icon: <SupervisedUserCircleIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: 'my_account',
      name: 'マイページ',
      secondary: null,
      icon: <PersonIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: 'my_account/notice',
      name: '通知設定',
      secondary: null,
      icon: <SettingsIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: 'member',
      name: 'メンバー管理',
      secondary: null,
      icon: <GroupIcon />,
      adminOnly: true,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: 'contact',
      name: 'お問い合わせ',
      secondary: null,
      icon: <MailIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: 'admin',
      name: `${SITEINFO.adminName}管理ページ`,
      secondary: null,
      icon: <FaceIcon />,
      adminOnly: true,
      devOnly: false,
      pajapossOnly: true,
    },
    {
      link: 'admin/import-users',
      name: `一括登録`,
      secondary: null,
      icon: <FaceIcon />,
      nozomi: true,
    },
    {
      link: 'login',
      name: 'ログアウト',
      secondary: null,
      icon: <ExitToAppIcon />,
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
    {
      link: 'signup',
      name: `会員登録\n（会社代表）`,
      secondary: '登録される方にこちらのURLをお送りください',
      icon: '',
      adminOnly: false,
      devOnly: false,
      pajapossOnly: true,
    },
    {
      link: '',
      name: '',
      secondary: null,
      icon: '',
      adminOnly: false,
      devOnly: false,
      pajapossOnly: false,
    },
  ]

  const handleSignOut = () => {
    firebase.auth().signOut().then(() => {
      console.log("ログアウト")
      dispatch({ type: 'RESET' })
    }).catch((error) => {
      console.log("error", error)
    })
  }

  const useClasses = (path) => {
    const data = location.pathname === `/${path}` ?
      (classes.text, classes.nowButtonText) :
      (classes.text)
    return data
  }

  return (
    <div className={classes.sidebar}>

      <Typography variant='body1' component='h1' color='secondary' className={classes.toolbar}>{SITEINFO.title}</Typography>

      <List>
        {sidebarItems.map((sidebarItem, key) => {
          const isCase = sidebarItem.link === ''

          if (sidebarItem.nozomi) { 
            if (user.email !== "acid.hina.nn@gmail.com") return '' 
          }

          // 開発環境のみ
          if (sidebarItem.devOnly) { if (process.env.NODE_ENV !== 'development') return '' }

          // 管理者権限のみ
          if (sidebarItem.adminOnly) { if (!user.admin) return '' }

          // パジャポスメンバーのみ
          if (sidebarItem.pajapossOnly) { if (!user.pajaposs) return '' }

          return <NavLink
            key={key}
            exact
            to={"/" + sidebarItem.link}
            onClick={(sidebarItem.link === 'login') ? () => handleSignOut() : null}
            className={classes.link}
            activeStyle={classes.current}
          >
            <ListItem button className={classes.button}>
              <ListItemIcon style={{
                color: location.pathname === `/${sidebarItem.link}` ? commonTheme.palette.secondary.main : commonTheme.palette.primary.light
              }}>
                {sidebarItem.icon}
              </ListItemIcon>
              <ListItemText
                primary={<span className={isCase ? classes.caseText : ''}>{sidebarItem.name}</span>}
                secondary={sidebarItem.secondary}
                className={useClasses(sidebarItem.link)}
              />
            </ListItem>
          </NavLink>
        })}
      </List>
    </div>
  )
})

export default Sidebar
