import { adminRef, companiesRef, caseRef } from '../firebase'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

export const initialState = {
  admin: {
    companies: [],
    doNotify: false,
    importantInfoKey: null,
    checkNewTags: {},
    allCases: [],
    total: 0,
    error: null
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      console.log("RESET")
      return initialState.admin
    case 'SET_COMPANIES':
      return {
        ...state,
        companies: action.data
      }
    case 'SET_CASE_INFOS':
      if (process.env.NODE_ENV === 'development') {
        console.log("SET_CASE_INFOS", action.data)
      }
      return {
        ...state,
        allCases: action.data,
        total: action.total,
        error: null
      }
    case 'FETCH_CASES_FAILURE':
      return {
        ...state,
        cases: [],
        total: 0,
        error: action.error
      };
    case 'CHANGE_DO_NOTIFY':
      return {
        ...state,
        doNotify: action.data
      }
    case 'CHANGE_IMPORTANT_INFO_KEY':
      return {
        ...state,
        importantInfoKey: action.data
      }
    // TODO: 今は使ってない
    case 'CHANGE_CHECK_NEW_TAGS':
      const key = `${action.id}___${action.number}`
      return {
        ...state,
        checkNewTags: {
          ...state.checkNewTags,
          [key]: action.data,
        }
      }
    default: return state
  }
}

export const actions = {
  getCompanies: (dispatch) => {
    companiesRef
      .orderByKey()
      .once('value', (snapshot) => {
        const companies = snapshot.val()
        const companyArr = Object.entries(companies).map(company => {
          const [key, content] = company
          return { ...content, companyId: key }
        })
        dispatch({
          type: 'SET_COMPANIES',
          data: companyArr
        })
      })
  },
  /**
   * 事例共有情報取得
   * @param {*} dispatch
   * @param {{
   *  searchQuery: {
   *   company: {
   *    companyId: string
   *   }
   *  }
   * }} params
   */
    getCaseInfos: (dispatch, params = {}) => {
      const { searchQuery } = params;

      // ローカルのときだけ
      if (process.env.NODE_ENV === 'development') {
        console.log('getCaseInfos', params);
      }

      let ref = caseRef;

      if (searchQuery) {
        const { company } = searchQuery;
        if (company?.companyId) {
          ref = ref
            .orderByChild('user/companyId')
            .equalTo(company.companyId);
        }
      }

      if (!searchQuery) ref = ref.orderByKey();

      ref.once('value', snapshot => {
        const cases = snapshot.val();

        if (!cases) {
          dispatch({ type: 'SET_CASE_INFOS', data: [], total: 0 });
          return;
        }

        let entries = Object.entries(cases);
        const total = entries.length;

        // NOTE: メモリ圧迫を防ぐため、全件stateに保存しない。 
        // -> それよりダウンロード代が高いので、ページ区切りはクライアントサイドで行う。
        dispatch({ type: 'SET_CASE_INFOS', data: entries, total: total });
      }).catch(error => {
        console.error('Error fetching cases:', error);
        dispatch({ type: 'FETCH_CASES_FAILURE', error });
      });
    },

  getDoNotify: (dispatch) => {
    adminRef
      .child('doNotify')
      .once('value', (snapshot) => {
        dispatch({ type: 'CHANGE_DO_NOTIFY', data: snapshot.val() })
      })
  },
  getImportantInfo: (dispatch) => {
    adminRef
      .child('importantInfo')
      .child('infoKey')
      .once('value', (snapshot) => {
        dispatch({ type: 'CHANGE_IMPORTANT_INFO_KEY', data: snapshot.val() })
      })
  },
  changeDoNotify: (dispatch, bool) => {
    adminRef.update({ doNotify: bool })
    dispatch({ type: 'CHANGE_DO_NOTIFY', data: bool })
  },
  changeImportantInfo: (dispatch, { infoKey }) => {
    console.log('changeImportantInfo', infoKey);
    adminRef.child('importantInfo').update({ infoKey })
    dispatch({ type: 'CHANGE_IMPORTANT_INFO_KEY', data: infoKey })
  },
}
