// CCSデザインのグローバルルール
const STYLES = {
  sidebar: {
    width: "240px"
  },
  input: {
    margin: "10px 0"
  },
  error: {
    marginBottom: '20px',
  },
  share: {
    detailWidth: '45'
  },
}

export default STYLES
