// https://github.com/mui-org/material-ui/blob/3e4854b39b17511d9b312071ca93061eeb503f5d/packages/material-ui/src/styles/createTypography.js
import { createTheme } from '@material-ui/core/styles'

const defaultTheme = createTheme()
const black = '#1c1e21'
// const lightBlack = '#67788A'

// 共通
export const commonTheme = {
  palette: {
    primary: {
      light: '#A8C0DD',
      main: '#395475',
      dark: '#072c49',
      contrastText: '#000000',
    },
    secondary: {
      light: '#FFF5E4',
      main: '#f29f1c',
      dark: '#ba7000',
      contrastText: '#ffffff',
    },
    error: {
      light: '#E66D45',
      main: '#E03800',
      dark: '#C73200',
      contrastText: '#ffffff',
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    fontFamily: "\"Noto Sans JP\", \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", \"sans-serif\"",
    h1: {
      fontWeight: 'bold',
      color: black,
    },
    h2: {
      fontWeight: 'bold',
      color: black,
    },
    h3: {
      fontWeight: 'bold',
      color: black,
    },
    h4: {
      fontWeight: 'bold',
      marginBottom: '30px',
      color: black,
    },
    h5: {
      fontWeight: 'bold',
      marginBottom: '15px',
      color: black,
    },
    h6: {
      fontWeight: 'bold',
      marginBottom: '30px',
      color: black,
    },
    title: {
      fontWeight: 'bold',
      color: black,
    },
    subtitle1: {
      fontWeight: 'bold',
      color: black,
    },
    subtitle2: {
      fontWeight: 'bold',
      lineHeight: '2.57',
      color: black,
    },
    subheading: {
      fontWeight: 'bold',
      color: black,
    },
    body2: {
      color: black,
    },
    body1: {
      color: black,
    },
    // caption: {
    //   color: lightBlack,
    // },
    button: { // .MuiButton-root
      textTransform: "none",
      fontWeight: 'bold',
      margin: defaultTheme.spacing(3, 0, 2),
    }
  },
  // スタイルを上書きする
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#ffffff',
      },
    },
    MuiLink: {
      root: {
        fontWeight: 'bold'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#0000008a',
      },
    },
    MuiCard: {
      root: {
        overflow: 'unset',
      },
    }
    // MuiTypography: {
    //   colorTextSecondary: {
    //     color: lightBlack
    //   }
    // }
  }
}

// メインテーマ
const mainTheme = Object.assign({}, commonTheme)
export const theme = createTheme({
  ...mainTheme,
})

// ログインテーマ
const signInTheme = Object.assign({}, commonTheme)
export const themeSignIn = createTheme({
  ...signInTheme,
  palette: {
    ...signInTheme.palette,
    background: {
      default: signInTheme.palette.primary.main,
    },
  },
})

// カード（背景青）テーマ
const cardTheme = Object.assign({}, commonTheme)
export const themeCard = createTheme({
  // NOTE: とりあえずSignUpと同じ。追加があれば修正。
  ...cardTheme,
  palette: {
    ...signInTheme.palette,
    background: {
      default: signInTheme.palette.primary.main,
    },
  },
})

// お知らせ（背景黄色）テーマ
const infoTheme = Object.assign({}, commonTheme)
export const themeInfo = createTheme({
  // NOTE: とりあえずSignUpと同じ。追加があれば修正。
  ...infoTheme,
  palette: {
    ...signInTheme.palette,
    background: {
      default: signInTheme.palette.secondary.light,
    },
  },
})
