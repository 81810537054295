import React, { useEffect, useContext, useState } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { caseRef, userRef } from "../../firebase/index"
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Button, Card, CardContent } from '@material-ui/core'
import MainTemplate from '../templates/MainTemplate'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { Back } from '../common/Back'
import Papa from 'papaparse';
import Encoding from 'encoding-japanese';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageTitle from '../common/PageTitle'
import EmailCsv from "../../assets/admin/email.csv";
import UserIdCsv from "../../assets/admin/userId.csv";

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
}))

const PostCaseAll = React.memo((props) => {
  const history = useHistory()
  const classes = useStyles()

  const { state: { loginUser }, dispatch } = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    console.log("actions");
    return () => {
      dispatch({ type: 'COMMON_RESET' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser.isLogin])

  const handlePost = async (csv) => {
    console.log("parsed csv", csv)

    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')

    // csvを一行ずつ処理
    // {
    //   vimeoUrl: "/videos/560261871"
    //   userId: "-MdCf_SGYE4boJvCBEpZ"
    //   email: "aaaaaa"
    //   contentText: "テスト投稿"
    //   campanyName: "テスト会社"
    //   name: "ああああああ"
    // }
    try {
      await Promise.all(csv.data.map(async (row, i) => {
        // user.key からユーザーを検索
        // メールアドレスからユーザーを検索
        let user = null
        if (!!row.userId) {
          // NOTE: userIdのとき
          await userRef.child(row.userId).once('value', (snapshot) => {
            const val = snapshot.val()
            return user = {
              userId: row.userId,
              ...val,
            }
          })
        } else if (!!row.email) {
          // NOTE: emailのとき
          await userRef.orderByChild('email')
            .startAt(row.email).endAt(row.email)
            .once('value', (snapshot) => {
              const sp = snapshot.val()
              if (!sp) {
                throw new Error(`${i+2} [ ${row.name || '' }${row.email || '' }${row.userId || '' } ] 以降の登録が失敗しました。emailをご確認ください`)
              }
              const key = Object.keys(sp)
              const val = Object.values(sp)
              return user = {
                userId: key[0],
                ...val[0],
              }
            })
        } else {
          throw new Error(`${i+2} [ ${row.name || '' }${row.email || '' }${row.userId || '' } ] 以降の登録が失敗しました。userIdかemailを指定してください`)
        }

        console.log(user)

        if (!user) {
          throw new Error(`${i+1} [ ${row.email || '' }${row.userId || '' } ] 以降の登録が失敗しました。ユーザー情報が正しく取得できません。userIdかemailををご確認ください`)
        }

        const inputUser = {
          company: user.company,
          companyInfo: {
            companyId: user.companyInfo.companyId,
            name: user.companyInfo.name,
          },
          name: user.name,
          uid: user.uid,
          userId: user.userId,
        }

        console.log("inputUser", inputUser);

        const input = {
          user: inputUser,
          createAt: now,
          industryTags: user.companyInfo.industryTags,
          memberNumTags: user.companyInfo.memberNumTags,
          prefTags: user.companyInfo.prefTags,
          text: row.contentText,
          video: {
            thumbnailUrls: [ "https://i.vimeocdn.com/video/default_100x75?r=pad" ],
            vimeoUrls: [ row.vimeoUrl ]
          },
        }

        console.log("input", input);

        await caseRef.push(input)
        .then()
        .catch((e) => {
          throw new Error(`${i+1} [ ${row.email || '' }${row.userId || '' } ] 以降の登録失敗しました。`)
        })
        return
      }))
      alert("成功しました。")
      setLoading(false)
    } catch (error) {
      alert(error)
      setLoading(false)
    }
  }

  const handleParseCsv = (event) => {
    setLoading(true)
    const file = event.target.files[0]
    console.log("input csv file")

    const reader = new FileReader()
    reader.onload = (e) => {
      const codes = new Uint8Array(e.target.result)
      const encoding = Encoding.detect(codes)
      const unicodeString = Encoding.convert(codes, {
        to: 'unicode',
        from: encoding,
        type: 'string',
      })
      Papa.parse(unicodeString, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => handlePost(results),
      })
    }
    reader.readAsArrayBuffer(file)
  }

  if (!loginUser.isLogin) { return <MainTemplate></MainTemplate> }

  return (
    <MainTemplate>

      {/* 戻るボタン */}
      <Back history={history} />

      <PageTitle title="事例共有一括共有" />

      <p>
        投稿者の<span style={{fontWeight: 'bold'}}>userId</span>か<span style={{fontWeight: 'bold'}}>email</span>を必ず指定してください。
        <br/>
        <span style={{fontWeight: 'bold'}}>userId</span>は<span style={{fontWeight: 'bold'}}>realtime database</span>の
        <a 
          target='_blank'
          rel='noopener noreferrer'
          href="https://console.firebase.google.com/u/0/project/css-site-d0ddf/database/css-site-d0ddf-default-rtdb/data/~2Fusers?hl=ja"
        >
          <span style={{fontWeight: 'bold'}}>users</span>
        </a>
        のキー（例：-MX9rOUWXh4wRTspQMuH）です。
        <br/>
      </p>
      <a href={UserIdCsv} download>
        <Button color="secondary">userId csvダウンロード</Button>
      </a>
      <a href={EmailCsv} download>
        <Button color="secondary">email csvダウンロード</Button>
      </a>

      <Card className={classes.card}>
        <CardContent style={{ position: 'relative' }}>
            <p>「ファイルを選択」をクリックして、CSVをアップロードしてください</p>
            <p style={{color: 'red', fontWeight: 'bold'}}>即時で事例共有投稿されます。</p>
            <input
              type="file"
              onChange={(e) => handleParseCsv(e)}
              disabled={loading}
            />
            {loading && <CircularProgress />}
        </CardContent>
      </Card >

      {/* 戻るボタン */}
      <Back history={history} />
    </MainTemplate >
  )
})
export default PostCaseAll
