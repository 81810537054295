import React, { useReducer, createContext } from 'react'
import { initialState as commonInit, reducer as commonReducer } from "./useCommonReducer"
import { initialState as signinInit, reducer as signinReducer } from "./useSignInReducer"
import { initialState as signupInit, reducer as signupReducer } from "./useSignUpReducer"
import { initialState as loginUserInit, reducer as loginUserReducer } from "./useLoginUserReducer"
import { initialState as shareCaseInit, reducer as shareCaseReducer } from "./useShereCaseReducer"
import { initialState as accountInit, reducer as accountReducer } from "./useAccountReducer"
import { initialState as consultInit, reducer as consultReducer } from "./useConsultReducer"
import { initialState as infoInit, reducer as infoReducer } from "./useInfoReducer"
import { initialState as adminInit, reducer as adminReducer } from "./useAdminReducer"

const initialState = {
  ...commonInit,
  ...signinInit,
  ...signupInit,
  ...loginUserInit,
  ...shareCaseInit,
  ...accountInit,
  ...consultInit,
  ...infoInit,
  ...adminInit,
}

export const GlobalContext = createContext()

const reducers = (state = {}, action = {}) => {
  if (process.env.NODE_ENV === "development") { console.log("action", action) }
  return {
    common: commonReducer(state.common, action),
    signin: signinReducer(state.signin, action),
    signup: signupReducer(state.signup, action),
    loginUser: loginUserReducer(state.loginUser, action),
    shareCase: shareCaseReducer(state.shareCase, action),
    account: accountReducer(state.account, action),
    consult: consultReducer(state.consult, action),
    info: infoReducer(state.info, action),
    admin: adminReducer(state.admin, action),
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState)
  if (process.env.NODE_ENV === "development") { console.log("reducer", state) }

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
