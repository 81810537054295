import React from 'react'
import { Typography } from '@material-ui/core'
import SITEINFO from "../../../constants/siteInfo"

const Caution = React.memo((props) => (
  <Typography
    variant='body2'
    color='error'
    style={{ textAlign: 'center' }}
  >
    ※こちらは、池本・{SITEINFO.adminName}・全体への、質問と相談の場です。<br />
    ※参加会社への質問は、「みんなの事例共有」のコメントからお願いいたします。
  </Typography>
))
export default Caution
