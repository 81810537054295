import firebase, { userRef } from "../firebase/index"

export const initialState = {
  loginUser: {
    user: {
      company: '', // 廃止
      companyInfo: {},　// 入れ替え
      name: '',
      uid: '',
      email: '', // firebase auth
      imageUrl: '',
    },
    isLogin: false,
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      console.log("RESET");
      return initialState.loginUser
    case 'SET_LOGIN_USER':
      return {
        ...state,
        user: action.data,
        isLogin: true,
      }
    case 'SET_LOGIN_COMPANY':
      return {
        ...state,
        user: {
          ...state.user,
          companyInfo: action.data
        }
      }
    default: return state
  }
}

export const actions = {
  loginCheck: (dispatch, history) => {
    console.log("login start")
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid
        userRef
          .orderByChild('uid').startAt(uid).endAt(uid).limitToFirst(1)
          .once('value', (snapshot) => {
            const loginUser = snapshot.val()
            if (loginUser) {
              dispatch({
                type: 'SET_LOGIN_USER',
                data: {
                  ...Object.values(loginUser)[0],
                  email: user.email,
                  userId: Object.keys(loginUser)[0]
                }
              })
            }
          })
      } else {
        console.log("ログインしていません")
        dispatch({ type: 'RESET' })
        history.push('/login')
      }
    })
  },
  login: (user, currentPassword) => firebase.auth()
    .signInWithEmailAndPassword(user.email, currentPassword)
    .then()
    .catch((error) => {
      throw new Error(`ログインに失敗しました ${error.message}`)
    })
}
