import React from 'react'
import SITEINFO from "../../constants/siteInfo"
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Container, CssBaseline, Typography } from '@material-ui/core'
import { themeSignIn } from "../../materialui/theme"
import { ThemeProvider } from "@material-ui/styles"
import { commonTheme } from "../../materialui/theme"
import Copyright from '../common/Copyright'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  title: {
    color: commonTheme.palette.secondary.main
  },
  back: {
    width: '100%',
  },
  link: {
    color: commonTheme.palette.primary.light,
  },
}))

const SignInTemplate = React.memo((props) => {
  const classes = useStyles()
  const { minWidth } = props

  return (
    <ThemeProvider theme={themeSignIn}>
      <Container component="main" maxWidth={minWidth || 'xs'} className={clsx(classes.paper, 'signin')}>
        <CssBaseline />
        <div className={classes.toolbar} />
        <Typography component="h1" variant="h4" className={classes.title}>{SITEINFO.title}</Typography>
        {props.children}
        <Copyright />
      </Container>
    </ThemeProvider>
  )
})

export default SignInTemplate
