import React, { useContext, useEffect } from "react"
import STYLES from "../../constants/styles"
import { commonTheme, theme, themeInfo } from "../../materialui/theme"
import { ThemeProvider } from "@material-ui/styles"
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Toolbar,
  AppBar,
  CssBaseline,
  IconButton,
  Drawer,
  Hidden,
} from '@material-ui/core'
import {
  Menu as MenuIcon
} from '@material-ui/icons'
import SideBar from "../common/SideBar"
import HeaderButtons from "../common/HeaderButtons"
import Copyright from '../common/Copyright'
import Search from '../common/Search'
import { useLocation } from 'react-router'
import * as Scroll from 'react-scroll'
import { GlobalContext } from '../../hooks/reducer'
import { actions as shareCaseActions } from '../../hooks/useShereCaseReducer'
import { actions as adminActions } from '../../hooks/useAdminReducer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  margin: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: STYLES.sidebar.width,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      marginLeft: STYLES.sidebar.width,
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: commonTheme.palette.primary.main,
    },
  },
  menuButton: {
    color: commonTheme.palette.primary.light,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: STYLES.sidebar.width,
    background: commonTheme.palette.primary.main,
    border: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    // NOTE: これがないとnorapの時に、レスポンシブで崩れる
    width: '0%',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      minHeight: '84px',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '90px'
    },
  },
}))

const useInfoStyles = makeStyles((theme) => ({
  appBar: {
    ...useInfoStyles.appBar,
    backgroundColor: commonTheme.palette.primary.main,
  },
}))

// NOTE: 共有事例検索窓を表示するURL
const casePath = ['']

const scroll = Scroll.animateScroll

const MainTemplate = React.memo((props) => {
  const { window, mainwidth, customTheme } = props
  const classes = useStyles()
  const infoClass = useInfoStyles()
  const location = useLocation()
  const path = location.pathname.split('/')[1]
  const displayableSearch = casePath.includes(path)

  const { state: { shareCase }, dispatch } = useContext(GlobalContext)
  const { industryTagList, memberNumTagList, prefTagList, otherTagList } = shareCase

  const [mobileOpen, setMobileOpen] = React.useState(false)

  let applyTheme = theme
  switch (customTheme) {
    case 'info':
      applyTheme = themeInfo
      break
    default:
      break
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const container = window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    scroll.scrollToTop({
      smooth: false,
      duration: 0,
      delay: 0,
    })

    // タグを読み込む
    if (industryTagList.length <= 0) { shareCaseActions.getIndustries(dispatch) }
    if (memberNumTagList.length <= 0) { shareCaseActions.getMemberNums(dispatch) }
    if (prefTagList.length <= 0) { shareCaseActions.getPrefs(dispatch) }
    if (otherTagList.length <= 0) { shareCaseActions.getOtherTags(dispatch) }

    shareCaseActions.getMasterData(dispatch)
    adminActions.getImportantInfo(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={applyTheme}>
      <Container component="div" className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={customTheme !== 'info' ? classes.appBar : infoClass.appBar}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.margin}></div>
            {displayableSearch && <Search />}
            <HeaderButtons props={props} />
          </Toolbar>
        </AppBar>

        <nav className={classes.drawer} aria-label="サイドバー">
          {/* SP */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <SideBar />
            </Drawer>
          </Hidden>
          {/* PC */}
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <SideBar />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content} style={{ width: !!mainwidth ? mainwidth : '0%' }}>
          <div className={classes.toolbar} />
          {props.children}
          <Copyright />
        </main>
      </Container>
    </ThemeProvider >
  )
})

export default MainTemplate
