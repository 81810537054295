import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { GlobalContext } from '../../hooks/reducer'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { Search as SearchIcon } from '@material-ui/icons'
import CreatableSelect from 'react-select/creatable'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '24px 15px 16px 0',
    minWidth: '150px',
    width: '100%',
    maxWidth: '250px',
    '& > div': {
      borderRadius: '30px'
    }
  },
  groupLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  badge: {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  },
  icon: {
    marginBottom: '-5px',
    marginRight: '6px',
  }
}))

const Search = React.memo((props) => {
  const classes = useStyles()

  const { state: { common, shareCase }, dispatch } = useContext(GlobalContext)
  const { searchTag } = common
  const { industryTagList, memberNumTagList, prefTagList, feedbackCaseTag, otherTagList } = shareCase;

  // groupedOptionsをuseMemoでメモ化し、不要な再レンダリングを防ぐ
  const groupedOptions = useMemo(() => [
    {
      label: 'フィードバック',
      options: feedbackCaseTag.id ? [feedbackCaseTag] : [], // feedbackCaseTagが存在しない場合は空配列
    },
    {
      label: '所在地',
      options: prefTagList,
    },
    {
      label: '業界・業種',
      options: industryTagList,
    },
    {
      label: '社員数',
      options: memberNumTagList,
    },
    {
      label: 'その他',
      options: otherTagList,
    },
  ], [feedbackCaseTag, prefTagList, industryTagList, memberNumTagList, otherTagList]);

  const formatGroupLabel = data => (
    <div className={classes.groupLabel}>
      <span>{data.label}</span>
      <span className={classes.badge}>{data.options.length}</span>
    </div>
  )

  return (
    <CreatableSelect
      className={classes.root}
      isClearable
      onChange={(e) => dispatch({ type: 'CHANGE_SEARCH_TAG', data: e })}
      options={groupedOptions}
      placeholder={
        <React.Fragment>
          <SearchIcon fontSize='small' className={classes.icon} />
          {isWidthUp('sm', props.width) ? '事例共有を検索できます' : '事例検索'}
        </React.Fragment>
      }
      value={searchTag}
      formatGroupLabel={formatGroupLabel}
    />
  )
})
export default withWidth()(Search)
