import React, { useContext, useEffect, } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import {
  Link as LinkStyle,
  Typography,
} from '@material-ui/core'
import CardTemplate from '../templates/CardTemplate'
import { GlobalContext } from '../../hooks/reducer'

// const useStyles = makeStyles((theme) => ({
// }))

const SignUpComplete = React.memo((props) => {
  const { dispatch } = useContext(GlobalContext)

  useEffect(() => {
    return () => { dispatch({ type: 'COMMON_RESET' }) }
  }, [dispatch])

  return (
    <CardTemplate>
      <Typography variant='h5'>登録が完了しました</Typography>
      <LinkStyle
        component={Link}
        to="/"
        underline="always"
      >
        <Typography variant='h4'>トップページへ</Typography>
      </LinkStyle>
    </CardTemplate >
  )
})
export default SignUpComplete
