import React, { useEffect, useRef, useContext, useState } from 'react'
// import firebase from '../../firebase/index'
import STYLES from "../../constants/styles"
// import SITEINFO from "../../constants/siteInfo"
import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Avatar,
  Button,
  Badge,
  Card,
  CardContent,
  CardMedia,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import {
  Close as CloseIcon,
  Edit as EditIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons'
import thumbsUpContainIcon from '../../assets/fontawesome/svgs/solid/thumbs-up.svg'
import thumbsUpIcon from '../../assets/fontawesome/svgs/regular/thumbs-up.svg'
import commentIcon from '../../assets/fontawesome/svgs/regular/comment-alt.svg'
import sampleImage from '../../assets/movie.jpg'
import { GlobalContext } from '../../hooks/reducer'
import { actions as shareCaseActions } from "../../hooks/useShereCaseReducer"
import { actions as commonActions } from "../../hooks/useCommonReducer"
import { Tags } from './common/Tags'
import { Comment } from '../common/Comment'
import { Vimeo } from './common/Vimeo'
import { commonTheme } from '../../materialui/theme'
import ProgressModal from '../common/ProgressModal'
import MsgVideoLoading from '../common/MsgVideoLoading'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const detailWidth = STYLES.share.detailWidth
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: '40px 0',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrap: {
    flex: '1 0 auto',
  },
  content: {},
  media: {
  },
  contentBottom: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '14px 0',
  },
  thumUp: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'flex-end',
  },
  commentWrap: {
    marginTop: '10px',
  },
  commentTtl: {
    marginBottom: '5px',
  },
  comment: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  commentForm: {
    display: 'flex',
    // alignItems: 'end',
    marginTop: '15px',
    alignItems: 'stretch',
  },
  commentFormBtn: {
    margin: 0,
    width: '100%',
  },
  textField: {
    "& > input": {
      paddingTop: '0.55em',
      paddingBottom: '0.55em',
    }
  },
  caution: {
    marginLeft: 0,
    marginRight: 0,
    color: theme.palette.error.main,
  },
  movieMediaWrap: {
    display: 'flex',
    margin: '10px 0',
    minHeight: '60px',
  },
  movieMedia: {
    maxWidth: '100px',
    maxHeight: '60px',
    margin: '0 15px 0 0',
  },
  movieMediatxt: {
    fontSize: '0.8em',
    color: theme.palette.error.main,
  }
}))

const CaseList = React.memo((props) => {
  const { state: { shareCase, loginUser, common }, dispatch } = useContext(GlobalContext)
  const { isLoading } = common
  const {
    isShowCmntField, editComments, goodCaseKeys, caseGoods, isShowCmnts,
    isShowCmntAlls, isShowMore, caseAllGoods, inputCommentVideos
  } = shareCase
  const {
    content, comments,
    hash, handleDispatch, inputComment,
  } = props
  const { key, text, user, createAt, video } = content
  const goodNum = caseAllGoods.filter(g => g.caseKey === key).length

  const [disableTxt, setDisableTxt] = useState(null)
  const [disabled, setDisabled] = useState(true)

  const inputVideo = inputCommentVideos[key]
  const [uploadVideo, setUploadVideo] = useState(false)

  const visibleComments = comments.length > 0 ?
    (!!isShowCmntAlls[key] ? comments : comments.slice(-2)) : []

  const isBreakpoint = isWidthUp('md', props.width)
  const editable = commonActions.editable(user, loginUser.user)

  let videoId = ''
  if (!!video && !!video.vimeoUrls && video.vimeoUrls.length > 0) {
    const uri = video.vimeoUrls[0]
    const pos = uri.lastIndexOf('/')
    videoId = uri.slice(pos + 1)
  }

  const history = useHistory()
  const classes = useStyles()
  const ref = useRef()

  useEffect(() => {
    if (hash === ('#case-' + key)) { ref.current.scrollIntoView({ behavior: "smooth" }) }
  }, [hash, key])

  useEffect(() => {
    const txt = '1文字以上入力してください。'
    if (!inputComment || isLoading) {
      setDisabled(true)
    }
    if (inputComment && !isLoading) setDisabled(false)

    if (uploadVideo && !inputComment) setDisableTxt(txt)
    else setDisableTxt(null)
  }, [inputComment, isLoading, uploadVideo])

  const handleUploadVimeo = (e) => {
    commonActions.uploadVimeo(dispatch, e, loginUser.user, key, 'caseComment')
  }

  const handleDeleteVimeo = (uri, i) => {
    commonActions.deleteVimeo(dispatch, uri, i, key, 'caseComment')
  }

  return (
    <Card
      ref={ref}
      id={'case-' + key}
      className={classes.root}
      style={{
        flexDirection: isBreakpoint ? 'row' : 'column',
        alignItems: 'flex-start',
      }}
    >
      {
        isBreakpoint && <React.Fragment>
          {
            // md以上でvimeo表示
            (!!video && video.vimeoUrls && video.vimeoUrls.length > 0) && <Vimeo
              videoId={videoId}
              isWidthUp={isBreakpoint}
              thisCase={content}
            />
          }
          {
            (!video || !video.vimeoUrls || video.vimeoUrls.length <= 0) && <CardMedia
              className={classes.media}
              style={{
                width: isBreakpoint ? ((100 - detailWidth) + '%') : '100%',
                objectFit: 'contain',
              }}
              image={sampleImage}
              component="img"
            />
          }
        </React.Fragment>
      }

      <div className={classes.details} style={{ width: isBreakpoint ? (detailWidth + '%') : '100%' }}>

        <CardHeader
          avatar={<Avatar
            src={user.imageUrl}
            onClick={() => history.push(`/company/${user.companyId}`)}
            className='avatar'
          />}
          title={user ? user.name : "名無しさん"}
          subheader={(!!user && !!user.companyInfo) ? user.companyInfo.name : ""}
          style={{ padding: '16px 16px 0' }}
        />

        <CardHeader
          subheader={<Typography variant='caption'>※アイコン画像を押すと、会社投稿一覧が見れます</Typography>}
          style={{ padding: '0 16px 16px', fontSize: '11px', color: commonTheme.palette.error.light }}
        />

        <Divider />

        {
          // md未満でvimeo表示
          (!isBreakpoint && !!video && video.vimeoUrls && video.vimeoUrls.length > 0) && <Vimeo
            videoId={videoId}
            isWidthUp={isBreakpoint}
            thisCase={content}
          />
        }

        <CardContent className={classes.contentWrap}>

          {/* 内容 */}
          <Typography
            variant="body1"
            className={clsx('indention', classes.content)}
            dangerouslySetInnerHTML={commonActions.changeToURL(text)}
          />

          {/* タグ一覧 */}
          <IconButton
            style={{ paddingLeft: 0 }}
            onClick={() => {
              dispatch({ type: 'CHANGE_SHOW_MARE', data: !isShowMore[key], key: key })
            }}
          >
            <Typography
              variant="caption"
              style={{
                fontWeight: 'bold',
                textDecorationLine: 'underline',
              }}
            >
              {isShowMore[key] ? '内容を一部隠す' : '内容を全て表示する'}
            </Typography>
            {/* {isShowMore[key] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
          </IconButton>
          {isShowMore[key] && <Tags tags={content} />}

          {/* 時間・編集 */}
          <div className={classes.contentBottom}>
            <p style={{ margin: '0' }}>
              <Typography variant="caption">
                {dayjs(createAt).format('YYYY年MM月DD日 HH:mm')}
              </Typography>
            </p>

            {
              editable && <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: 0 }}
                onClick={() => history.push(`/case/edit/${key}`)}
              >
                <EditIcon fontSize='small' style={{ marginRight: '5px' }} />
                投稿を編集する
              </Button>
            }
          </div>

          <Divider />

          {/* いいね */}
          <IconButton
            style={{ paddingLeft: 0 }}
            onClick={() => shareCaseActions.changeGood(handleDispatch, {
              goodCaseKeys: goodCaseKeys,
              caseGoods: caseGoods,
              caseKey: key,
              user: loginUser.user
            })}
          >
            {
              goodCaseKeys.includes(content.key) ? (
                <React.Fragment>
                  <CardMedia
                    className='global_icon global_icon_txt'
                    image={thumbsUpContainIcon}
                  />
                  <Typography variant="caption" color="secondary">いいね！</Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <CardMedia
                    className='global_icon global_icon_txt'
                    image={thumbsUpIcon}
                  />
                  <Typography variant="caption">いいね！</Typography>
                </React.Fragment>
              )
            }
            {goodNum > 0 && <Chip className='good' size="small" label={goodNum} />}
          </IconButton>

          <Divider />

          {/* コメント */}
          <div className={classes.commentWrap}>
            {
              comments.length > 0 && <React.Fragment>

                {/* コメントをすべて表示・非表示 */}
                {
                  comments.length > 2 ? (
                    <IconButton
                      style={{ paddingLeft: 0 }}
                      onClick={() => {
                        handleDispatch({
                          type: 'IS_SHOW_CMNT_ALL',
                          field: key,
                          data: !isShowCmntAlls[key],
                        })
                      }}
                    >
                      {/* コメントが２件以上の表示 */}
                      <CardMedia
                        className='global_icon global_icon_txt'
                        image={commentIcon}
                      />
                      <Typography
                        variant="caption"
                        style={{
                          fontWeight: 'bold',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {!!isShowCmntAlls[key] ? 'コメントを隠す' : `${comments.length}件のコメントをすべて表示する`}
                      </Typography>
                      {!!isShowCmntAlls[key] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  ) : (
                    <IconButton
                      style={{ paddingLeft: 0, color: 'rgba(0, 0, 0, 0.54)' }}
                      disabled={true}
                      component='div'
                    >
                      {/* コメントが２件以下の表示 */}
                      <CardMedia
                        className='global_icon global_icon_txt'
                        image={commentIcon}
                      />
                      <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                        コメント
                      </Typography>
                    </IconButton>
                  )
                }

                {/* コメント一覧 */}
                <List style={{ paddingTop: 0 }}>
                  {
                    visibleComments.map((comment, key) => (
                      <Comment
                        key={key}
                        comment={comment}
                        isShowCmntField={isShowCmntField}
                        editComments={editComments}
                        handleDispatch={handleDispatch}
                        loginUser={loginUser}
                        isShowCmnts={isShowCmnts}
                        replyType=''
                        commentActions={shareCaseActions}
                        contentType='case'
                        commentKey={comment.key}
                      />
                    ))
                  }
                </List>
              </React.Fragment>
            }

            { comments.length > 0 && <Divider style={{marginBottom: '12px'}} /> }

            {/******* コメントフォーム *******/}

            {/* 動画のアップロード */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={uploadVideo}
                  onClick={e => setUploadVideo(!uploadVideo)}
                />
              }
              label={
                <span style={{fontSize: '0.8em', color: '#0000008a'}}>
                  {uploadVideo ? '動画のアップロードをやめる' : 'コメントに動画をアップロードする'}
                </span>
              }
              style={{marginBottom: '10px'}}
            />

            {
              uploadVideo && <div style={{position: "relative"}}>
                <span style={{fontSize: '0.8em', color: '#0000008a'}}>
                  アップロードする動画を選択してください。
                </span>
                <input
                  type="file"
                  accept="video/*"
                  onChange={e => handleUploadVimeo(e)}
                />
                {isLoading && <ProgressModal class_name="loading-comment" />}

                <div className={classes.movieMediaWrap}>
                  {
                    inputVideo && inputVideo.video &&
                    inputVideo.video.thumbnailUrls &&
                    inputVideo.video.thumbnailUrls.length > 0 &&
                    inputVideo.video.thumbnailUrls.map(
                      (url, i) => <Grid item key={i} style={{ marginTop: '10px' }}>
                        <Badge
                          color="error"
                          badgeContent={<CloseIcon />}
                          onClick={() => handleDeleteVimeo(inputVideo.video.vimeoUrls[i], i)}
                        >
                          <CardMedia
                            className={classes.movieMedia}
                            component="img"
                            image={url}
                            key={i + "cardmedia"}
                          />
                        </Badge>
                      </Grid>
                    )
                  }
                </div>

                {
                  disableTxt && <Typography className={classes.movieMediatxt}>
                    {disableTxt}
                  </Typography>
                }
                <MsgVideoLoading align='left' isLoading={isLoading} />
              </div>
            }

            {/* コメントフォーム */}
            <div className={classes.commentForm}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <OutlinedInput
                  value={inputComment || ''}
                  onChange={(e) => handleDispatch({ type: 'CHANGE_COMMENT_VALUE', data: e.target.value, field: key })}
                  aria-describedby=""
                  inputProps={{ 'aria-label': '', }}
                  labelWidth={0}
                  placeholder="ここにコメントを入力する"
                  className={clsx(classes.textField)}
                  multiline
                />
              </FormControl>
            </div>

            <Button
              variant="contained"
              color="secondary"
              className={classes.commentFormBtn}
              onClick={() => {
                shareCaseActions.postComment(handleDispatch, {
                  text: inputComment,
                  user: loginUser.user,
                  caseKey: key,
                  video: inputVideo,
                  isUploadVideo: uploadVideo,
                })
                const isVideo = inputVideo && inputVideo.video &&
                                inputVideo.video.vimeoUrls &&
                                inputVideo.video.vimeoUrls.length > 0 &&
                                inputVideo.video.thumbnailUrls &&
                                inputVideo.video.thumbnailUrls.length > 0
                if (isVideo && !uploadVideo) {
                  commonActions.deleteAllVimeo(dispatch, inputVideo.video)
                }
                setUploadVideo(false)
              }}
              disabled={disabled}
            >
              投稿
            </Button>

            <FormHelperText className={clsx(classes.caution)}>
              ※コメントで会社に「質問・感想」などを投稿することができます。
            </FormHelperText>
          </div>
        </CardContent>
      </div>
    </Card >
  )
})
export default withWidth()(CaseList)
