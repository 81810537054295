import React, { useContext } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import {
  Divider,
  Typography,
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import { GlobalContext } from '../../hooks/reducer'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: '40px 0',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrap: {
    flex: '1 0 auto',
  },
  content: {},
  media: {
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
  },
  contentBottom: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '14px 0',
  },
  thumUp: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'flex-end',
  },
  commentWrap: {
    marginTop: '10px',
  },
  commentTtl: {
    marginBottom: '5px',
  },
  comment: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  commentForm: {
    display: 'flex',
    alignItems: 'end',
    marginTop: '15px',
  },
  textField: {
    "& > input": {
      paddingTop: '0.55em',
      paddingBottom: '0.55em',
    }
  },
  caution: {
    marginLeft: 0,
    marginRight: 0,
    color: theme.palette.error.main,
  },
  wrap: {
    display: 'flex',
  },
  importantMark: {
    background: theme.palette.secondary.main,
    textAlign: 'center',
    fontWeight:'bold',
    padding: '5px',
    borderRadius: '4px',
    color: '#ffffff',
  },
  left: {
    width: '85%',
  },
  right: {
    width: '15%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
}))

const InfoList = React.memo((props) => {
  const { content, history } = props
  const { key } = content

  const { state: { admin } } = useContext(GlobalContext)
  const { importantInfoKey } = admin

  const classes = useStyles()

  return (
    <li
      style={{ cursor: 'pointer' }}
      onClick={() => history.push(`/info/edit/${key}`)}
      >

      <div className={classes.wrap}>
        <div className={classes.left}>
          {/* タイトル・内容 */}
          <div style={{ paddingBottom: 0 }}>
            <ListItem
              style={{ paddingBottom: 0 }}
              component='div'
            >
              <ListItemIcon>
                <Avatar src={content.user.imageUrl} />
              </ListItemIcon>
              <ListItemText
                className={classes.content}
                primary={content.title}
                secondary={content.text}
              />
            </ListItem>
          </div>

          {/* 投稿者・日時 */}
          <div className={classes.capWrap}>
            <Typography
              component='p'
              className={classes.caption}
              variant='caption'
            >
              {dayjs(content.updateAt).tz().format('YYYY年MM月DD日 HH:mm')}
            </Typography>
            <p className={classes.caption} style={{ marginBottom: '14px' }}>
              <Typography variant='caption' style={{ marginRight: '5px' }}>
                {content.user.name}
              </Typography>
              <Typography variant='caption'>
                {content.user.companyInfo.name}
              </Typography>
            </p>
          </div>
        </div>

        <div className={classes.right}>
          {
            key === importantInfoKey && <Typography component="div" variant='caption' className={classes.importantMark}>
              重要
            </Typography>
          }
        </div>
      </div>

      <Divider component="div" />
    </li>
  )
})
export default withWidth()(InfoList)
