import React, { useEffect, useContext } from 'react'
import MODAL from "../../constants/modal"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import MainTemplate from '../templates/MainTemplate'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as commonActions } from "../../hooks/useCommonReducer"
import { actions as signupActions } from "../../hooks/useSignUpReducer"
import { actions as accountActions } from "../../hooks/useAccountReducer"
import { AvatarLink } from '../common/AvatarLink'
import CustomModal from '../common/CustomModal'
import firebase, { companiesRef } from '../../firebase/index'
import Select from 'react-select'
import { FormErrorMsg } from '../common/FormErrorMsg'
import { Tags } from '../shareCase/common/Tags'
import PageTitle from '../common/PageTitle'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    padding: '60px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px',
    },
  },
  formWrap: {
    margin: '20px 0',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '10px',
  },
  form: {
    width: '100%',
  },
  avatarWrap: {
    width: '100%',
    marginTop: 0,
    border: 'solid 1px #bdbdbd',
    borderRadius: '5px',
    padding: '10px',
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  tagFrom: {
    marginBottom: '20px',
  },
  noticed: {
    marginRight: '15px'
  }
}))

const MyAccount = React.memo((props) => {
  const history = useHistory()
  const classes = useStyles()

  const { state: { loginUser, account, common, shareCase, admin }, dispatch } = useContext(GlobalContext)
  const { validation, isOpen } = common
  const { doNotify } = admin
  const { user } = loginUser
  const { companyInfo } = user
  const { my, myCompany } = account
  const { editUser, isOpenPassword, currentPassword } = my
  const { editCompany } = myCompany
  const isAdmin = user.admin
  const { industryTagList, memberNumTagList, prefTagList } = shareCase


  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    // if (loginUser.isLogin) { accountActions.setNotice(dispatch, user) }
    return () => dispatch({ type: 'COMMON_RESET' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, loginUser.isLogin])

  // input監視
  useEffect(() => {
    if (my.isOpen) {
      const unallowed = (!my.submit.name || !my.submit.email || !currentPassword)
      const unallowedPass = isOpenPassword ? !validation.password.submit : false
      if (unallowed || unallowedPass) {
        // ボタン無効
        !my.disabled && dispatch({ type: 'CHANGE_MY_DISABLED', data: true, field: 'my' })
      } else {
        // ボタン有効
        my.disabled && dispatch({ type: 'CHANGE_MY_DISABLED', data: false, field: 'my' })
      }
    }

    if (myCompany.isOpen) {
      const unallowed = (!myCompany.submit.name)
      if (unallowed) {
        !myCompany.disabled && dispatch({ type: 'CHANGE_MY_DISABLED', data: true, field: 'myCompany' })
      } else {
        myCompany.disabled && dispatch({ type: 'CHANGE_MY_DISABLED', data: false, field: 'myCompany' })
      }
    }
  }, [
    dispatch, my.isOpen, my.disabled, my.submit, isOpenPassword, currentPassword,
    validation.password.submit, myCompany.isOpen, myCompany.disabled, myCompany.submit,
  ])

  useEffect(() => {
    // オープンの時に、editUserにログイン情報をセットする
    if (!my.isOpen && !myCompany.isOpen) {
      dispatch({ type: 'ACCOUNT_RESET' })
      dispatch({ type: 'COMMON_RESET' })
    }
    if (my.isOpen) {
      if (myCompany.isOpen) { dispatch({ type: 'ACCOUNT_COMPANY_RESET' }) }
      dispatch({ type: 'SET_EDIT_USER', data: user })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, my.isOpen])

  useEffect(() => {
    // オープンの時に、editCompanyにログイン情報をセットする
    if (!my.isOpen && !myCompany.isOpen) {
      dispatch({ type: 'ACCOUNT_RESET' })
      dispatch({ type: 'COMMON_RESET' })
    }
    if (myCompany.isOpen) {
      if (my.isOpen) { dispatch({ type: 'ACCOUNT_USER_RESET' }) }
      dispatch({ type: 'SET_EDIT_COMAPNY', data: companyInfo })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, myCompany.isOpen])

  const handleUpdateUser = async () => {
    console.log('update user start')

    const updatePassword = () => {
      const currentUser = firebase.auth().currentUser
      return currentUser.updatePassword(editUser.password)
        .then().catch((error) => {
          throw new Error(`更新に失敗しました。 ${error.message}`)
        })
    }

    const updateEmail = () => {
      const currentUser = firebase.auth().currentUser
      return currentUser.updateEmail(editUser.email)
        .then().catch((error) => {
          throw new Error(`更新に失敗しました。 ${error.message}`)
        })
    }

    // 処理
    try {
      const updateUser = await accountActions.setUserData(editUser)
      dispatch({ type: 'PROGRESS', data: 10 })
      await actions.login(user, currentPassword)
      if (isOpenPassword) { await updatePassword() }
      if (editUser.email !== user.email) { await updateEmail() }
      dispatch({ type: 'PROGRESS', data: 40 })
      // 会員情報更新
      await accountActions.updateUser(dispatch, {
        updateData: updateUser,
        type: 'login',
        currentUser: user,
        editUser: editUser,
      })
      dispatch({ type: 'PROGRESS', data: 70 })
      await accountActions.updateInPost(dispatch, 'user', updateUser)
      dispatch({ type: 'PROGRESS', data: 90 })

      // 画像を変更した場合、元の画像削除
      if (!!user.imageName && user.imageName !== editUser.imageName) {
        console.log('delete prof image start')
        signupActions.deleteImage(`profile/${user.imageName}`)
      }

      // NOTE: メールアドレス・パスワードが変わったときのみ通知
      if (editUser.email !== user.email || isOpenPassword) {
        commonActions.sendMail({ email: editUser.email, name: editUser.name }, 'sendUserChangeMail', doNotify)
      }

      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'ACCOUNT_RESET' })
      console.log('handleUpdateUser stop')
    } catch (error) {
      console.log(error.message)
      dispatch({ type: 'PROGRESS_RESET' })
      dispatch({ type: 'ERROR', data: error.message })
      dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.error })
    }
  }

  const handleUpdateCompany = async () => {
    console.log('Update Company start')

    const setCompanyData = () => accountActions.setCompanyData({
      ...user.companyInfo,
      ...editCompany
    })

    const updateCompany = (updataData) => companiesRef
      .child(companyInfo.companyId)
      .update(updataData)

    // 会員情報変更
    const updateUserCompany = async (updataData) => {
      await accountActions.updateInPost(dispatch, 'company', updataData)
      return dispatch({
        type: 'SET_LOGIN_USER', data: {
          ...user,
          companyInfo: updataData,
        }
      })
    }

    // 処理
    try {
      const updateData = await setCompanyData()
      dispatch({ type: 'PROGRESS', data: 10 })
      await updateCompany(updateData)
      dispatch({ type: 'PROGRESS', data: 50 })
      await updateUserCompany(updateData)
      dispatch({ type: 'PROGRESS', data: 70 })
      // 画像を変更した場合、元の画像削除
      if (!!companyInfo.imageName && companyInfo.imageName !== editCompany.imageName) {
        signupActions.deleteImage(`company/${companyInfo.imageName}`)
      }
      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'ACCOUNT_RESET' })
    } catch (error) {
      console.log(error.message)
      dispatch({ type: 'PROGRESS_RESET' })
      dispatch({ type: 'ERROR', data: error.message })
      dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.error })
    }
  }

  if (!loginUser.isLogin) { return <MainTemplate></MainTemplate> }

  return (
    <MainTemplate>
      {isOpen && <CustomModal />}

      <PageTitle title='マイページ' />

      {/***************** プロフィール *****************/}
      <Container component={Card} maxWidth="sm" className={clsx(classes.card, 'signform', 'indention')}>

        {/* プロフィール・変更ボタン */}
        <Grid container justifyContent='space-between' className={classes.formWrap}>
          <Grid item>
            <Typography variant='h6' style={{ margin: 0 }}>プロフィール</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: 0 }}
              onClick={() => dispatch({ type: 'CHANGE_IS_OPEN_EDIT_USER', data: !my.isOpen })}
            >
              {my.isOpen ? '変更をやめる' : '変更する'}
            </Button>
          </Grid>
        </Grid>

        {/* 現在のパスワード入力 */}
        {
          my.isOpen && <Grid container className={classes.formWrap}>
            <Grid item xs={12}>
              <Typography color='error' variant='caption' align='center'>
                ▼プロフィールを変更するには、
                <span style={{ fontWeight: 'bold' }}>
                  ご自身のログインパスワード
                </span>
                の入力が必要です
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant='body2' className={classes.label}>現在の
              {isWidthUp('sm', props.width) && <br />}
              ログインパスワード</Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                type='password'
                value={currentPassword}
                onChange={(e) => dispatch({ type: 'CHANGE_CURRENT_PASSWORD', data: e.target.value })}
                variant="outlined"
                required
                className={classes.form}
              />
            </Grid>
          </Grid>
        }

        {/* アバター */}
        <Grid container className={classes.formWrap}>
          {
            my.isOpen && <Box className={clsx(classes.avatarWrap)}>
              {/* TODO: サイズ参考 : 2mb : 5000mbで有料 : 2500枚保存 */}
              {/* https://alaki.co.jp/blog/?p=1156#i-2 */}
              <Grid container alignItems='flex-end'>
                <Grid item sm={12} md={3}>
                  <Avatar src={editUser.imageBase64} className={classes.avatar} />
                </Grid>
                <Grid item sm={12} md={9}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => accountActions.setPreview(dispatch, e, 'user')}
                    style={{ margin: '10px 0', display: 'block' }}
                  />
                  <AvatarLink />
                </Grid>
              </Grid>

            </Box>
          }
          {!my.isOpen && <Avatar src={user.imageUrl} className={classes.avatar} />}
        </Grid>

        {/* 名前 */}
        <Grid container className={classes.formWrap}>
          <Grid item sm={4} xs={12}>
            <Typography variant='body2' className={classes.label}>名前</Typography>
          </Grid>
          <Grid item sm={8} xs={12}>
            {
              my.isOpen && <TextField
                value={editUser.name}
                onChange={(e) => {
                  dispatch({ type: 'CHANGE_EDIT_USER_VALUE', data: e.target.value, field: 'name' })
                  dispatch({ type: 'VALIDATE_NAME', data: e.target.value })
                }}
                onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'name' })}
                onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'name' })}
                variant="outlined"
                required
                className={classes.form}
                error={validation.name.isShowError}
                helperText={validation.name.isShowError ? validation.name.errorMsg : ''}
              />
            }
            {!my.isOpen && <Typography>{user.name}</Typography>}
          </Grid>
        </Grid>

        {/* 会社 */}
        <Grid container className={classes.formWrap}>
          <Grid item sm={4} xs={12}>
            <Typography variant='body2' className={classes.label}>会社</Typography>
          </Grid>
          <Typography component={Grid} item sm={8} xs={12}>{user.companyInfo.name}</Typography>
        </Grid>

        {/* メールアドレス */}
        <Grid container className={classes.formWrap}>
          <Grid item sm={4} xs={12}>
            <Typography variant='body2' className={classes.label}>メールアドレス</Typography>
          </Grid>
          <Grid item sm={8} xs={12}>
            {
              my.isOpen && <TextField
                value={editUser.email}
                onChange={(e) => {
                  dispatch({ type: 'CHANGE_EDIT_USER_VALUE', data: e.target.value, field: 'email' })
                  dispatch({ type: 'VALIDATE_EMAIL', data: e.target.value })
                }}
                onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'email' })}
                onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'email' })}
                variant="outlined"
                required
                className={classes.form}
                error={validation.email.isShowError}
                helperText={validation.email.isShowError ? validation.email.errorMsg : ''}
              />
            }
            {!my.isOpen && <Typography>{user.email}</Typography>}
          </Grid>
        </Grid>

        {/* パスワード */}
        <Grid container className={classes.formWrap}>
          {
            my.isOpen && <React.Fragment>
              {/* <Button
                color="primary"
                size='small'
                style={{ margin: 0 }}
                onClick={() => dispatch({ type: 'CHANGE_IS_OPEN_PASSWORD', data: !isOpenPassword })}
              >
                パスワード変更をしない場合はこちらをクリック<ExpandMoreIcon />
              </Button> */}
              <Grid item xs={12}>
                <Button
                  color="primary"
                  size='small'
                  style={{ margin: 0, fontWeight: 'normal' }}
                  onClick={() => dispatch({ type: 'CHANGE_IS_OPEN_PASSWORD', data: !isOpenPassword })}
                >
                  <p style={{ textAlign: 'left' }}>
                    {
                      isOpenPassword ?
                        (<React.Fragment>
                          <span style={{ fontWeight: 'bold' }}>パスワード変更をしない場合</span>
                          はこちらをクリックしてください
                        </React.Fragment>) :
                        (<React.Fragment>
                          <span style={{ fontWeight: 'bold' }}>パスワードを変更する場合</span>は<br />
                          こちらをクリックして新しいパスワードを入力してください
                        </React.Fragment>)
                    }
                  </p>
                </Button>
              </Grid>
              {
                isOpenPassword && <React.Fragment>
                  <TextField
                    label="新しいパスワードを入力"
                    type="password"
                    value={editUser.password}
                    onChange={(e) => {
                      dispatch({ type: 'CHANGE_EDIT_USER_VALUE', data: e.target.value, field: 'password' })
                      dispatch({ type: 'VALIDATE_PASSWORD', data: e.target.value, confirm: editUser.passwordConfirm })
                    }}
                    onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'password' })}
                    onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'password' })}
                    variant="outlined"
                    required
                    error={validation.password.isShowError}
                    className={'signform_input'}
                  />
                  <TextField
                    label="新しいパスワードを再入力"
                    type="password"
                    value={editUser.passwordConfirm}
                    onChange={(e) => {
                      dispatch({ type: 'CHANGE_EDIT_USER_VALUE', data: e.target.value, field: 'passwordConfirm' })
                      dispatch({ type: 'VALIDATE_PASSWORD', data: e.target.value, confirm: editUser.password })
                    }}
                    onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'password' })}
                    onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'password' })}
                    variant="outlined"
                    required
                    error={validation.password.isShowError}
                    helperText={validation.password.isShowError ? validation.password.errorMsg : ''}
                    className={'signform_input'}
                  />
                </React.Fragment>
              }
            </React.Fragment>
          }
          {
            !my.isOpen && <React.Fragment>
              <Grid item sm={4} xs={12}>
                <Typography
                  variant='body2'
                  className={classes.label}
                  style={{ padding: '6px 0' }}
                >パスワード</Typography>
              </Grid>
              <Typography>******</Typography>
            </React.Fragment>
          }
        </Grid>

        {
          my.isOpen && <React.Fragment>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: '10px' }}
              onClick={() => dispatch({ type: 'CHANGE_IS_OPEN_EDIT_USER', data: false })}
            >
              変更をやめる
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleUpdateUser()
                dispatch({ type: 'IS_LOADING', data: true })
                dispatch({
                  type: 'IS_OPEN',
                  data: { bool: true, stage: MODAL.progress, path: '' }
                })
              }}
              disabled={my.disabled}
            >
              プロフィールを変更する
            </Button>
          </React.Fragment>
        }

      </Container>

      {/***************** 所属企業 *****************/}
      <Container
        component={Card}
        maxWidth="sm"
        className={clsx(classes.card, 'signform', 'indention')}
        style={{ marginTop: '40px' }}
      >

        {/* 会社情報・変更ボタン */}
        <Grid container justifyContent='space-between' className={classes.formWrap}>
          <Grid item>
            <Typography variant='h6' style={{ margin: 0 }}>会社情報</Typography>
          </Grid>
          {
            isAdmin && <Grid item>
              <Button
                variant="outlined"
                color="primary"
                style={{ margin: 0 }}
                onClick={() => dispatch({ type: 'CHANGE_IS_OPEN_COMPANY', data: !myCompany.isOpen })}
              >
                {myCompany.isOpen ? '変更をやめる' : '変更する'}
              </Button>
            </Grid>
          }
        </Grid>

        {/* 会社アカウントを見る */}
        <Grid container justifyContent='space-between' className={classes.formWrap}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 0 }}
              onClick={() => history.push(`/company/${companyInfo.companyId}`)}
            >
              会社アカウントを見る
            </Button>
          </Grid>
        </Grid>

        {/* 会社アバター */}
        <Grid container className={classes.formWrap}>
          {
            myCompany.isOpen && <Box className={clsx(classes.avatarWrap)}>
              <Grid container alignItems='flex-end'>
                <Grid item sm={12} md={3}>
                  <Avatar src={editCompany.imageBase64} className={classes.avatar} />
                </Grid>
                <Grid item sm={12} md={9}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => accountActions.setPreview(dispatch, e, 'mainCompany')}
                    style={{ margin: '10px 0', display: 'block' }}
                  />
                  <AvatarLink />
                </Grid>
              </Grid>
            </Box>
          }
          {!myCompany.isOpen && <Avatar src={companyInfo.imageUrl} className={classes.avatar} />}
        </Grid>

        {/* 会社名 */}
        <Grid container className={classes.formWrap}>
          <Grid item sm={4} xs={12}>
            <Typography variant='body2' className={classes.label}>会社名</Typography>
          </Grid>
          <Grid item sm={8} xs={12}>
            {
              myCompany.isOpen && <TextField
                value={editCompany.name}
                onChange={(e) => {
                  dispatch({ type: 'CHANGE_EDIT_COMPANY_VALUE', data: e.target.value, field: 'name' })
                  dispatch({ type: 'VALIDATE_NAME', data: e.target.value })
                }}
                onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'name' })}
                onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'name' })}
                variant="outlined"
                required
                className={classes.form}
                error={validation.name.isShowError}
                helperText={validation.name.isShowError ? validation.name.errorMsg : ''}
              />
            }
            {!myCompany.isOpen && <Typography>{companyInfo.name}</Typography>}
          </Grid>
        </Grid>

        {/* ウェブサイト */}
        <Grid container className={classes.formWrap}>
          <Grid item sm={4} xs={12}>
            <Typography variant='body2' className={classes.label}>ウェブサイト</Typography>
          </Grid>
          <Grid item sm={8} xs={12}>
            {
              myCompany.isOpen && <TextField
                value={editCompany.url}
                onChange={(e) => dispatch({ type: 'CHANGE_EDIT_COMPANY_VALUE', data: e.target.value, field: 'url' })}
                variant="outlined"
                className={classes.form}
              />
            }
            {
              !myCompany.isOpen && <Link
                href={companyInfo.url}
                color='primary'
                underline='hover'
                target='_blank'
              >{companyInfo.url}</Link>
            }
          </Grid>
        </Grid>

        {/* 会社概要 */}
        <Grid container className={classes.formWrap}>
          <Grid item sm={4} xs={12}>
            <Typography variant='body2' className={classes.label}>会社概要</Typography>
          </Grid>
          <Grid item sm={8} xs={12}>
            {
              myCompany.isOpen && <TextField
                value={editCompany.description}
                onChange={(e) => dispatch({ type: 'CHANGE_EDIT_COMPANY_VALUE', data: e.target.value, field: 'description' })}
                variant="outlined"
                multiline
                minRows={4}
                className={classes.form}
              />
            }
            {!myCompany.isOpen && <Typography style={{ whiteSpace: 'pre-line' }}>
              {!!companyInfo.description && companyInfo.description}
            </Typography>}
          </Grid>
        </Grid>

        {/* タグ */}
        <Grid container className={classes.formWrap}>
          {
            myCompany.isOpen && <React.Fragment>
              <Grid item sm={4} xs={12}>
                <Typography variant='body2' className={classes.label}>業種・業界</Typography>
              </Grid>
              <Grid item sm={8} xs={12} className={classes.tagFrom}>
                <Select
                  className={classes.tag}
                  onChange={(e) => {
                    dispatch({ type: 'CHANGE_EDIT_COMPANY_VALUE', data: e, field: 'industryTags' })
                    dispatch({ type: 'VALIDATE_INDUSTRIES', data: e })
                  }}
                  label="業界・業種"
                  isMulti
                  options={industryTagList}
                  name="industries"
                  value={editCompany.industryTags}
                  onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'industries' })}
                  onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'industries' })}
                />
                {validation.industries.isShowError && <FormErrorMsg msg={validation.industries.errorMsg} />}
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography variant='body2' className={classes.label}>所在地</Typography>
              </Grid>
              <Grid item sm={8} xs={12} className={classes.tagFrom}>
                <Select
                  className={classes.tag}
                  onChange={(e) => {
                    dispatch({ type: 'CHANGE_EDIT_COMPANY_VALUE', data: e, field: 'prefTags' })
                    dispatch({ type: 'VALIDATE_PREFECTURES', data: e })
                  }}
                  label="所在地"
                  isMulti
                  options={prefTagList}
                  name="prefs"
                  value={editCompany.prefTags}
                  onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'prefectures' })}
                  onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'prefectures' })}
                  error={validation.prefectures.isShowError}
                  helperText={validation.prefectures.errorMsg}
                />
                {validation.prefectures.isShowError && <FormErrorMsg msg={validation.prefectures.errorMsg} />}
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography variant='body2' className={classes.label}>社員数</Typography>
              </Grid>
              <Grid item sm={8} xs={12} className={classes.tagFrom}>
                <Select
                  className={classes.tag}
                  onChange={(e) => {
                    dispatch({ type: 'CHANGE_EDIT_COMPANY_VALUE', data: e, field: 'memberNumTags' })
                    dispatch({ type: 'VALIDATE_MEMBER_NUM', data: e })
                  }}
                  label="社員数"
                  options={memberNumTagList}
                  name="memberNum"
                  value={editCompany.memberNumTags}
                  onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'memberNum' })}
                  onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'memberNum' })}
                  error={validation.memberNum.isShowError}
                  helperText={validation.memberNum.errorMsg}
                />
                {validation.memberNum.isShowError && <FormErrorMsg msg={validation.memberNum.errorMsg} />}
              </Grid>
            </React.Fragment>
          }
          {!myCompany.isOpen && <Tags tags={companyInfo} customStyle='account' />}
        </Grid>

        {
          myCompany.isOpen && <React.Fragment>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: '10px' }}
              onClick={() => dispatch({ type: 'CHANGE_IS_OPEN_COMPANY', data: false })}
            >
              変更をやめる
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleUpdateCompany()
                dispatch({ type: 'IS_LOADING', data: true })
                dispatch({
                  type: 'IS_OPEN',
                  data: { bool: true, stage: MODAL.progress, path: '' }
                })
              }}
              disabled={myCompany.disabled}
            >
              会社情報を変更する
            </Button>
          </React.Fragment>
        }

      </Container>

    </MainTemplate >
  )
})
export default withWidth()(MyAccount)
