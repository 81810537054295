import React from 'react'
import SignInTemplate from '../templates/SignInTemplate'
import Manual from '../about/common/Manual'
import { useHistory } from 'react-router'
import { Back } from '../common/Back'

const SignInManual = React.memo((props) => {
  const history = useHistory()

  return (
    <SignInTemplate minWidth='md'>
      <Back history={history} alignLeft />
      <Manual />
      <Back history={history} alignLeft />
    </SignInTemplate>
  )
})
export default SignInManual
