import React from 'react'
import { Typography } from '@material-ui/core'

// NOTE: <Select>はreact-selectを使用しており、既存のエラーメッセージ機能が無いので、このコンポーネントを活用する
export const FormErrorMsg = ({ msg }) => {
  // 参考スタイル
  // .MuiFormHelperText-root
  // .MuiFormHelperText-contained
  // .MuiFormHelperText-root.Mui-error
  return <Typography style={{
    color: '#E03800',
    margin: '3px 14px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em'
  }}>{msg}</Typography>
}
