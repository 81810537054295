import React, { useEffect, useContext, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, Link } from 'react-router-dom'
import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import MainTemplate from "../templates/MainTemplate"
import {
  Button,
  Card,
  Container,
  FormControlLabel,
  List,
  Switch,
  Typography,
  Divider,
} from '@material-ui/core'
import InfoList from './InfoList'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as adminActions } from "../../hooks/useAdminReducer"
import { actions as infoActions } from "../../hooks/useInfoReducer"
import CustomModal from "../common/CustomModal"
import Pagination from '@material-ui/lab/Pagination'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '20px',
    rowGap: '10px',
    margin: '20px 0',
  },
  list: {},
  pagenation: {
    marginBottom: '10px'
  },
  button: {
    margin: '0',
  },
}))

const Admin = React.memo((props) => {
  const classes = useStyles()
  const history = useHistory()
  const ref = useRef()
  const fixedRef = useRef()

  const { state: { info, admin, loginUser }, dispatch } = useContext(GlobalContext)
  const { infoes } = info
  const { doNotify, importantInfoKey } = admin

  const [fixedPage, setFixedPage] = useState(1)
  const [totalFixedPages, setTotalFixedPages] = useState(0)
  const perFixedPage = 2
  const startFixedNum = (fixedPage - 1) * perFixedPage
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const perPage = 20
  const startNum = (page - 1) * perPage

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin) {
      infoActions.getInfos(dispatch)
      adminActions.getDoNotify(dispatch)
    }
    return () => { dispatch({ type: 'COMMON_RESET' }) }
  }, [dispatch, history, loginUser.isLogin])

  useEffect(() => {
    if (loginUser.isLogin) {
      setTotalPages(Math.ceil(infoes.filter(info => info.type === 'info').length / perPage))
      setTotalFixedPages(Math.ceil(infoes.filter(info => info.type === 'fixedInfo').length / perFixedPage))
    }
  }, [infoes, loginUser.isLogin])

  useEffect(() => { fixedRef.current.scrollIntoView({ behavior: "smooth" }) }, [fixedPage])
  useEffect(() => { ref.current.scrollIntoView({ behavior: "smooth" }) }, [page])

  return (
    <MainTemplate>

      {/* モーダル */}
      <CustomModal />

      <Container component={Card} style={{ paddingBottom: '20px', marginBottom: '30px' }}>
        <Typography variant='h6' style={{ margin: '20px 0 0' }}>設定</Typography>
        <Typography variant='caption' component='p' color='error'>※一時的に全体の通知を停止したい場合、「通知を許可」をオフしてください。</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={doNotify}
              onChange={(e) => adminActions.changeDoNotify(dispatch, e.target.checked)}
              name='doNotify'
              color="primary"
            />
          }
          label='通知を許可'
        />
      </Container>

      <div className={classes.grid}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push('/admin/companies')}
        >参加会社一覧</Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => history.push('/admin/case_management')}
        >事例共有管理画面</Button>

        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.button)}
          onClick={() => history.push('/admin/tags')}
        >タグ管理画面</Button>
      </div>

      <div className={classes.grid}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => history.push('/info/post')}
        >お知らせを投稿する</Button>

        <Button
          variant="contained"
          color="default"
          className={clsx(classes.button)}
          onClick={() => history.push('/info')}
          ref={fixedRef}
        >お知らせページ</Button>

        <Button
          variant="contained"
          color="default"
          className={clsx(classes.button)}
          onClick={() => history.push('/admin/post_cases')}
        >共有事例一括投稿</Button>
      </div>

      {/* 固定投稿 */}

      <Container
        component={Card}
        style={{ marginTop: '40px' }}
      >

        <Typography variant='h6' style={{ margin: '20px 0 0' }}>固定投稿</Typography>

        <div style={{ margin: '16px 0 0' }}>
          ※重要な投稿が設定されて{importantInfoKey ? 'います' : 'いません'}。
          {
            importantInfoKey && <Link to={'/info/edit/' + importantInfoKey}>
              重要な投稿を編集する
            </Link>
          }
          <Divider component="div" style={{ margin: '16px 0 0' }} />
        </div>

        <List className={classes.list}>
          {
            infoes && infoes
              .filter(info => info.type === 'fixedInfo')
              .slice(startFixedNum, startFixedNum + perFixedPage)
              .map((info, i) => <InfoList key={i} content={info} history={history} />)
          }
        </List>
        <Pagination
          count={totalFixedPages}
          size="large" page={fixedPage}
          onChange={(e, newPage) => setFixedPage(newPage)}
          className={classes.pagenation}
          ref={ref}
        />

      </Container>

      {/* 通常投稿 */}
      <Container
        component={Card}
        style={{ marginTop: '40px' }}
      >

        <Typography variant='h6' style={{ margin: '20px 0 0' }}>通常投稿</Typography>
        <List className={classes.list}>
          {
            infoes && infoes
              .filter(info => info.type === 'info')
              .slice(startNum, startNum + perPage)
              .map((info, i) => <InfoList key={i} content={info} history={history} />)
          }
        </List>
        <Pagination
          count={totalPages}
          size="large" page={page}
          onChange={(e, newPage) => setPage(newPage)}
          className={classes.pagenation}
        />

      </Container>

    </MainTemplate >
  )
})
export default Admin
