import React, { useEffect, useState, /*useContext*/ } from 'react'
import STYLES from "../../../constants/styles"
import Player from "@vimeo/player"
import { caseRef, caseCommentsRef } from '../../../firebase'
// import { CircularProgress } from '@material-ui/core'
// import { GlobalContext } from '../../../hooks/reducer'
// https://www.npmjs.com/package/@vimeo/player#embed-options

const detailWidth = STYLES.share.detailWidth

export const Vimeo = (props) => {
  const { isWidthUp, videoId, thisCase, type } = props
  const uploadState = !!thisCase.videoUploaded
  const [uploaded, setUploaded] = useState(uploadState)
  // const { state: { shareCase }, /*dispatch*/ } = useContext(GlobalContext)
  // const { vimeoShow } = shareCase

  // NOTE: 動画を上寄せにする
  const percent = isWidthUp ? 44 : 84

  const iframeId = `vimeo-${videoId}`

  const check = (player) => {
    console.log('start check', videoId);
    let targetRef = null
    switch (type) {
      case 'caseComments':
        targetRef = caseCommentsRef
        break;
      default:
        // case
        targetRef = caseRef
        break;
    }

    if (!uploaded) {
      player.getVideoUrl().then((url) => {
        console.log('動画のURL;', url);
        setUploaded(true)
        targetRef.child(thisCase.key).update({videoUploaded: true})
      }).catch((error) => {
        console.log('error', error, videoId);
      })
    }
  }

  // videoIDがかわったら状態を監視して、
  // 読み込み終わったら、表示する
  useEffect(() => {
    const options = {
      id: videoId,
      byline: false, //投稿者情報の非表示
      portrait: false, //左上Vimeoのロゴ非表示
      title: false, //タイトルの非表示
    }

    const player = new Player(iframeId, options)

    // 最初に１回実行
    setTimeout(() => {
      console.log("start player first check")
      check(player)
    }, 1000)

    let time = null
    // タイマーで実行
    if (!uploaded) {
      time = setInterval(() => {
        // 動画のURL取得して、正しく表示されるか確認
        // 表示されない場合、文言を表示する
        check(player)
      }, 10000) // NOTE: トライ間隔を１分から短くした
      // プライバシーエラーが出る理由は、不明
    } else {
      clearInterval(time)
    }

    // iframeにstateをもたせる
    const wrapEl = document.getElementById(iframeId)
    const iframeEl = wrapEl.firstElementChild

    // NOTE: iframeが表示された時点で実行する
    if (iframeEl) {
      // 手動で、iframeのsrc変えてしまう
      const src = iframeEl.src
      var outofSrc = src.replace(/\?.*$/,"")
      // 末尾の数字だけ取得
      const pos = outofSrc.lastIndexOf('/')
      const preId = outofSrc.slice(pos + 1)
      const newSrc = src.replace(preId, videoId)
      iframeEl.src = newSrc
      // setloading(false)
    }

    return () => {
      clearInterval(time)
      // dispatch({ type: 'SET_VIMEO_STATES', data: false, field: videoId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaded, videoId])

  const baseStyles = {
    width: isWidthUp ? ((100 - detailWidth) + '%') : '100%',
    paddingBottom: `calc(var(--video--height) / var(--video--width) * ${percent}%)`,
  }
  const cmntStyles = {
    width: '90%',
    marginLeft: '10%',
  }

  return <React.Fragment>
  <div
    className="embed-container"
    style={type === 'caseComments' ? cmntStyles : baseStyles}
  >
    <div id={iframeId}></div>
    {/* <div id={iframeId} style={ !vimeoShow ? {display: 'none'} : {display: 'block'}}></div> */}
    {/* <CircularProgress size="80px" style={{
      position: 'absolute',
      top: 0,
      left: 0,
    }} /> */}

    <div
      style={{
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        left: `50%`,
        bottom: '-20px',
        transform: `translateX(-50%)`,
        fontSize: '0.8em',
      }}
    >
      ※アップロード処理・読み込みに時間がかかることがあります
    </div>
  </div>
  </React.Fragment>
}
