import React from 'react'
import ReactDOM from 'react-dom'
import './scss/index.scss'
import App from './components/App'
import GlobalContextProvider from "./hooks/reducer"

// NOTE: <React.StrictMode><App /><React.StrictMode />はmaterial-uiの問題でワーニングが出る
ReactDOM.render(
  // <GlobalContext.Provider value={AppStateData}>
  <GlobalContextProvider>
    <App />
  </GlobalContextProvider>
  , document.getElementById('root')
);
