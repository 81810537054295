import React, { useContext, useEffect, } from 'react'
import { companiesRef } from "../../firebase/index"
import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, } from 'react-router-dom'
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  Link as LinkStyle,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  Business as BusinessIcon,
} from '@material-ui/icons'
import CardTemplate from '../templates/CardTemplate'
import { GlobalContext } from '../../hooks/reducer'
import { commonTheme } from "../../materialui/theme"
import Select from 'react-select'
import { FormErrorMsg } from '../common/FormErrorMsg'
import CustomModal from '../common/CustomModal'
import MODAL from '../../constants/modal'
import { actions as signupActions } from '../../hooks/useSignUpReducer'
import { actions as accountActions } from '../../hooks/useAccountReducer'
import { actions as shareCaseActions } from '../../hooks/useShereCaseReducer'
import { Required } from '../common/Required'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  formWrap: {
    margin: '5px 0',
    width: '100%',
  },
  number: {
    background: commonTheme.palette.primary.main,
    borderRadius: '14px',
    width: '25px',
    height: '25px',
    textAlign: 'center',
    color: '#ffffff',
    padding: '3px',
    marginRight: '5px',
  },
  profile: {
    margin: '10px 0'
  },
  avatarWrap: {
    marginTop: 0,
    border: 'solid 1px #bdbdbd',
    borderRadius: '5px',
    padding: '10px',
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  tagLabel: {
    margin: '0 0 4px',
  },
}))

const SignUp = React.memo((props) => {
  const { state: { signup, common, shareCase }, dispatch } = useContext(GlobalContext)
  const { user, mainCompany } = signup
  const { industryTagList, memberNumTagList, prefTagList } = shareCase
  const { disabled, validation, isLoading, isOpen } = common
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    const unallowed = (
      !validation.name.submit || !validation.email.submit ||
      !validation.password.submit || !validation.companyName.submit ||
      !validation.industries.submit || !validation.prefectures.submit ||
      !validation.memberNum.submit || isLoading
    )

    if (unallowed) {
      // ボタン無効
      !disabled && dispatch({ type: 'CHANGE_DISABLED', data: true })
    } else {
      // ボタン有効
      disabled && dispatch({ type: 'CHANGE_DISABLED', data: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation, isLoading, disabled, dispatch])

  useEffect(() => {
    // タグを読み込む
    if (industryTagList.length <= 0) { shareCaseActions.getIndustries(dispatch) }
    if (memberNumTagList.length <= 0) { shareCaseActions.getMemberNums(dispatch) }
    if (prefTagList.length <= 0) { shareCaseActions.getPrefs(dispatch) }
    return () => {
      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'SIGNUP_RESET' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handlePost = async () => {
    console.log('会員登録 start')
    dispatch({ type: 'PROGRESS', data: 0 })

    const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')

    /* NOTE: 登録でエラーが出る条件
    * メールは一意
    * メールの形式じゃない（ex: gmail.c -> ng）
    * パスワードは６文字以上
    * */

    let createdCompany = {}

    // 会社登録
    const createCompany = () => companiesRef.push({
      ...mainCompany,
      imageBase64: '',
      createAt: now,
    }, (error) => {
      if (error) {
        console.log('createCompany error')
        throw new Error("会社登録に失敗しました")
      } else {
        console.log('createCompany done')
      }
    })

    const getCompany = () => companiesRef.orderByChild('name')
      .startAt(mainCompany.name).endAt(mainCompany.name)
      .limitToFirst(1)
      .once('value', (snapshot) => {
        const loginCompany = snapshot.val()
        const tmpKey = Object.keys(loginCompany)[0]
        const tmpContent = Object.values(loginCompany)[0]
        createdCompany = { companyId: tmpKey, ...tmpContent }
      })

    // 処理
    try {
      const { createdUser } = await signupActions.createUser(user.email, user.password)
      dispatch({ type: 'PROGRESS', data: 40 })
      await createCompany()
      dispatch({ type: 'PROGRESS', data: 70 })
      await getCompany()
      const addUser = {
        ...createdUser,
        ...user,
        admin: true,
      }
      dispatch({ type: 'PROGRESS', data: 90 })
      const addUserInfo = await signupActions.addUserInfo(addUser, createdCompany)
      dispatch({
        type: 'SET_LOGIN_USER',
        data: {
          userId: addUserInfo.key,
          name: addUser.name,
          uid: addUser.uid,
          admin: true,
          company: createdCompany.name, //TODO: 廃止
          companyInfo: createdCompany,
          imageUrl: addUser.imageUrl,
          imageName: addUser.imageName,
          email: addUser.email,
        }
      })
      // 完了画面
      history.push('/signup/complete')
    } catch (error) {
      console.log(error.message)
      dispatch({ type: 'PROGRESS_RESET' })
      dispatch({ type: 'ERROR', data: error.message })
      dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.error })
    }
  }

  return (
    <CardTemplate manual={true}>

      {isOpen && <CustomModal />}

      {// TODO: メッセージの日本語化
        // errors.isError && <Grid item xs className={classes.errorMessage}>
        //   <Typography component="p" align="center">
        //     ログインに失敗しました
        //   </Typography>
        //   <Typography component="p" align="center">
        //     {errors.message}
        //   </Typography>
        // </Grid>
      }


      <Box className='signform'>

        <FormControl className='' variant="outlined" fullWidth={true}>

          {/* ---------------- 管理者登録 ---------------- */}
          <Box style={{ marginBottom: '20px' }}>
            <Typography variant="h6" align="center">管理者会員登録</Typography>

            <Typography variant='body2' style={{ marginBottom: '5px' }}>
              ▼プロフィール写真をアップロード
            </Typography>
            <Typography variant='caption' color='error' style={{ marginBottom: '5px' }}>
              ※アップロードしない場合、デフォルトの画像が設定されます。
            </Typography>

            <Box className={clsx(classes.formWrap, classes.avatarWrap)}>

              {/* TODO: サイズ参考 : 2mb : 5000mbで有料 : 2500枚保存 */}
              {/* https://alaki.co.jp/blog/?p=1156#i-2 */}
              <Grid container alignItems='flex-end'>
                <Grid item xs={12} sm={3} className={classes.profile}>
                  <Avatar src={user.imageBase64} className={classes.avatar} />
                </Grid>
                <Grid item xs={12} sm={9} className={classes.profile}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => accountActions.setPreview(dispatch, e, 'user', true)}
                  />
                </Grid>
              </Grid>

              <LinkStyle
                color="primary"
                href='https://photocombine.net/edit/'
                target="_blank"
                rel="noopener"
                underline='hover'
              >
                <Typography variant="caption">画像の切り抜き・リサイズができるサイトはこちら</Typography>
              </LinkStyle>
            </Box>

            <TextField
              label={<React.Fragment>名前<Required /></React.Fragment>}
              value={user.name}
              onChange={(e) => {
                dispatch({ type: 'CHANGE_SIGNUP_USER_VALUE', data: e.target.value, field: 'name' })
                dispatch({ type: 'VALIDATE_NAME', data: e.target.value })
              }}
              onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'name' })}
              onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'name' })}
              variant="outlined"
              // required
              className={clsx(classes.formWrap, 'signform_input')}
              error={validation.name.isShowError}
              helperText={validation.name.isShowError ? validation.name.errorMsg : ''}
            />

            <TextField
              label={<React.Fragment>メールアドレス<Required /></React.Fragment>}
              value={user.email}
              onChange={(e) => {
                dispatch({ type: 'CHANGE_SIGNUP_USER_VALUE', data: e.target.value, field: 'email' })
                dispatch({ type: 'VALIDATE_EMAIL', data: e.target.value })
              }}
              onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'email' })}
              onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'email' })}
              variant="outlined"
              // required
              className={clsx(classes.formWrap, 'signform_input')}
              error={validation.email.isShowError}
              helperText={validation.email.isShowError ? validation.email.errorMsg : ''}
            />

            <Box className={classes.formWrap}>
              <TextField
                label={<React.Fragment>パスワード<Required /></React.Fragment>}
                type="password"
                value={user.password}
                onChange={(e) => {
                  dispatch({ type: 'CHANGE_SIGNUP_USER_VALUE', data: e.target.value, field: 'password' })
                  dispatch({ type: 'VALIDATE_PASSWORD', data: e.target.value, confirm: user.passwordConfirm })
                }}
                onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'password' })}
                onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'password' })}
                className={'signform_input'}
                variant="outlined"
                // required
                error={validation.password.isShowError}
              />
              <TextField
                label={<React.Fragment>パスワード再入力<Required /></React.Fragment>}
                type="password"
                value={user.passwordConfirm}
                onChange={(e) => {
                  dispatch({ type: 'CHANGE_SIGNUP_USER_VALUE', data: e.target.value, field: 'passwordConfirm' })
                  dispatch({ type: 'VALIDATE_PASSWORD', data: e.target.value, confirm: user.password })
                }}
                onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'password' })}
                onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'password' })}
                className={'signform_input'}
                variant="outlined"
                // required
                error={validation.password.isShowError}
                helperText={validation.password.isShowError ? validation.password.errorMsg : ''}
              />
            </Box>
          </Box>

          <Typography variant='body2' align='center'>
            続けて、会社の登録をお願いします。
          </Typography>
          {/* ↓  */}
          <div style={{
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '27px 50px 0 50px',
            borderColor: '#ff0000 transparent transparent transparent',
            margin: '10px auto 30px'
          }}></div>

          {/* ---------------- 会社登録 ---------------- */}
          <Box>
            <Typography variant="h6" align="center">会社登録</Typography>

            <Typography variant='body2' style={{ marginBottom: '5px' }}>
              ▼会社の写真をアップロード
            </Typography>
            <Typography variant='caption' color='error' style={{ marginBottom: '5px' }}>
              ※アップロードしない場合、デフォルトの画像が設定されます。
            </Typography>

            <Box className={clsx(classes.formWrap, classes.avatarWrap)}>

              <Grid container alignItems='flex-end'>
                <Grid item xs={12} sm={3} className={classes.profile}>
                  <Avatar src={mainCompany.imageBase64} className={classes.avatar}>
                    {!mainCompany.imageBase64 && <BusinessIcon fontSize='large' />}
                  </Avatar>
                </Grid>
                <Grid item xs={12} sm={9} className={classes.profile}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => accountActions.setPreview(dispatch, e, 'mainCompany', true)}
                  />
                </Grid>
              </Grid>

              <LinkStyle
                color="primary"
                href='https://photocombine.net/edit/'
                target="_blank"
                rel="noopener"
                underline='hover'
              >
                <Typography variant="caption">画像の切り抜き・リサイズができるサイトはこちら</Typography>
              </LinkStyle>
            </Box>

            <TextField
              label={<React.Fragment>会社名<Required /></React.Fragment>}
              value={mainCompany.name}
              onChange={(e) => {
                dispatch({ type: 'CHANGE_SIGNUP_MAIN_COMPANY_VALUE', data: e.target.value, field: 'name' })
                dispatch({ type: 'VALIDATE_COMPANY_NAME', data: e.target.value })
              }}
              variant="outlined"
              // required
              className={clsx(classes.formWrap, 'signform_input')}
              onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'companyName' })}
              onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'companyName' })}
              error={validation.companyName.isShowError}
              helperText={validation.companyName.isShowError ? validation.companyName.errorMsg : ''}
            />

            <TextField
              label="会社URL"
              value={mainCompany.url}
              onChange={(e) => dispatch({ type: 'CHANGE_SIGNUP_MAIN_COMPANY_VALUE', data: e.target.value, field: 'url' })}
              variant="outlined"
              // required
              className={clsx(classes.formWrap, 'signform_input')}
            />

            <FormControl className={classes.formWrap}>
              <Typography variant='body2' className={classes.tagLabel}>
                業界・業種<Required />
              </Typography>
              <Select
                className={classes.tag}
                onChange={(e) => {
                  dispatch({ type: 'CHANGE_SIGNUP_MAIN_COMPANY_VALUE', data: e, field: 'industryTags' })
                  dispatch({ type: 'VALIDATE_INDUSTRIES', data: e })
                }}
                label="業界・業種"
                isMulti
                options={industryTagList}
                name="industries"
                value={mainCompany.industryTags}
                onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'industries' })}
                onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'industries' })}
              />
              {validation.industries.isShowError && <FormErrorMsg msg={validation.industries.errorMsg} />}
            </FormControl>

            <FormControl className={classes.formWrap}>
              <Typography variant='body2' className={classes.tagLabel}>
                所在地<Required />
              </Typography>
              <Select
                className={classes.tag}
                onChange={(e) => {
                  dispatch({ type: 'CHANGE_SIGNUP_MAIN_COMPANY_VALUE', data: e, field: 'prefTags' })
                  dispatch({ type: 'VALIDATE_PREFECTURES', data: e })
                }}
                label="所在地"
                isMulti
                options={prefTagList}
                name="prefs"
                value={mainCompany.prefTags}
                onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'prefectures' })}
                onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'prefectures' })}
                error={validation.prefectures.isShowError}
                helperText={validation.prefectures.errorMsg}
              />
              {validation.prefectures.isShowError && <FormErrorMsg msg={validation.prefectures.errorMsg} />}
            </FormControl>

            <FormControl className={classes.formWrap}>
              <Typography variant='body2' className={classes.tagLabel}>
                社員数<Required />
              </Typography>
              <Select
                className={classes.tag}
                onChange={(e) => {
                  dispatch({ type: 'CHANGE_SIGNUP_MAIN_COMPANY_VALUE', data: e, field: 'memberNumTags' })
                  dispatch({ type: 'VALIDATE_MEMBER_NUM', data: e })
                }}
                label="社員数"
                options={memberNumTagList}
                name="memberNum"
                value={mainCompany.memberNumTags}
                onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'memberNum' })}
                onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'memberNum' })}
                error={validation.memberNum.isShowError}
                helperText={validation.memberNum.errorMsg}
              />
            </FormControl>
            {validation.memberNum.isShowError && <FormErrorMsg msg={validation.memberNum.errorMsg} />}
          </Box>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handlePost()
              dispatch({ type: 'IS_LOADING', data: true })
              dispatch({
                type: 'IS_OPEN',
                data: { bool: true, stage: MODAL.progress, path: '' }
              })
            }}
            disabled={disabled}
          >
            登録する
          </Button>
        </FormControl>

      </Box>
    </CardTemplate >
  )
})
export default SignUp
