import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import MainTemplate from "../templates/MainTemplate"
import { List, Typography } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import CaseList from './CaseList'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as shareCaseActions } from "../../hooks/useShereCaseReducer"
import { actions as infoActions } from "../../hooks/useInfoReducer"
import CustomModal from "../common/CustomModal"
import PageTitle from '../common/PageTitle'
import * as Scroll from 'react-scroll'
import FixedInfoList from '../info/FixedInfoList'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: '-50px'
  },
  infoWrap: {
    padding: '20px 20px 24px',
    backgroundColor: 'rgb(255 245 228)',
    borderRadius: '4px',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px 14px',
    },
  },
}))

const ShareCase = React.memo((props) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { state: { shareCase, loginUser, common, admin, info }, dispatch } = useContext(GlobalContext)
  const {
    filterdCases, cases, inputComments, caseComments,
    industryTagList, memberNumTagList, prefTagList, feedbackCaseTag, otherTagList
  } = shareCase
  const { searchTag } = common
  const { importantInfoKey } = admin
  const { importantInfo } = info

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const perPage = 5
  const startNum = (page - 1) * perPage

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    if (loginUser.isLogin) {
        console.log("caseRef start")
        shareCaseActions.moutedGetData(dispatch, loginUser.user.uid)
    }
    return () => { 
      dispatch({ type: 'CASE_RESET' })
      dispatch({ type: 'COMMON_RESET' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, loginUser.isLogin])

  useEffect(() => {
    infoActions.getImportantInfo(dispatch, importantInfoKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, importantInfoKey])

  useEffect(() => {
    if (loginUser.isLogin) {
      setTotalPages(Math.ceil(filterdCases.length / perPage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser.isLogin, filterdCases])

  // NOTE: 検索窓更新でページを初期化
  useEffect(() => { setPage(1) }, [searchTag])

  useEffect(() => {
    console.log("check search")
    shareCaseActions.filterCases(dispatch, searchTag, cases, {
      industryTagList, memberNumTagList, prefTagList, feedbackCaseTag, otherTagList
    })
    const scroll = Scroll.animateScroll
    scroll.scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchTag, cases])

  useEffect(() => {
    const scroll = Scroll.animateScroll
    scroll.scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <MainTemplate>

      {/* モーダル */}
      <CustomModal />

      {/* 重要なお知らせ */}
      {
        !!importantInfo && <List className={classes.infoWrap}>
          <Typography
            variant='body1'
            component='li'
            align='left'
            style={{
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
          >
            重要なお知らせ
          </Typography>

          <FixedInfoList
            InfoKey={importantInfoKey}
            content={importantInfo}
            handleDispatch={(e) => dispatch(e)}
            customStyle='importantInfo'
          />
        </List>
      }

      <PageTitle title='みんなの事例' />

      {
        searchTag && <Typography variant='body1' component='p' align='center' style={{ marginBottom: '30px', marginRight: '10px' }}>
          「 {searchTag.label} 」の検索結果一覧
        </Typography>
      }

      { filterdCases.length > 0 && <Pagination count={totalPages} size="large" page={page} onChange={(e, newPage) => setPage(newPage)} style={{marginBottom: '30px', position: 'relative', zIndex: 1}}/>}

      <List className={classes.list}>
        {filterdCases && <React.Fragment>
          {
            filterdCases.length <= 0 ? (
              !searchTag ? '' : '検索結果は0件です。'
            ) : (
              // 描画の速さにマウントが追いついていない
              filterdCases.slice(startNum, startNum + perPage).map((content, key) => {
                const comments = caseComments.filter((cmnt) => cmnt.caseKey === content.key)
                return <CaseList
                  hash={location.hash}
                  key={key}
                  content={content}
                  inputComment={inputComments[content.key]}
                  comments={comments}
                  handleDispatch={(e) => dispatch(e)}
                />
              })
            )
          }
        </React.Fragment>}
      </List>

      { filterdCases.length > 0 && <Pagination count={totalPages} size="large" page={page} onChange={(e, newPage) => setPage(newPage)} /> }

    </MainTemplate >
  )
})
export default ShareCase
