import React, { useEffect, useContext } from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import withWidth from '@material-ui/core/withWidth'
import MainTemplate from '../templates/MainTemplate'
import { GlobalContext } from '../../hooks/reducer'
import CustomModal from '../common/CustomModal'
import PageTitle from '../common/PageTitle'
import {
  Card,
  CardHeader,
  Container,
  Link,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import Manual from "./common/Manual"
import SITEINFO from '../../constants/siteInfo'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const data = [
  {label: '会社名', data: SITEINFO.campanyName},
  {label: '代表取締役', data: SITEINFO.ceo},
  {label: '所在地', data: SITEINFO.address},
  {label: 'WEBサイト', data: <Link color="primary" href={SITEINFO.url} target="_blank" rel="noopener" underline='hover'><Typography variant="caption">{SITEINFO.url}</Typography></Link>}
]

const useStyles = makeStyles((theme) => ({
  card: {
    paddingBottom: '16px',
    marginBottom: '40px',
  },
  link: {
    marginTop: '15px',
  }
}))

const AboutSite = React.memo((props) => {
  const history = useHistory()
  const classes = useStyles()
  const { state: { common }, dispatch } = useContext(GlobalContext)
  const { isOpen } = common

  useEffect(() => {
    return () => dispatch({ type: 'COMMON_RESET' })
  }, [dispatch])

  return (
    <MainTemplate>
      {isOpen && <CustomModal />}

      <PageTitle title='サイト概要' />

      <Container component={Card} maxWidth="sm" className={clsx('signform', classes.card)}>
        <CardHeader title='当サイトの目的' />

        <Typography className='indention' >
          CCSユーザーが事例共有をはじめ、CCSのより良い活用について、互いに学ぶための情報交流サイトです。
        </Typography>

        <Link onClick={() => history.push('/term_service')} underline='always' color='primary'>
          <Typography component='p' variant='inherit' className={classes.link}>
            当サイトの利用規約
          </Typography>
        </Link>

        <Link onClick={() => history.push('/policy')} underline='always' color='primary'>
          <Typography component='p' variant='inherit' className={classes.link}>
            プライバシーポリシー
          </Typography>
        </Link>

      </Container>

      <Manual />

      <Container component={Card} maxWidth="sm" className={clsx('signform', classes.card)}>
        <CardHeader title='運営元企業' />

        <TableContainer
          component={Paper}
          className='indention'
          style={{boxShadow: 'none'}}
        >
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableBody>
              {data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{minWidth: '130px'}}
                  >{row.label}</TableCell>
                  <TableCell align="left">{row.data}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </Container>

    </MainTemplate >
  )
})

export default withWidth()(AboutSite)
