import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import withWidth from '@material-ui/core/withWidth'
import MainTemplate from '../templates/MainTemplate'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from '../../hooks/useLoginUserReducer'

const Contact = React.memo((props) => {
  const history = useHistory()
  const { state: { loginUser }, dispatch } = useContext(GlobalContext)

  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    console.log('初回')
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    return () => dispatch({ type: 'COMMON_RESET' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
  
  if (!loginUser.isLogin) { return <MainTemplate></MainTemplate> }

  return (
    <MainTemplate>

      <div style={{ height: '800px' }}>
        <iframe
          src="https://pro.form-mailer.jp/fms/55d23e22225550" 
          title="HTML Form" 
          style={{
            width: '100%',
            height: '100%',
            border: '0', // frameBorder="0"の代わり
            overflow: 'auto' // scrolling="auto"の代わり
          }}
        >
          <a 
            href="https://pro.form-mailer.jp/fms/55d23e22225550" 
            title="Contact"
          >
            この部分はインラインフレームを使用しています
          </a>
        </iframe>
      </div>

    </MainTemplate >
  )
})

export default withWidth()(Contact)
