/** ページごとに事例を分割 
 * @param {{
 *  page: number
 *  rowsPerPage: number 
 *  order: {
 *   orderBy: 'name' (company) | 'userName' | 'createdAt' 
 *   order: 'asc' | 'desc'
 *  }
 * cases []
 * }} params
 */ 
export const divideCasesPerPage= ({ page, rowsPerPage, order, cases }) => {
    let entries = cases;

    // companyIdによるフィルタリング後、クライアントサイドで並び替え
    if (order.orderBy) {
        entries = cases.sort((_a, _b) => {
        const a = _a[1];
        const b = _b[1];
        
        let valueA = (order.orderBy === 'name' ? a.user?.company : a[order.orderBy]);
        let valueB = (order.orderBy === 'name' ? b.user?.company : b[order.orderBy]);

        if (order.orderBy === 'userName') {
            valueA = a.user?.name;
            valueB = b.user?.name;
        }

        if (valueA < valueB) {
            return order.order === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
            return order.order === 'asc' ? 1 : -1;
        }
            return 0;
        });
    } else {
        if (order.order === 'desc') { 
            entries.reverse(); 
        }
    }

    return entries
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(([key, content]) => ({ key, ...content }));
}