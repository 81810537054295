import React, { useEffect, useRef, useContext } from 'react'
import clsx from 'clsx'
import SITEINFO from "../../constants/siteInfo"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Card,
  Divider,
  CardContent,
  CardMedia,
  Typography,
  CardHeader,
  Avatar,
  Button,
  OutlinedInput,
  FormHelperText,
  FormControl,
  List,
  IconButton,
  Grid,
} from '@material-ui/core'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import {
  Edit as EditIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons'
// import thumbsUpContainIcon from '../../assets/fontawesome/svgs/solid/thumbs-up.svg'
// import thumbsUpIcon from '../../assets/fontawesome/svgs/regular/thumbs-up.svg'
import commentIcon from '../../assets/fontawesome/svgs/regular/comment-alt.svg'
import { GlobalContext } from '../../hooks/reducer'
import { actions as infoActions } from "../../hooks/useInfoReducer"
import { actions as commonActions } from '../../hooks/useCommonReducer'
import { Comment } from '../common/Comment'
import { commonTheme } from '../../materialui/theme'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '40px 0',
    border: `2px solid ${commonTheme.palette.primary.main}`
  },
  content: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
  },
  contentBottom: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '14px 0',
  },
  commentForm: {
    display: 'flex',
    marginTop: '15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  textField: {
    "& > input": {
      paddingTop: '0.55em',
      paddingBottom: '0.55em',
    }
  },
  caution: {
    marginLeft: 0,
    marginRight: 0,
    color: theme.palette.error.main,
  },
  title: {
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  photoWrap: {
  },
  photo: {
    marginTop: '10px',
  }
}))

const InfoList = React.memo((props) => {
  const { state: { info, loginUser } } = useContext(GlobalContext)
  const {
    isShowCmntField, editComments, isShowCmnts, isShowCmntAlls
  } = info
  const {
    content, comments, disabled, hash, handleDispatch, inputComment,
  } = props
  const { key, text, title, user, createAt, photos } = content

  const visibleComments = comments.length > 0 ?
    (!!isShowCmntAlls[key] ? comments : comments.slice(-2)) : []

  const history = useHistory()
  const classes = useStyles()
  const ref = useRef()

  useEffect(() => {
    if (hash === ('#info-' + key)) { ref.current.scrollIntoView({ behavior: "smooth" }) }
  }, [hash, key])

  // const Good = () => (
  // <IconButton
  //   style={{ paddingLeft: 0 }}
  //   onClick={() => infoActions.changeGood(handleDispatch, {
  //     goodKeys: goodKeys,
  //     goods: goods,
  //     infoKey: content.key,
  //     user: loginUser.user
  //   })}
  // >
  //   {
  //     goodKeys.includes(content.key) ? (
  //       <React.Fragment>
  //         <CardMedia
  //           className='global_icon global_icon_txt'
  //           image={thumbsUpContainIcon}
  //         />
  //         <Typography variant="caption" color="secondary">いいね！</Typography>
  //       </React.Fragment>
  //     ) : (
  //       <React.Fragment>
  //         <CardMedia
  //           className='global_icon global_icon_txt'
  //           image={thumbsUpIcon}
  //         />
  //         <Typography variant="caption">いいね！</Typography>
  //       </React.Fragment>
  //     )
  //   }
  // </IconButton>
  // )

  return (
    <Card
      ref={ref}
      id={'info-' + key}
      className={classes.root}
    >

      <div>

        <CardHeader
          avatar={<Avatar src={user.imageUrl} />}
          title={user ? user.name : "名無しさん"}
          subheader={(!!user && !!user.companyInfo) ? user.companyInfo.name : ""}
        />

        <Divider />

        <CardContent>

          {/* 内容 */}
          <Typography variant="body1" className={clsx(classes.content, classes.title)}>
            {title}
          </Typography>
          <Typography
            variant="body1"
            className={classes.content}
            dangerouslySetInnerHTML={commonActions.changeToURL(text)}
          />

          {/* 写真 */}
          {
            photos && <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              {photos.map((photo, i) => (
                <Grid
                  key={i}
                  item
                  style={{ maxWidth: isWidthDown('sm', props.width) ? '100%' : '46%' }}
                >
                  <CardMedia
                    image={photo.url}
                    component="img"
                    className={classes.photo}
                  />
                </Grid>
              ))}
            </Grid>
          }

          {/* 時間・編集 */}
          <div className={classes.contentBottom}>
            <p style={{ margin: '0' }}>
              <Typography variant="caption">
                {dayjs(createAt).format('YYYY年MM月DD日 HH:mm')}
              </Typography>
            </p>

            {
              loginUser.user.pajaposs && <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: 0 }}
                onClick={() => history.push(`/info/edit/${key}`)}
              >
                <EditIcon fontSize='small' style={{ marginRight: '5px' }} />
                 投稿を編集する
              </Button>
            }
          </div>

          <Divider />


          {/* いいね */}
          {/* <Good /> */}
          {/* <Divider /> */}

          {/* コメント */}
          <div className={classes.commentWrap}>
            {
              comments.length > 0 && <React.Fragment>

                {/* コメントをすべて表示・非表示 */}
                {
                  comments.length > 2 ? (
                    <IconButton
                      style={{ paddingLeft: 0 }}
                      onClick={() => {
                        handleDispatch({
                          type: 'IS_SHOW_INFO_CMNT_ALL',
                          field: key,
                          data: !isShowCmntAlls[key],
                        })
                      }}
                    >
                      {/* コメントが２件以上の表示 */}
                      <CardMedia
                        className='global_icon global_icon_txt'
                        image={commentIcon}
                      />
                      <Typography
                        variant="caption"
                        style={{
                          fontWeight: 'bold',
                          textDecorationLine: 'underline',
                        }}
                      >
                        {!!isShowCmntAlls[key] ? 'コメントを隠す' : `${comments.length}件のコメントをすべて表示する`}
                      </Typography>
                      {!!isShowCmntAlls[key] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  ) : (
                    <IconButton
                      style={{ paddingLeft: 0, color: 'rgba(0, 0, 0, 0.54)' }}
                      disabled={true}
                      component='div'
                    >
                      {/* コメントが２件以下の表示 */}
                      <CardMedia
                        className='global_icon global_icon_txt'
                        image={commentIcon}
                      />
                      <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                        コメント
                      </Typography>
                    </IconButton>
                  )
                }

                {/* コメント一覧 */}
                <List style={{ paddingTop: 0 }}>
                  {
                    visibleComments.map((comment, key) => (
                      <Comment
                        key={key}
                        comment={comment}
                        isShowCmntField={isShowCmntField}
                        editComments={editComments}
                        handleDispatch={handleDispatch}
                        loginUser={loginUser}
                        isShowCmnts={isShowCmnts}
                        replyType=''
                        commentActions={infoActions}
                        contentType='info'
                        commentKey={comment.key}
                      />
                    ))
                  }
                </List>
              </React.Fragment>
            }

            {/* コメントフォーム */}
            <div className={classes.commentForm}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <OutlinedInput
                  value={inputComment || ''}
                  onChange={(e) => handleDispatch({ type: 'CHANGE_INFO_COMMENT_VALUE', data: e.target.value, field: key })}
                  aria-describedby=""
                  inputProps={{ 'aria-label': '', }}
                  labelWidth={0}
                  multiline
                  placeholder="ここにコメントを入力する"
                  className={clsx(classes.textField)}
                />
              </FormControl>
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: 0 }}
                onClick={() => infoActions.postComment(handleDispatch, {
                  text: inputComment,
                  user: loginUser.user,
                  infoKey: key,
                })}
                disabled={disabled}
              >
                投稿
              </Button>
            </div>
            <FormHelperText className={clsx(classes.caution)}>
              ※コメントで{SITEINFO.adminName}に「質問・感想」などを投稿することができます。
            </FormHelperText>
          </div>
        </CardContent>
      </div>
    </Card >
  )
})
export default withWidth()(InfoList)
