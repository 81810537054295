const SITEINFO = {
  title: "CCSコミュニティサイト",
  campanyName: "株式会社パジャ・ポス",
  adminName: "パジャ・ポス事務局",
  name: "パジャ・ポス",
  ceo: '池本　克之',
  address: '〒150-0013 東京都渋谷区恵比寿1丁目24‒15　シエルブルー恵比寿EAST6階',
  appUrl: process.env.NODE_ENV === 'development' ? "http://localhost:3000/" : "https://ccs-community.com/",
  path: {
    info: 'info',
    consult: 'consult',
  },
  url: "https://www.ikemotokatsuyuki.net/",
  pajapossCompanyId: "sample2",
  adminMail: 'noreply@css-site-d0ddf.firebaseapp.com',
}

export default SITEINFO
