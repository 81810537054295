import React from 'react'
import { Typography } from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

const PageTitle = (props) => {
  const { title, color } = props
  return <Typography
    component="h2"
    variant={isWidthUp('sm', props.width) ? 'h4' : 'h5'}
    style={{ textAlign: 'center', color: color || 'inherit' }}
  >
    {title}
  </Typography>
}

export default withWidth()(PageTitle)
