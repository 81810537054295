import { Fab, Tooltip } from '@material-ui/core'
import { ExpandLess as ExpandLessIcon } from '@material-ui/icons'
import React from 'react'
import * as Scroll from 'react-scroll'
import { commonTheme } from '../../materialui/theme'

const scroll = Scroll.animateScroll

export const BackTop = () => {
  return (
    <Tooltip
      title='上にスクロール'
      onClick={() => scroll.scrollToTop()}
    >
      <Fab
        style={{
          bottom: '15px',
          left: '15px',
          zIndex: '9999',
          color: commonTheme.palette.primary.main,
          position: 'fixed',
          backgroundColor: commonTheme.palette.primary.light
        }}>
        <ExpandLessIcon />
      </Fab>
    </Tooltip>
  )
}
