import React, { useState, useEffect } from 'react';
import { TableCell, Tooltip, IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'break-spaces',
    marginTop: '10px',
    marginBottom: '10px',
    fontSize: '12px',
  },
  copy: {
    opacity: '0.9',
    transition: 'opacity 0.5s', // ふわっと表示するためのトランジション
    height: '25px',
    width: '117px',
    position: 'absolute',
    textalign: 'center',
    fontSize: '11px',
    backgroundColor: '#4CAF50',
    padding: '5px',
    margin: '5px 0',
    borderRadius: '5px',
  },
  noncopy: {
    opacity: 0,
  },
}));

const EllipsisTableCell = ({ text, labelId, className, width}) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    let timeout;
    if (copySuccess) {
      timeout = setTimeout(() => setCopySuccess(''), 1000);
    }
    return () => clearTimeout(timeout);
  }, [copySuccess]);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleCopy = (event) => {
    event.preventDefault(); // デフォルトのイベントをキャンセルする
    event.stopPropagation(); // さらにイベントの伝播を止める
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess('コピーしました');
    }).catch(() => {
      // エラーハンドリングをここに追加することができます。
    });
  };
  
  
  return (
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        className={clsx(className)}
      >
          <div 
            onClick={handleTooltipOpen} 
            style={{
              cursor: 'pointer',
              padding: '8px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: width,
            }}
          >
            {text}
          </div>

        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          interactive
          placement="top-start"
          title={
            <React.Fragment>
              <div style={{ textAlign: 'right'}}>
                <IconButton
                  onClick={handleCopy}
                  aria-label="copy"
                  size="small"
                  style={{ margin: '4px', color: 'white' }}
                >
                  <FileCopyIcon />
                </IconButton>

                <IconButton
                  onClick={handleTooltipClose}
                  aria-label="close"
                  size="small"
                  style={{ margin: '4px', color: 'white' }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              {!copySuccess && <div className={clsx(classes.copy, classes.noncopy)}></div>}
              {copySuccess && <div className={classes.copy}>{copySuccess}</div>}

              <div className={classes.text}>{text}</div>
            </React.Fragment>
          }
          arrow
        >
          <div />
        </Tooltip>
      </TableCell>
  );
}

export default EllipsisTableCell;
