import React, { useEffect, useContext } from 'react'
import MODAL from "../../constants/modal"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import MainTemplate from '../templates/MainTemplate'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as accountActions } from "../../hooks/useAccountReducer"
import CustomModal from '../common/CustomModal'
import PageTitle from '../common/PageTitle'
import NotifyPDF from "../../assets/CCSコミュニティサイト_通知設定方法.pdf"

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    padding: '60px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px',
    },
  },
  formWrap: {
    margin: '20px 0',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '10px',
  },
  form: {
    width: '100%',
  },
  avatarWrap: {
    width: '100%',
    marginTop: 0,
    border: 'solid 1px #bdbdbd',
    borderRadius: '5px',
    padding: '10px',
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  tagFrom: {
    marginBottom: '20px',
  },
  noticed: {
    marginRight: '15px'
  }
}))

const Notice = React.memo((props) => {
  const history = useHistory()
  const classes = useStyles()

  const { state: { loginUser, account, common, shareCase }, dispatch } = useContext(GlobalContext)
  const { industryTagList } = shareCase
  const { isOpen } = common
  const { user } = loginUser
  const { notice, noticeType, noticeTypes } = account
  const { editNotice } = notice
  const { editNoticeType } = noticeType
  const isAllFalse = !!user.noticeTypes ?
    Object.values(user.noticeTypes).every(bool => !bool) : false

  // 初回mount
  useEffect(() => {
    // ログインしていない場合、ログインページに遷移
    if (!loginUser.isLogin) { actions.loginCheck(dispatch, history) }
    return () => dispatch({ type: 'COMMON_RESET' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, loginUser.isLogin])

  useEffect(() => {
    // オープンの時に、editNoticeにログイン情報をセットする
    if (!notice.isOpen && !noticeType.isOpen) {
      dispatch({ type: 'ACCOUNT_RESET' })
      dispatch({ type: 'COMMON_RESET' })
    }
    if (notice.isOpen) {
      if (noticeType.isOpen) { dispatch({ type: 'ACCOUNT_NOTICE_TYPE_RESET' }) }
      dispatch({ type: 'SET_EDIT_NOTICE', data: user })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, notice.isOpen])

  useEffect(() => {
    if (!notice.isOpen && !noticeType.isOpen) {
      dispatch({ type: 'ACCOUNT_RESET' })
      dispatch({ type: 'COMMON_RESET' })
    }
    if (noticeType.isOpen) {
      if (notice.isOpen) { dispatch({ type: 'ACCOUNT_NOTICE_RESET' }) }
      if (user.noticeTypes) { dispatch({ type: 'SET_EDIT_NOTICE_TYPE', data: user }) }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, noticeType.isOpen])

  useEffect(() => {
    if (noticeType.isOpen) {
      // all noticeTypes true -> all true
      if (!editNoticeType.all) {
        const removeAll = { ...editNoticeType }
        const caseKeys = Object
          .keys(editNoticeType)
          .filter(type => {
            if (type === 'caseAll') return false
            return type.slice(0, 4) === 'case'
          })
        caseKeys.forEach(caseKey => {
          delete removeAll[caseKey]
        })
        delete removeAll.all
        const isNotAllTrue = Object.values(removeAll).some(bool => !bool ? true : false);
        if (!isNotAllTrue) {
          let newEditTypes = {}
          Object.entries(removeAll).forEach(([key, value]) => {
            newEditTypes[key] = false
          })
          newEditTypes.all = true
          dispatch({ type: 'SET_EDIT_NOTICE_TYPE', data: { noticeTypes: newEditTypes } })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editNoticeType])

  useEffect(() => {
    const caseData = industryTagList.map(tag => ({
      name: 'case' + tag.value.charAt(0).toUpperCase() + tag.value.slice(1),
      label: tag.label
    }))
    caseData.unshift({ name: 'caseAll', label: '全ての事例' })
    dispatch({ type: 'SET_NOTICE_TYPES_CASE', data: caseData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryTagList])

  const handleUpdateNotice = async () => {
    try {
      dispatch({ type: 'PROGRESS', data: 10 })
      await accountActions.updateNotice(user, editNotice, user.noticeTypes)
      dispatch({ type: 'PROGRESS', data: 50 })
      dispatch({
        type: 'SET_LOGIN_USER', data: {
          ...user,
          ...notice.editNotice,
        }
      })
      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'ACCOUNT_RESET' })
    } catch (error) {
      console.log(error.message)
      dispatch({ type: 'PROGRESS_RESET' })
      dispatch({ type: 'ERROR', data: error.message })
      dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.error })
    }
  }

  const handleUpdateNoticeType = async () => {
    try {
      dispatch({ type: 'PROGRESS', data: 10 })
      await accountActions.updateNoticeType(dispatch, user, editNoticeType)
      dispatch({ type: 'PROGRESS', data: 50 })
      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'ACCOUNT_RESET' })
    } catch (error) {
      console.log(error.message)
      dispatch({ type: 'PROGRESS_RESET' })
      dispatch({ type: 'ERROR', data: error.message })
      dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.error })
    }
  }

  if (!loginUser.isLogin) { return <MainTemplate></MainTemplate> }

  return (
    <MainTemplate>
      {isOpen && <CustomModal />}

      <PageTitle title='通知設定' />

      <Grid container justifyContent="center">
        <Link
          href={NotifyPDF}
          underline='none'
          target='_blank'
          rel='noopener noreferrer'
            >
          <Button component={Grid} item variant="contained" color="secondary">
            通知設定方法はこちら
          </Button>
        </Link>
      </Grid>

      {/***************** 通知設定 *****************/}
      <Container
        component={Card}
        maxWidth="sm"
        className={clsx(classes.card, 'signform', 'indention')}
        style={{ marginTop: '40px' }}
      >

        {/* 通知設定・変更ボタン */}
        <Grid container justifyContent='space-between' className={classes.formWrap}>
          <Grid item>
            <Typography variant='h6' style={{ margin: 0 }}>通知設定</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: 0 }}
              onClick={() => dispatch({ type: 'CHANGE_IS_OPEN_NOTICE', data: !notice.isOpen })}
            >
              {notice.isOpen ? '変更をやめる' : '変更する'}
            </Button>
          </Grid>
        </Grid>

        {/* APIトークン */}
        <Grid container className={classes.formWrap}>
          <Grid
            component={Typography}
            item
            xs={12}
            variant='body2'
            className={classes.label}
          >
            ChatWorkのAPIトークン
          </Grid>
          <Grid item xs={12}>
            {
              notice.isOpen && <TextField
                value={editNotice.noticeToken}
                onChange={(e) => dispatch({ type: 'CHANGE_EDIT_NOTICE_VALUE', data: e.target.value, field: 'noticeToken' })}
                variant="outlined"
                className={classes.form}
              />
            }
            {!notice.isOpen && <Typography>{user.noticeToken}</Typography>}
          </Grid>
        </Grid>

        {/* チャットルームID */}
        <Grid container className={classes.formWrap}>
          <Grid
            component={Typography}
            item
            xs={12}
            variant='body2'
            className={classes.label}
          >
            ChatWorkのルームID
          </Grid>
          <Grid item xs={12}>
            {
              notice.isOpen && <TextField
                value={editNotice.noticeRoomId}
                onChange={(e) => dispatch({ type: 'CHANGE_EDIT_NOTICE_VALUE', data: e.target.value, field: 'noticeRoomId' })}
                variant="outlined"
                className={classes.form}
              />
            }
            {!notice.isOpen && <Typography>{user.noticeRoomId}</Typography>}
          </Grid>
        </Grid>

        {
          notice.isOpen && <React.Fragment>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: '10px' }}
              onClick={() => dispatch({ type: 'CHANGE_IS_OPEN_NOTICE', data: false })}
            >
              変更をやめる
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleUpdateNotice()
                dispatch({ type: 'IS_LOADING', data: true })
                dispatch({
                  type: 'IS_OPEN',
                  data: { bool: true, stage: MODAL.progress, path: '' }
                })
              }}
            >
              通知設定を変更する
            </Button>
          </React.Fragment>
        }

      </Container>

      {/***************** 通知詳細設定 *****************/}
      <Container
        component={Card}
        maxWidth="sm"
        className={clsx(classes.card, 'signform', 'indention')}
        style={{ marginTop: '40px' }}
      >

        {/* 通知詳細設定・変更ボタン */}
        <Grid container justifyContent='space-between' className={classes.formWrap}>
          <Grid item><Typography variant='h6' style={{ margin: 0 }}>通知詳細設定</Typography></Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: 0 }}
              onClick={() => dispatch({ type: 'CHANGE_IS_OPEN_NOTICE_TYPE', data: !noticeType.isOpen })}
              disabled={!user.noticeRoomId || !user.noticeToken}
            >
              {noticeType.isOpen ? '変更をやめる' : '変更する'}
            </Button>
          </Grid>
        </Grid>

        {
          (!user.noticeRoomId || !user.noticeToken) &&
          <Typography variant='caption' color='error'>詳細設定をする前に、上記の通知設定を行ってください。</Typography>
        }

        {
          (user.noticeRoomId && user.noticeToken) && <React.Fragment>
            {
              !noticeType.isOpen && isAllFalse &&
              <Grid item component={Chip} label='全ての通知をしない' />
            }

            {/* 全て */}
            <Grid container className={classes.formWrap}>
              {noticeType.isOpen && <FormControlLabel
                control={
                  <Switch
                    checked={!!editNoticeType.all}
                    onChange={(e) => dispatch({ type: 'CHANGE_EDIT_NOTICE_TYPES_VALUE', data: e.target.checked, field: e.target.name })}
                    name="all"
                    color="primary"
                  />
                }
                label="全て通知する"
              />}
              {
                !noticeType.isOpen &&
                !!user.noticeTypes && !!user.noticeTypes.all &&
                <Chip label='全て通知する' />
              }
            </Grid>

            {/* 事例 */}
            <Grid container className={classes.formWrap}>
              <Grid item xs={12} component={Typography} variant='button'>事例</Grid>
              {noticeTypes.case.map((type, i) => <React.Fragment>
                {noticeType.isOpen && <FormControlLabel
                  key={'case-' + i}
                  control={
                    <Switch
                      checked={!!editNoticeType[type.name]}
                      onChange={(e) => dispatch({ type: 'CHANGE_EDIT_NOTICE_TYPES_VALUE', data: e.target.checked, field: e.target.name })}
                      name={type.name}
                      color="primary"
                      disabled={!!editNoticeType.all}
                    />
                  }
                  label={type.label}
                />}
                {!noticeType.isOpen && !!user.noticeTypes && !!user.noticeTypes[type.name] &&
                  <Grid key={'case-' + i} item component={Chip} label={type.label} className={classes.noticed} />
                }
              </React.Fragment>)}
            </Grid>

            {/* 相談 */}
            <Grid container className={classes.formWrap}>
              <Grid item xs={12} component={Typography} variant='button'>相談</Grid>
              {noticeTypes.consult.map((type, i) => <React.Fragment>
                {noticeType.isOpen && <FormControlLabel
                  key={'consult-' + i}
                  control={
                    <Switch
                      checked={!!editNoticeType[type.name]}
                      onChange={(e) => dispatch({ type: 'CHANGE_EDIT_NOTICE_TYPES_VALUE', data: e.target.checked, field: e.target.name })}
                      name={type.name}
                      color="primary"
                      disabled={!!editNoticeType.all}
                    />
                  }
                  label={type.label}
                />}
                {!noticeType.isOpen && !!user.noticeTypes && !!user.noticeTypes[type.name] &&
                  <Grid key={'consult-' + i} item component={Chip} label={type.label} className={classes.noticed} />
                }
              </React.Fragment>)}
            </Grid>

            {/* お知らせ */}
            <Grid container className={classes.formWrap}>
              <Grid item xs={12} component={Typography} variant='button'>お知らせ</Grid>
              {noticeTypes.info.map((type, i) => <React.Fragment>
                {noticeType.isOpen && <FormControlLabel
                  key={'notice-' + i}
                  control={
                    <Switch
                      checked={!!editNoticeType[type.name]}
                      onChange={(e) => dispatch({ type: 'CHANGE_EDIT_NOTICE_TYPES_VALUE', data: e.target.checked, field: e.target.name })}
                      name={type.name}
                      color="primary"
                      disabled={!!editNoticeType.all}
                    />
                  }
                  label={type.label}
                />}
                {!noticeType.isOpen && !!user.noticeTypes && !!user.noticeTypes[type.name] &&
                  <Grid key={'notice-' + i} item component={Chip} label={type.label} className={classes.noticed} />
                }
              </React.Fragment>)}
            </Grid>

            {
              noticeType.isOpen && <React.Fragment>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: '10px' }}
                  onClick={() => dispatch({ type: 'CHANGE_IS_OPEN_NOTICE_TYPE', data: false })}
                >
                  変更をやめる
              </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleUpdateNoticeType()
                    dispatch({ type: 'IS_LOADING', data: true })
                    dispatch({
                      type: 'IS_OPEN',
                      data: { bool: true, stage: MODAL.progress, path: '' }
                    })
                  }}
                >
                  通知詳細設定を変更する
              </Button>
              </React.Fragment>
            }
          </React.Fragment>
        }

      </Container>

    </MainTemplate >
  )
})
export default withWidth()(Notice)
