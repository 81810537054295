import React from 'react'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Avatar,
  TextField,
  Grid,
  Typography,
  Paper,
} from '@material-ui/core'
import { actions as accountActions } from "../../hooks/useAccountReducer"
import { actions as commonActions } from '../../hooks/useCommonReducer'
import { actions as signupActions } from '../../hooks/useSignUpReducer'
import MODAL from '../../constants/modal'
import { AvatarLink } from '../common/AvatarLink'

const MemberAdd = React.memo((props) => {
  const {
    dispatch, classes, addMember, addMemberPassword, addMemberDisabled,
    loginUser, validation, EnhancedTableHead, doNotify
  } = props

  const handleSignup = async (user, password) => {
    // 処理
    try {
      dispatch({
        type: 'IS_OPEN',
        data: { bool: true, stage: MODAL.progress, path: '' }
      })
      dispatch({ type: 'PROGRESS', data: 10 })
      const createdUser = await signupActions.createMember(user.email, password)
      dispatch({ type: 'PROGRESS', data: 70 })
      await signupActions.addUserInfo({
        ...createdUser,
        ...user
      }, loginUser.user.companyInfo)
      // メール通知
      commonActions.sendMail({ email: user.email, name: user.name }, 'sendUserCreatedMail', doNotify)
      dispatch({ type: 'PROGRESS', data: 90 })
      accountActions.setMember(dispatch, loginUser.user.companyInfo.companyId)
      dispatch({ type: 'COMMON_RESET' })
      dispatch({ type: 'HIDDEN_ADD_MEMBER' })
    } catch (error) {
      console.log(error.message)
      dispatch({ type: 'PROGRESS_RESET' })
      dispatch({ type: 'ERROR', data: error.message })
      dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.error })
    }
  }

  return (
    <Paper className={classes.paper}>
      <TableContainer style={{ marginTop: '30px' }}>
        <Typography variant='h6'>▼メンバーを追加する</Typography>
        <Table className={classes.table} size='medium'>
          <EnhancedTableHead add={true} isOpenMemberPassword={true} />
          <TableBody >
            <TableRow hover tabIndex={-1}>

              {/* アバター */}
              <TableCell align='center'>
                <Avatar src={addMember.imageBase64} className={classes.avatar} />
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => accountActions.setPreview(dispatch, e, 'addMember')}
                  style={{ margin: '10px 0', display: 'block' }}
                />
                <AvatarLink />
              </TableCell>

              {/* 名前 */}
              <TableCell component="th" id='add_member' scope="row" padding='checkbox'>
                <TextField
                  value={addMember.name}
                  onChange={(e) => {
                    dispatch({
                      type: 'CHANGE_ADD_MEMBER',
                      data: e.target.value,
                      field: 'name',
                    })
                    dispatch({ type: 'VALIDATE_NAME', data: e.target.value })
                  }}
                  onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'name' })}
                  onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'name' })}
                  variant="outlined"
                  required
                  className={classes.form}
                  error={validation.name.isShowError}
                  helperText={validation.name.isShowError ? validation.name.errorMsg : ''}
                />
              </TableCell>

              {/* 管理者権限 */}
              <TableCell padding="checkbox">
                <Checkbox
                  checked={addMember.admin}
                  inputProps={{ 'aria-labelledby': 'add_member' }}
                  onClick={(e) => dispatch({
                    type: 'CHANGE_ADD_MEMBER',
                    data: e.target.checked,
                    field: 'admin',
                  })}
                />
              </TableCell>

              {/* メールアドレス */}
              <TableCell padding='checkbox'>
                <TextField
                  value={addMember.email}
                  onChange={(e) => {
                    dispatch({
                      type: 'CHANGE_ADD_MEMBER',
                      data: e.target.value,
                      field: 'email'
                    })
                    dispatch({ type: 'VALIDATE_EMAIL', data: e.target.value })
                  }}
                  onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'email' })}
                  onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'email' })}
                  variant="outlined"
                  required
                  className={classes.form}
                  error={validation.email.isShowError}
                  helperText={validation.email.isShowError ? validation.email.errorMsg : ''}
                />
              </TableCell>
              {/* パスワード */}
              <TableCell
                padding='checkbox'
                colSpan={2}
              >
                <Grid container>
                  <Grid item component={TextField}
                    label="パスワード"
                    type="password"
                    value={addMemberPassword.password}
                    onChange={(e) => {
                      dispatch({
                        type: 'CHANGE_ADD_MEMBER_PASSWORD',
                        data: e.target.value,
                        field: 'password',
                      })
                      dispatch({ type: 'VALIDATE_PASSWORD', data: e.target.value, confirm: addMemberPassword.passwordConfirm })
                    }}
                    onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'password' })}
                    onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'password' })}
                    variant="outlined"
                    required
                    error={validation.password.isShowError}
                    className={'signform_input'}
                  />
                  <Grid item component={TextField}
                    label="パスワード再入力"
                    type="password"
                    value={addMemberPassword.passwordConfirm}
                    onChange={(e) => {
                      dispatch({
                        type: 'CHANGE_ADD_MEMBER_PASSWORD',
                        data: e.target.value,
                        field: 'passwordConfirm'
                      })
                      dispatch({ type: 'VALIDATE_PASSWORD', data: e.target.value, confirm: addMemberPassword.password })
                    }}
                    onBlur={(e) => dispatch({ type: 'VALIDATE_SHOW_ERROR', field: 'password' })}
                    onFocus={() => dispatch({ type: 'VALIDATE_RESET', field: 'password' })}
                    variant="outlined"
                    required
                    error={validation.password.isShowError}
                    helperText={validation.password.isShowError ? validation.password.errorMsg : ''}
                    className={'signform_input'}
                  />
                </Grid>
              </TableCell>

              {/* 登録ボタン */}
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={addMemberDisabled}
                  onClick={() => handleSignup(addMember, addMemberPassword.password)}
                >登録</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
})

export default MemberAdd
