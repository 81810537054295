import React from 'react'
import { Typography } from '@material-ui/core'

const MsgVideoLoading = props => {
  const { align, isLoading } = props

  // TODO: postCase一元化
  return <React.Fragment>
    {
      isLoading && <Typography
        variant='caption'
        color='error'
        component='p'
        align={align}
        style={{ fontWeight: 'bold' }}
      >
        ※動画アップロード中です。アップロードが完了するまで、ページを移動しないでください。
      </Typography>
    }
  </React.Fragment>
}

export default MsgVideoLoading
