import React from 'react'
import {
  Link,
} from 'react-router-dom'
import {
  Grid,
  Typography,
  Link as LinkStyle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { commonTheme } from "../../materialui/theme"

const useStyles = makeStyles((theme) => ({
  cautionWrap: {
    marginBottom: '20px',
  },
  caution: {
    color: commonTheme.palette.error.light,
    fontWeight: 'bold',
  },
  manualLink: {
    color: commonTheme.palette.primary.light,
  },
}))

export const ManualLink = (props) => {
  const classes = useStyles()

  return <Grid item xs className={classes.cautionWrap}>
    <Typography component="p" align="center" className={classes.caution}>
      ▽ 初めてご利用の方は必ずお読みください ▽
    </Typography>
    <LinkStyle component={Link} to="/manual" className={classes.manualLink}>
      <Typography component="p" align="center" variant='inherit'>
        サイトの利用方法はこちら
      </Typography>
    </LinkStyle>
  </Grid>
}
