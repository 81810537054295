import React, {
  // useState,
  useEffect,
  // useCallback,
  useContext,
} from 'react'
import firebase from '../../firebase/index'
// import STYLES from "../../constants/styles"
// import SITEINFO from "../../constants/siteInfo"
// import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  useHistory,
} from 'react-router-dom'
import {
  Button,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core'
import CardTemplate from '../templates/CardTemplate'
import { GlobalContext } from '../../hooks/reducer'

const useStyles = makeStyles((theme) => ({
  title: {
  },
  subTitle: {
  },
  error: {
    color: theme.palette.error.main
  },
}))

const SendMailToUpdatePassword = React.memo((props) => {
  const { state: { signin, common }, dispatch } = useContext(GlobalContext)
  const { sendemail, disabled } = signin
  const { errors } = common
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    return () => {
      // unmount
      dispatch({ type: 'RESET' })
    }
  }, [dispatch])

  const handleSendMail = () => {
    firebase.auth().sendPasswordResetEmail(sendemail)
      .then(() => {
        console.log('Email sent.')
        history.push("/forget-password/complete-send-mail")
      }).catch((error) => {
        dispatch({ type: 'ERROR', data: error.message })
        console.log(error)
      })
  }

  return (
    <CardTemplate>
      <Typography variant="h6" align="center" className={classes.title}>
        パスワードを再設定する
        </Typography>
      {
        errors.isError && <Typography variant="subtitle1" align="center" className={classes.error}>
          {errors.message}
        </Typography>
      }
      <Typography variant="subtitle2" className={classes.subTitle}>
        登録しているメールアドレスを入力してください
        </Typography>
      <FormControl className={""} variant="outlined" fullWidth={true}>
        <TextField
          label="メールアドレス"
          value={sendemail}
          onChange={(e) => dispatch({ type: 'CHANGE_SENDEMAIL_VALUE', data: e.target.value })}
          className={""}
          variant="outlined"
          required
          fullWidth={true}
          error={errors.isError}
          helperText={errors.isError && '登録している正しいメールアドレスを入力してください'}
        />
        <Button
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={() => handleSendMail()}
        >
          パスワードを再設定する
          </Button>
      </FormControl>
    </CardTemplate>
  )
})
export default SendMailToUpdatePassword
