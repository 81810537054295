import React from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import MainTemplate from '../templates/MainTemplate'
import PageTitle from '../common/PageTitle'
import {
  Card,
  CardHeader,
  CardMedia,
  Container,
  Typography,
} from '@material-ui/core'
import { Back } from '../common/Back'
import { useHistory } from 'react-router'
import Ikemoto from "../../assets/ikemoto.jpg";
import Akiyama from "../../assets/akiyama.jpg";

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  card: {
    paddingBottom: '24px',
    marginBottom: '40px',
  },
  header: {
    paddingTop: '38px'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  text: {
    marginTop: '15px',
  },
  img: {
    maxHeight: '345px',
    width: 'auto',
    margin: '0 auto',
    maxWidth: '100%',
  },
}))

const Instructors = React.memo((props) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <MainTemplate>

      <Back history={history} />
      <PageTitle title='講師紹介' />

      <Container component={Card} maxWidth="sm" className={clsx('signform', classes.card)}>
        <CardHeader
          title={<CardMedia src={Ikemoto} component='img' className={classes.img} />}
          className={classes.header}
        />
        <Typography variant='body1' className={classes.title}>
          池本克之
        </Typography>
        <Typography variant='body1' className={clsx('indention', classes.text)}>
          1965年神戸市生まれ<br />
          1988年日本大学生物資源科学部卒<br />
          <br />
          ノンバンク、海外ホテル事業、生命保険代理店営業を経験。<br />
          財務、マーケティング、セールス、人材教育などを体得する。<br />
          <br />
          その後、通信販売のベンチャー企業の経営に参画。<br />
          それまでのノウハウを実践する。<br />
          <br />
          株式会社ドクターシーラボ移籍後、<br />
          代表取締役として2003年3月ジャスダック店頭公開に貢献。<br />
          2003年11月ドクターシーラボを退任。<br />
          月商1億円に満たない時代から1年3ヶ月で月商7億円超に、<br />
          さらに年商120億円企業へと成長させた。<br />
          <br />
          2004年3月株式会社ネットプライス執行役員に就任。<br />
          公開企業のマネジメント経験を活かし、<br />
          若いベンチャー企業の参謀役として<br />
          カスタマーサービス、物流、CRM、仕入先開拓等の機能を統括する。<br />
          <br />
          2004年7月にはマザーズ店頭公開。経営者として2度の株式公開を経験する。<br />
          <br />
          その後、複数の企業経営を経て、<br />
          現在は組織学習経営コンサルタントとして多くの企業の業績向上、<br />
          企業文化の発展に向けコンサルティングしている。
        </Typography>
      </Container>

      <Container component={Card} maxWidth="sm" className={clsx('signform', classes.card)}>
        <CardHeader
          title={<CardMedia src={Akiyama} component='img'  className={classes.img}/>}
          className={classes.header}
        />
        <Typography variant='body1' className={classes.title}>
          秋山佑介
        </Typography>
        <Typography variant='body1' className={clsx('indention', classes.text)}>
          LSGホールディングスグループCEO<br />
          青山学院大学講師<br />
          <br />
          1988年山梨県生まれ。<br />
          大学在学中に個別指導学習塾運営会社に勤務し、<br />
          最高情報責任者・東京支部長を兼任。<br />
          創業期の出店拡大・コンプライアンスの策定に従事する。<br />
          入社時2教室だった教室数は現在全国170教室。<br />
          <br />
          学習塾勤務時代の同僚より叱咤激励され、<br />
          諦めていた夢に挑戦。<br />
          独立行政法人航空大学校に合格し、<br />
          その後、事業用操縦士として従事。<br />
          現在も教官を務めるパイロット予備校では、<br />
          セミナー受講者の国家試験合格率の高さから、<br />
          「パイロット試験の第一人者」と呼ばれている。<br />
          <br />
          2011年、<br />
          IT・経営コンサル会社の現LSGホールディングスグループ創業。<br />
          傘下に通販業・配送業等。<br />
          <br />
          現在は、<br />
          経営コンサルタントとして複数企業でのコンサル業務を行なっている。<br />
          7社の取締役及び社外取締役・青山学院大学講師・パイロット予備校教官。
        </Typography>
      </Container>

    </MainTemplate >
  )
})

export default withWidth()(Instructors)
