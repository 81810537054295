import React from 'react'

// TODO: postCase一元化

const ProgressModal = props => {
  const { class_name } = props
  const _class_name = class_name || "loading-bro"
  return <div className={_class_name}>
    <p className={_class_name + '-txt'}>uploading...</p>
    <svg id="load" x="0px" y="0px" viewBox="0 0 150 150">
      <circle id="loading-inner" cx="75" cy="75" r="60" />
    </svg>
  </div>
}

export default ProgressModal
