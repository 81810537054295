import React, { useEffect, useContext, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import MainTemplate from "../templates/MainTemplate"
import {
  Avatar,
  Paper,
  Table,
  Button,
  Link,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableHead,
  Grid,
  Typography,
  TextField,
  TableSortLabel,
} from '@material-ui/core'
import { GlobalContext } from '../../hooks/reducer'
import { actions } from "../../hooks/useLoginUserReducer"
import { actions as adminActions } from "../../hooks/useAdminReducer"
import CustomModal from "../common/CustomModal"
import EllipsisTableCell from '../common/EllipsisTableCell'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { divideCasesPerPage } from '../../lib/cases'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: '1px rgba(224, 224, 224, 1) solid',
    marginBottom: theme.spacing(2),
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '20px 0',
    gap: '5px 10px',
    '& > button': {
      marginBottom: '22px',
    }
  },
  table: {
    minWidth: 750,
  },
  td: {
    fontSize: '12px',
  },
  tag: {
    marginRight: '10px'
  },
  fixed: {
    position: 'sticky',
    zIndex: 1,
    left: 0,
    background: '#ffffff',
    '&::before': {
      content: '',
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      width: '100%',
      height: '100%',
    },
    // '&:hover': {
    //   backgroundColor: 'rgba(0, 0, 0, 0.04)',
    // },
  },
  scrollbar: {
    width: '100%',
    height: '10px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      height: '10px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#e7e7e7',
    }
  },
  scrollbarInner: {
    width: '1500px',
    height: '1px'
  },
  scrollbox: {
    width: '100%',
    marginTop: '5px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      height: '10px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#e7e7e7',
    }
  },
  scrollboxInner: {
    width: '1500px',
    marginBottom: '5px',
    backgroundColor: '#F9F8F6',
  },
  avatar: {
    transition: 'border 0.3s ease',
    cursor: 'pointer', 
    border: '2px solid transparent', /* 初期状態では透明な枠線 */
    '&:hover': { // ホバー時のスタイルを定義
      border: '2px solid rgba(57, 84, 117, 0.7019607843)',
      opacity: 0.8,
    },
  },
  caption: {
    margin: '10px 0',
  },
  tdName: {
    width: '120px',
  },
  tdCreateAt: {
    width: '120px',
  },
  tdUser: {
    width: '90px',
  },
  tdIndust: {
    width: '110px',
  },
  tdPref: {
    width: '110px',
  },
  tdMember: {
    width: '110px',
  },
  tdText: {
    width: '110px',
  },
  tdcVideo: {
    width: '110px',
  },
  tdId: {
    width: '110px',
  },
  howto: {  
    border: '1px #e0e0e0 solid',
    borderRadius: '5px',
    padding: '10px',
    fontSize: '12px',
    background: 'rgb(249 248 246)',
    '& > li::marker': {
      content: '""',
    }
  }
}))

const headCells = [
  { id: 'name', sort: true, align: 'center', label: '会社名', style: {}, padding: '', className: '' },
  { id: 'createAt', sort: true, align: 'center', label: '投稿日', style: {}, padding: '', className: '' },
  { id: 'userName', sort: true, align: 'left', label: '投稿者名', style: {}, padding: '', className: '' },
  { id: 'video', sort: false, align: 'left', label: '動画', style: {}, padding: '', className: '' },
  { id: 'text', align: 'left', label: '内容', style: {}, padding: '', className: '' },
  { id: 'industryTags', align: 'left', label: '業種・業界', style: {}, padding: '', className: '' },
  { id: 'prefTags', align: 'left', label: '所在地', style: {}, padding: '', className: '' },
  { id: 'memberNumTags', align: 'left', label: '社員数', style: {}, padding: '', className: '' },
  { id: 'id', align: 'left', label: '会社ID', style: {}, padding: '', className: '' },
]

const EnhancedTableHead = (props) => {
  const { classes, order, handleSortRequest } = props

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell 
            key={i + 'head'} 
            sortDirection={order.orderBy === headCell.id ? order.order : false}
            align='left'
            padding={headCell.padding}
            style={headCell.style}
            className={headCell.id === 'name' ? classes.fixed : headCell.className}
          >
            {
              !headCell.sort ? headCell.label :
                <TableSortLabel
                  active={order.orderBy === headCell.id}
                  direction={order.orderBy === headCell.id ? order.order : 'desc'}
                  onClick={() => handleSortRequest(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const sortables = headCells.filter(cell => cell.sort).map(cell => cell.label)

const HowTo = () => {
  const classes = useStyles()

  return <ul className={classes.howto}>
    <li>
      ※<b>会社名で検索</b>できます。会社名を入力すると候補が出てきます。
    </li>
    <li>
      ※「{sortables.join(', ')}」は、<b>並べ替えが可能</b>です。項目名をクリックしてください。
    </li>
    <li>
      ※省略されているものは、<b>クリックすると全文表示</b>できます。
    </li>
  </ul>
}

const Companies = React.memo((props) => {
  const classes = useStyles()
  const history = useHistory()
  const barRef = useRef()
  const boxRef = useRef()

  const { state: { loginUser, admin }, dispatch } = useContext(GlobalContext)
  const { allCases, total, companies } = admin

  const [cases, setCases] = useState([]);

  const rowsPerPageOptions = [3, 25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [searchQuery, setSearchQuery] = useState(null);
  const [company, setCompany] = useState(null);

  const [order, setOrder] = useState({
    order: 'desc', 
    orderBy: ''
  }); // Example: 'asc' or 'desc'

  // Fetch cases based on user state, page, and sorting
  useEffect(() => {
    if (loginUser.isLogin) {
      adminActions.getCaseInfos(dispatch, { searchQuery });
    } else {
      actions.loginCheck(dispatch, history);
    }
    return () => { 
      dispatch({ type: 'COMMON_RESET' });
      
      // メモリ解放
      dispatch({ type: 'SET_CASE_INFOS', data: [], total: 0 });
    }

    // NOTE: ページが変わるたびに再取得すると課金が発生するため、再取得しないように修正
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loginUser.isLogin, searchQuery, /* page, rowsPerPage, order*/]);

  useEffect(() => {
    if (allCases?.length > 0) {
      const casesParPage = divideCasesPerPage({ page, rowsPerPage, order, cases: allCases });
      setCases(casesParPage);
      // ローカルのときだけ
      if (process.env.NODE_ENV === 'development') {
        console.log('casesParPage', casesParPage);
      }
    } else {
      setCases([]);
    }
  }, [dispatch, allCases, page, rowsPerPage, order]);

  useEffect(() => {
    if (loginUser.isLogin) adminActions.getCompanies(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loginUser.isLogin]);

  useEffect(() => {
    const scrollbar = document.getElementById('scrollbar')
    const scrollbox = document.getElementById('scrollbox')
    scrollbar.addEventListener('scroll', () => {
      scrollbox.scrollLeft = scrollbar.scrollLeft
    })
    scrollbox.addEventListener('scroll', () => {
      scrollbar.scrollLeft = scrollbox.scrollLeft
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = (e, newPage) => { setPage(newPage) }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const searchCases = () => {
    let search = null;
    if (company) {
      search = { ...search, company };
    }

    setSearchQuery(search)
  }

  // 並び替えの関数
  const handleSortRequest = (property) => {
    const isAsc = order.orderBy === property && order.order === 'asc';
    setOrder({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    });
  };

  return (
    <MainTemplate>

      {/* モーダル */}
      <CustomModal />

      <div className={classes.root}>

        <Typography variant='h5'>
          事例共有情報一覧
        </Typography>

        <HowTo />
        
        {/* 検索 */}
        <div className={classes.search}>
          <Autocomplete
            options={companies}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="会社選択" variant="outlined" />}
            onChange={(event, value) => setCompany(value)}
          />
          {/* 検索ボタン */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => searchCases()}
          >
            検索
          </Button>
        </div>

        <Paper className={classes.paper}>
          <div className={classes.scrollbar} id="scrollbar"><div ref={barRef} className={classes.scrollbarInner}></div></div>
          <TableContainer className={classes.scrollbox} id="scrollbox">
            <Table
              className={clsx(classes.table, classes.scrollboxInner)}
              aria-labelledby="tableTitle"
              size='medium'
              aria-label="enhanced table"
              ref={boxRef}
            >
              <EnhancedTableHead 
                classes={classes} 
                orderBy={order.orderBy}
                order={order.order}
                handleSortRequest={handleSortRequest}
              />

              <TableBody>
                {cases
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                      >

                        {/* アバター */}{/* 会社名 */}
                        <TableCell 
                          component="th" 
                          id={labelId} 
                          scope="row" 
                          className={clsx(classes.fixed, classes.td, classes.tdName)}
                        >
                          <Grid 
                            container 
                            alignItems='center' 
                            direction='column'
                          >
                            <Grid 
                              item 
                              component={Avatar} 
                              src={row.user?.companyInfo?.imageUrl} 
                              className={'comment_avatar ' + classes.avatar} 
                              onClick={() => history.push(`/company/${row.user?.companyId}`)}
                            />
                            {
                              !!row.user?.companyInfo?.url ? <Grid item component={Link}
                                href={row.user?.companyInfo?.url}
                                color='primary'
                                underline='hover'
                                target='_blank'
                              >
                                {row.user?.companyInfo?.name}
                              </Grid> : <Grid item component='p'>{row.user?.companyInfo?.name}</Grid>
                            }
                          </Grid>
                        </TableCell>

                        {/* 投稿日 */}
                        <TableCell 
                          component="th" 
                          id={labelId} 
                          scope="row" 
                          className={clsx(classes.td, classes.tdCreateAt)}
                          align='center'
                        >
                          {dayjs(row.createAt).format('YYYY/MM/DD HH:mm:ss')}
                        </TableCell>

                        {/* 投稿者名 */}
                        <TableCell
                          component="th" 
                          id={labelId} 
                          scope="row" 
                          className={clsx(classes.td, classes.tdUser)}
                        >
                          {row.user?.name}
                        </TableCell>

                        {/* ビデオ */}
                        <TableCell 
                          component="th" 
                          id={labelId} 
                          scope="row"
                          className={clsx(classes.td, classes.tdcVideo)}
                        >
                          {row.video?.vimeoUrls && row.video?.vimeoUrls.length > 0 &&
                            row.video.vimeoUrls.map((url, _i) => {
                              return <div key={_i + "video"}>{url}</div>
                            }
                          )}
                        </TableCell>

                        {/* 投稿 */}
                        <EllipsisTableCell
                          text={row.text}
                          labelId={labelId}
                          className={clsx(classes.td, classes.tdText)}
                          width="110px"
                        />

                        {/* 業界 */}
                        <EllipsisTableCell
                          text={row.industryTags?.map((tag, i) => tag.label).join(", ") }
                          labelId={labelId}
                          className={clsx(classes.td, classes.tdIndust)}
                          width="110px"
                        />

                        {/* 所在地 */}
                        <EllipsisTableCell
                          text={row.prefTags?.map((tag, i) => tag.label).join(", ")}
                          labelId={labelId}
                          className={clsx(classes.td, classes.tdPref)}
                          width="110px"
                        />

                        {/* 社員 */}
                        <TableCell 
                          component="th" 
                          id={labelId} 
                          scope="row"
                          className={clsx(classes.td, classes.tdMember)}
                        >
                          <p>{row.memberNumTags.label}</p>
                        </TableCell>

                        {/* 会社ID */}
                        <TableCell
                          component="th" 
                          id={labelId} 
                          scope="row" 
                          className={clsx(classes.td, classes.tdId)}
                        >
                          {row.user?.companyId}
                        </TableCell>

                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* テーブルページネーション */}
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

    </MainTemplate >
  )
})
export default Companies
