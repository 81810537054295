import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import "./../scss/App.scss"
import SignIn from "./signIn/SignIn"
import SignUp from "./signUp/SignUp"
import ShareCase from "./shareCase/ShareCase"
import PostCase from "./shareCase/PostCase"
import CompleteSendMail from './fogetPassword/CompleteSendMail'
import SendMailToUpdatePassword from './fogetPassword/SendMailToUpdatePassword'
import SampleNozomi from './SampleNozomi'
import SignUpComplete from './signUp/SignUpComplete'
import MyAccount from './account/MyAccount'
import Notice from './account/Notice'
import Member from './account/Member'
import Consult from './Consult/Consult'
import PostConsult from './Consult/PostConsult'
import IndivConsult from './Consult/IndivConsult'
import Info from './info/Info'
import Admin from './Admin/Admin'
import PostInfo from './info/PostInfo'
import Companies from './Admin/Companies'
import CaseManagement from './Admin/CaseManagement'
import CompanyPage from './shareCase/CompanyPage/CompanyPage'
import { BackTop } from './common/BackTop'
import AboutSite from './about/AboutSite'
import TermsService from './about/TermsService '
import SignInManual from './signIn/SignInManual'
import Policy from './about/Policy'
import Instructors from './about/Instructors'
import Contact from './contact/Contact'
import PostCaseAll from './Admin/PostCaseAll'
import AdminTags from './Admin/AdminTags'
import ImportUsers from './Admin/ImportUsers'
import FbCase from './shareCase/fbCase/FbCase'

export default () => {
  return (
    <div>
      <Router>
        <Switch>
          {/* 共有事例 */}
          <Route path="/" exact children={<ShareCase />} />
          <Route path="/case/post" exact children={<PostCase />} />
          <Route path="/case/edit/:key" exact children={<PostCase />} />
          {/* 会社ページ（会社の事例一覧） */}
          <Route path="/company/:companyId" exact children={<CompanyPage />} />
          <Route path="/fb/:companyId" exact children={<FbCase />} />
          {/* ログイン */}
          <Route path="/login" exact children={<SignIn />} />
          <Route path="/forget-password" exact children={<SendMailToUpdatePassword />} />
          <Route path="/forget-password/complete-send-mail" exact children={<CompleteSendMail />} />
          {/* 会員登録 */}
          <Route path="/signup" exact children={<SignUp />} />
          <Route path="/signup/complete" exact children={<SignUpComplete />} />
          <Route path="/manual" exact children={<SignInManual />} />
          {/* マイページ */}
          <Route path="/my_account" exact children={<MyAccount />} />
          <Route path="/my_account/notice" exact children={<Notice />} />
          <Route path="/member" exact children={<Member />} />
          {/* 相談部屋 */}
          <Route path="/consult" exact children={<Consult />} />
          <Route path="/consult/post" exact children={<PostConsult />} />
          <Route path="/consult/edit/:consultKey" exact children={<PostConsult />} />
          <Route path="/consult/:consultKey" exact children={<IndivConsult />} />
          {/* お知らせ */}
          <Route path="/info" exact children={<Info />} />
          <Route path="/info/edit/:infoKey" exact children={<PostInfo />} />
          <Route path="/info/post" exact children={<PostInfo />} />
          {/* パジャポス運営 */}
          <Route path="/admin" exact children={<Admin />} />
          <Route path="/admin/companies" exact children={<Companies />} />
          <Route path="/admin/post_cases" exact children={<PostCaseAll />} />
          <Route path="/admin/tags" exact children={<AdminTags />} />
          <Route path="/admin/case_management" exact children={<CaseManagement />} />
          <Route path="/admin/import-users" exact children={<ImportUsers />} />
          {/* サイト概要 */}
          <Route path="/about_site" exact children={<AboutSite />} />
          <Route path="/policy" exact children={<Policy />} />
          <Route path="/term_service" exact children={<TermsService />} />
          <Route path="/about_instructors" exact children={<Instructors />} />
          {/* 問い合わせ */}
          <Route path="/contact" exact children={<Contact />} />
          {/* 開発用 */}
          <Route path="/sample" exact children={<SampleNozomi />} />
        </Switch>
      </Router>

      <BackTop />
    </div>
  )
}
