import React from 'react'
import { Typography } from '@material-ui/core'

export const Required = () => (
  <Typography
    variant='caption'
    color='error'
    component='span'
    style={{
      fontSize: '0.8em',
      marginLeft: '5px',
    }}
  >
    ※必須
  </Typography>
)
