import firebase, { functions, storage, userRef } from "../firebase/index"
import { actions as accountActions } from "./useAccountReducer"
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

export const initialState = {
  signup: {
    user: {
      imageBase64: null,
      imageFile: null,
      imageName: '',
      imageUrl: '',
      name: '',
      email: '',
      password: '',
      passwordConfirm: '',
    },
    mainCompany: {
      imageBase64: null,
      imageFile: null,
      imageName: '',
      imageUrl: '',
      name: '',
      url: '',
      prefTags: [],
      industryTags: [],
      memberNumTags: [],
    },
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      console.log("signup RESET")
      return initialState.signup
    case 'SIGNUP_RESET':
      console.log("signup RESET")
      return initialState.signup
    case 'CHANGE_SIGNUP_USER_VALUE':
      return {
        ...state,
        user: {
          ...state.user,
          [action.field]: action.data,
        },
      }
    case 'CHANGE_SIGNUP_MAIN_COMPANY_VALUE':
      return {
        ...state,
        mainCompany: {
          ...state.mainCompany,
          [action.field]: action.data,
        },
      }
    case 'CHANGE_SIGNUP_IMAGE':
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          ...action.data,
        },
      }
    default: return state
  }
}


export const actions = {
  storeImage: (dispatch, file, type) => {
    // 拡張子取得
    const fileName = file.name
    const lastDot = fileName.lastIndexOf('.')
    const ext = fileName.substring(lastDot + 1)

    // ファイル名決定
    const unixms = dayjs().valueOf()
    const rand = Math.floor(Math.random() * 101)
    const newFileName = `${unixms}_${rand}.${ext}`

    let refPath = ''
    let accountReducerType = ''
    let message = ''

    // プロフィール画像
    if (type === 'user') {
      refPath = 'profile'
      accountReducerType = 'CHANGE_EDIT_USER_IMAGE'
      message = 'プロフィール画像'
    }

    // 会社画像
    if (type === 'mainCompany') {
      refPath = 'company'
      accountReducerType = 'CHANGE_EDIT_COMPANY_IMAGE'
      message = '会社画像'
    }

    // メンバー画像
    if (type === 'member') {
      refPath = 'profile'
      accountReducerType = 'CHANGE_EDIT_MEMBER_IMAGE'
      message = 'ユーザー画像'
    }

    // 追加メンバー画像
    if (type === 'addMember') {
      refPath = 'profile'
      accountReducerType = 'CHANGE_ADD_MEMBER_IMAGE'
      message = 'ユーザー画像'
    }

    // ストレージへのパスを生成
    const uploadRef = storage.ref(refPath).child(newFileName)
    uploadRef
      .put(file)
      .then(snapshot => {
        // 画像取得
        uploadRef
          .getDownloadURL()
          .then(url => {
            if (type !== 'member') {
              dispatch({
                type: 'CHANGE_SIGNUP_IMAGE',
                field: type,
                data: { imageUrl: url, imageName: newFileName }
              })
            }
            dispatch({
              type: accountReducerType,
              data: { imageUrl: url, imageName: newFileName }
            })
          }).catch(function (error) {
            console.log('error get user image', error)
            // TODO: 画像削除
            throw new Error(`${newFileName} ${message}の取得に失敗しました。 ${error.message}`)
          })
      })
      .catch(error => {
        console.log(error)
        throw new Error(`${message}の登録に失敗しました。 ${error.message}`)
      })
  },
  deleteImage: (imagePath) => {
    const desertRef = storage.ref().child(imagePath)
    desertRef.delete().then(() => {
      console.log("deleteImage done")
    }).catch((error) => {
      console.log("deleteImage error", error.message)
    })
  },
  createMember: async (email, password) => {
    let addUser = {
      email: email,
      emailVerified: false,
      password: password,
    }

    const func = functions.httpsCallable("createMember")
    const createdUser = await func(addUser).then(res => {
      return res.data
    }).catch(e => {
      console.log(e)
      throw new Error(`登録に失敗しました。`)
    })
    return createdUser
  },
  createUser: async (email, password) => {
    let createdUser = {}
    await firebase.auth().createUserWithEmailAndPassword(email, password)
      .then((newUser) => {
        console.log("createUser done")
        createdUser = newUser.user
      })
      .catch((error) => {
        console.log("createUser error", error.code)
        throw new Error(`会員登録に失敗しました。 ${error.message}`)
      })
    const mailUserRef = firebase.auth().currentUser
    return { createdUser, mailUserRef }
  },
  /** ユーザー情報追加 */
  addUserInfo: async (user, companyInfo) => {
    console.log('add UserInfo start')
    const info = await accountActions.setUserData(user, companyInfo)
    const newUserRef = await userRef.push(info)
    return newUserRef
  },
  /** ユーザー情報一括追加 */
  addUsersInfo: async (users, companyInfo) => {
    console.log('addUsersInfo start')
    const updates = {};

    // ユーザーごとの処理を非同期で実行
    await Promise.all(users.map(async user => {
      const info = await accountActions.setUserData(user, companyInfo)
      updates[user.uid] = info;
    }));

    // updatesオブジェクトが完成してから、userRef.updateを実行
    await userRef.update(updates);
    return updates;
  },
}
