import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import {
  Button,
  Card,
  CardHeader,
  Container,
  Link,
  Typography,
} from '@material-ui/core'
import ManualPDF from "../../../assets/CCSコミュニティサイト_操作マニュアル.pdf"

const useStyles = makeStyles((theme) => ({
  card: {
    paddingBottom: '16px',
    marginBottom: '40px',
  }
}))

const Manual = React.memo((props) => {
  const classes = useStyles()
  return (
    <Container component={Card} maxWidth="sm" className={clsx('signform', classes.card)}>
      <CardHeader title='当サイトの利用方法' />

      <Typography className='indention'>
        当サイトの利用方法は、「CCSコミュニティサイト_操作マニュアル.pdf」をご覧ください。<br />
        下記の「PDFをダウンロードする」ボタンをクリックすると、「CCSコミュニティサイト_操作マニュアル.pdf」のダウンロードができます。
      </Typography>

      <Link href={ManualPDF} underline='none' target='_blank' rel='noopener noreferrer'>
        <Button variant="contained" color="secondary">
          PDFをダウンロードする
        </Button>
      </Link>
    </Container>
  )
})

export default withWidth()(Manual)
