import React, { useContext, useState } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Card,
  CardContent,
  Typography,
  Button,
} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import {
  Edit as EditIcon,
} from '@material-ui/icons'
import { GlobalContext } from '../../hooks/reducer'
import { commonTheme } from '../../materialui/theme'
import { actions as commonActions } from '../../hooks/useCommonReducer'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const mainStyle = (theme) => {
  return {
    root: {
      margin: '40px 0',
      border: `2px solid ${commonTheme.palette.secondary.main}`
    },
    content: {
      whiteSpace: 'pre-line',
      overflowWrap: 'break-word',
    },
    contentBottom: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      padding: '14px 0',
    },
    commentForm: {
      display: 'flex',
      alignItems: 'end',
      marginTop: '15px',
    },
    textField: {
      "& > input": {
        paddingTop: '0.55em',
        paddingBottom: '0.55em',
      }
    },
    caution: {
      marginLeft: 0,
      marginRight: 0,
      color: theme.palette.error.main,
    },
    gradiant: {
      display: 'none',
    },
    openArrow: {
      display: 'none',
    }
  }
}

const useStyles = makeStyles((theme) => (mainStyle(theme)))
const useImportantInfoStyles = makeStyles((theme) => ({
  ...mainStyle(theme),
  root: {
    ...mainStyle(theme).root,
    margin: '0',
    overflow: 'hidden',
    height: '200px',
    position: 'relative',
  },
  gradiant: {
    position: 'absolute',
    bottom: 0,
    height: '300px',
    width: '100%',
    // NOTE: 警告が出るのでまとめる
    // background: 'rgb(255,255,255)',
    // background: 'linear-gradient(0deg, rgba(255,255,255,0.9139005944174545) 0%, rgba(255,255,255,0) 50%)',
    background: 'linear-gradient(0deg, rgba(255,255,255,0.9139005944174545) 0%, rgba(...)), rgb(255,255,255)',
  },
  noneGradiant: {
    background: 'none',
  },
  openArrow: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '10px 25px 0 25px',
    borderColor: '#a6a6a6 transparent transparent transparent',
    transform: 'translateX(-50%)',
    position: 'absolute',
    bottom: '8px',
    left: '50%',
  },
  closeArrow: {
    borderWidth: '0 25px 10px 25px',
    borderColor: 'transparent transparent #a6a6a6 transparent',
  },
  openedInfo: {
    height: '100%',
  },
}))

const FixedInfoList = React.memo((props) => {
  const { state: { loginUser } } = useContext(GlobalContext)
  const { content, customStyle, InfoKey } = props
  const { key, text, title, updateAt } = content

  // NOTE: 重要な投稿の時は、keyが付与されないので、InfoKeyから取得
  const info_key = InfoKey || key

  const history = useHistory()
  const classes = customStyle === 'importantInfo' ? useImportantInfoStyles() : useStyles()

  const [openedInfo, setOpenedInfo] = useState(false)

  return (
    <Card
      component='li'
      id={'info-' + info_key}
      className={clsx({
        [classes.root]: true,
        [classes.openedInfo]: openedInfo
      })}
    >

      {/* 重要なお知らせ用のグラデーション */}
      <div
        className={clsx({
          [classes.gradiant]: true,
          [classes.noneGradiant]: openedInfo
        })}
        onClick={() => { setOpenedInfo(!openedInfo) }}
      >
        <div className={clsx({
          [classes.openArrow]: true,
          [classes.closeArrow]: openedInfo
        })}></div>
      </div>

      <CardContent>

        {/* タイトル */}
        <Typography variant="h6" className={classes.content}>
          {title}
        </Typography>
        {/* 内容 */}

        <Typography
          variant="body1"
          className={classes.content}
          dangerouslySetInnerHTML={commonActions.changeToURL(text)}
        />

        {/* 時間・編集 */}
        <div className={classes.contentBottom}>
          <p style={{ margin: '0' }}>
            <Typography variant="caption">
              更新 {dayjs(updateAt).format('YYYY年MM月DD日 HH:mm')}
            </Typography>
          </p>

          {
            loginUser.user.pajaposs && <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ margin: 0 }}
              onClick={() => history.push(`/info/edit/${info_key}`)}
            >
              <EditIcon fontSize='small' style={{ marginRight: '5px' }} />
              投稿を編集する
            </Button>
          }
        </div>

      </CardContent>

    </Card >
  )
})
export default withWidth()(FixedInfoList)
