import React from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Avatar,
  Button,
  Chip,
  FormControl,
  ListItem,
  ListItemAvatar,
  Tooltip,
  Typography,
  IconButton,
  OutlinedInput,
  Grid,
} from '@material-ui/core'
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import MODAL from '../../constants/modal'
import { commonTheme } from '../../materialui/theme'
import { actions as commonActions } from '../../hooks/useCommonReducer'
import { Vimeo } from '../shareCase/common/Vimeo'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  list:{
    marginBottom: '15px',
  },
  commentForm: {
    display: 'flex',
    alignItems: 'end',
    marginTop: '15px',
    flexFlow: 'wrap',
    justifyContent: 'flex-end',
    width: '100%',
  },
  commentBtm: {
  },
  videoText: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  videoTextOpen: {
    color: theme.palette.primary.light,
  },
  sup: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: '6px'
  },
  replyTo: {
    fontWeight: 'bold',
    marginRight: '5px',
    fontSize: '0.75rem',
    verticalAlign: 'middle'
  },
  text: {
    verticalAlign: 'middle'
  },
  editFrom: {
    width: '100%'
  },
  editBtn: {
    margin: 0
  },
  videoDeleteCautionTxt: {
    color: theme.palette.error.main,
  },
  editBtnGroup: {
    textAlign: 'right',
  }
}))

const useStylesEditTooltips = makeStyles(theme => ({
  tooltip: {
    fontSize: "1em",
  },
}))

const useStylesDeleteTooltips = makeStyles(theme => ({
  arrow: {
    color: theme.palette.error.light,
  },
  tooltip: {
    backgroundColor: theme.palette.error.light,
    fontSize: "1em",
  },
}))

export const Comment = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const editTooltipsClasses = useStylesEditTooltips()
  const deleteTooltipsClasses = useStylesDeleteTooltips()

  const {
    loginUser, handleDispatch, comment, editComments, commentActions, goods, goodKeys, good, commentKey,
    isShowCmnts, isShowCmntField, contentType, defaultShowAll, forcedChangeURL
  } = props
  const { video } = comment
  const isVideo = !!video && video.vimeoUrls && video.vimeoUrls.length > 0
  const openCommentClass = isShowCmnts[comment.key] ? 'comment_item_txt_open' : ''
  const textClass = defaultShowAll || isShowCmnts[comment.key] ? 'indention' : ''
  const isBtnClass = defaultShowAll ? 'not_pointer' : ''

  const editable = commonActions.editable(comment.user, loginUser.user)

  const goodNum = !!goods ? goods.length : 0

  let
    isShowComment,
    replyType,
    isShowCmntFieldType,
    changeEditValueType,
    deletePath = ''

  let commonGoodTypeObj = {
    user: loginUser.user,
    type: contentType,
  }

  let goodTypeObj = {}

  switch (contentType) {
    case 'consultCmnt':
      isShowComment = 'IS_SHOW_CONSULT_CMNT'
      replyType = 'SET_TO_REPLY'
      isShowCmntFieldType = 'IS_SHOW_CONSULT_CMNT_FIELD'
      changeEditValueType = 'CHANGE_EDIT_CONSULT_COMMENT_VALUE'
      deletePath = `consultComments/${comment.key}`
      goodTypeObj = {
        ...commonGoodTypeObj,
        consultKey: comment.consultKey,
        consultCmntKey: comment.key,
        good: good,
      }
      break
    case 'case':
      isShowComment = 'IS_SHOW_CMNT'
      // replyType = 'SET_TO_REPLY'
      isShowCmntFieldType = 'IS_SHOW_CMNT_FIELD'
      changeEditValueType = 'CHANGE_EDIT_COMMENT_VALUE'
      deletePath = `caseComments/${comment.key}`
      goodTypeObj = {
        ...commonGoodTypeObj,
        caseKey: comment.caseKey,
        caseCmntKey: comment.key,
        goodKeys: goodKeys,
        goods: goods,
      }
      break
    case 'info':
      isShowComment = 'IS_SHOW_INFO_CMNT'
      isShowCmntFieldType = 'IS_SHOW_INFO_CMNT_FIELD'
      changeEditValueType = 'CHANGE_EDIT_INFO_COMMENT_VALUE'
      deletePath = `infoComments/${comment.key}`
      break
    default:
      break
  }

  // video
  let videoId = ''
  if (isVideo) {
    const uri = video.vimeoUrls[0]
    const pos = uri.lastIndexOf('/')
    videoId = uri.slice(pos + 1)
  }

  return (
    <li className={classes.list}>

      {/* コメント内容 */}
      <ListItem component='div' className='comment_item'>
        {
          !isShowCmntField[comment.key] && <React.Fragment>

            <ListItemAvatar className='comment_item_avater'>
              <Avatar
                src={comment.user.imageUrl}
                onClick={() => history.push(`/company/${comment.user.companyId}`)}
                className='comment_avatar avatar'
              />
            </ListItemAvatar>

            {/* コメント内容表示 */}
            <div
              className={clsx('comment_item_txt', openCommentClass, isBtnClass)}
              onClick={() => handleDispatch({
                type: isShowComment,
                field: comment.key,
                data: !!openCommentClass ? false : true
              })}
            >

              <Typography component='p' className='comment_item_txt_name' variant="caption">
                {comment.user.name} {comment.user.companyInfo.name}
              </Typography>

              <Typography>
                {/* 返信先 */}
                {
                  (!!comment.to && !!comment.to.name) ?
                    <span className={classes.replyTo}>{comment.to.name}</span> : ''
                }
                {/* コメント内容 */}
                <span
                  className={clsx(textClass, classes.text)}
                  
                  dangerouslySetInnerHTML={
                    comment.text 
                    ? commonActions.changeToURL(comment.text, !!openCommentClass, forcedChangeURL)
                    : { __html: '' } // `comment.text` が無い場合は空の文字列を設定
                  }
                />
              </Typography>

            </div>

            <div className='comment_item_icons'>

              {
                editable && <React.Fragment>
                  {/* コメント編集 */}
                  <Tooltip
                    title="編集"
                    placement="top"
                    arrow
                    classes={editTooltipsClasses}
                  >
                    <IconButton
                      edge="end"
                      onClick={() => handleDispatch({
                        type: isShowCmntFieldType,
                        field: comment.key, data: true
                      })}
                    >
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>

                  {/* コメント削除 */}
                  <Tooltip
                    title="削除"
                    placement="top"
                    arrow
                    classes={deleteTooltipsClasses}
                  >
                    <IconButton
                      edge="end"
                      onClick={() => handleDispatch({
                        type: 'IS_OPEN',
                        data: { bool: true, stage: MODAL.willDelete, path: deletePath, video: video }
                      })}
                    >
                      <DeleteForeverIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              }

            </div>
          </React.Fragment>
        }

        {
          // コメント編集
          isShowCmntField[comment.key] && <div className={classes.commentForm}>
            <FormControl variant="outlined" className={classes.editFrom}>
              <OutlinedInput
                value={editComments[comment.key] ? editComments[comment.key].text : ''}
                onChange={(e) => handleDispatch({ type: changeEditValueType, data: e.target.value, field: comment.key })}
                labelWidth={0}
                aria-describedby=""
                inputProps={{ 'aria-label': comment.key }}
                className={clsx(classes.textField)}
                multiline
              />
            </FormControl>
          </div>
        }

      </ListItem>


      {/************* ▼▼▼ 動画 ▼▼▼ **************/}
      {
        ((openCommentClass && isVideo) || (isVideo && isShowCmntField[comment.key])) &&
          <div>
            <Vimeo
              videoId={videoId}
              isWidthUp={true}
              thisCase={comment}
              type="caseComments"
            />
          </div>
      }

      {
        /* 動画表示切り替えボタン */
        isVideo && !isShowCmntField[comment.key] && <Typography
          variant="caption"
          className={clsx({
            [classes.videoText]: true,
            [classes.videoTextOpen]: openCommentClass,
          })}
          onClick={() => handleDispatch({
            type: isShowComment,
            field: comment.key,
            data: !!openCommentClass ? false : true
          })}
        >
          { !openCommentClass ? '動画を見る' : '動画を隠す' }
          { !openCommentClass ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon /> }
        </Typography>
      }

      {
        isVideo && isShowCmntField[comment.key] && <Typography
          variant="caption"
          className={clsx(classes.videoDeleteCautionTxt)}
        >
          ※動画を変更したい場合、コメントを一度削除してから再投稿をお願いいたします。
        </Typography>
      }
      {/************* ▲▲▲ 動画 ▲▲▲ **************/}

      {/************* ▼▼▼ コメント編集・削除ボタン ▼▼▼ **************/}
      {
        isShowCmntField[comment.key] &&
        <div className={clsx(classes.editBtnGroup)}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.editBtn}
            onClick={() => commentActions.updateComment(
              handleDispatch, comment, editComments
            )}
          >
            投稿
          </Button>
          <Button
            variant="contained"
            className={classes.editBtn}
            onClick={() => handleDispatch({
              type: isShowCmntFieldType,
              field: comment.key, data: false
            })}
          >
            <CloseIcon />
          </Button>
        </div>
      }
      {/************* ▲▲▲ コメント編集・削除ボタン ▲▲▲ **************/}

      {/* いいね・返信ボタン・投稿時間 */}
      <Grid
        container
        className={clsx({
          'comment_item': true,
          [classes.commentBtm]: !isVideo,
        })}
        alignItems='center'
        justifyContent='flex-end'
      >

        {/* いいね */}
        {
          !!goods && <Grid
            item
            component={IconButton}
            className={classes.good}
            size='small'
            onClick={() => commentActions.changeGood(handleDispatch, goodTypeObj)}
          >
            <Typography
              className={classes.sup}
              style={{
                color: !!good ? commonTheme.palette.secondary.main : 'inherit',
                fontWeight: !!good ? 'bold' : 'normal',
              }}
            >いいね！</Typography>
            {goodNum > 0 && <Chip className='good' size="small" label={goodNum} />}
          </Grid>
        }

        {/* 返信ボタン */}
        {
          !!replyType && <Grid
            item
            component={IconButton}
            className={classes.sup}
            size='small'
            onClick={() => {
              handleDispatch({
                type: replyType,
                data: comment.user,
                contentType: contentType,
                key: commentKey,
              })
              handleDispatch({ type: 'CHANGE_SCROLL_TO', data: 'comment' })
            }}
          >返信する</Grid>
        }

        {/* 投稿時間 */}
        <Grid item component={Typography}
          className={clsx(classes.sup, 'comment_item_txt_time')}
          variant="caption"
        >
          {dayjs(comment.createdAt).tz().format('YY/MM/DD HH:mm')}
        </Grid>
      </Grid>

    </li>
  )
}
