import React, { useEffect, useContext } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { functions, database, caseRef } from "../../firebase/index"
import MODAL from "../../constants/modal"
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  Button,
  Typography,
} from '@material-ui/core'
import { } from '@material-ui/icons'
import { GlobalContext } from '../../hooks/reducer'
import { vimeo } from '../../config/vimeo'

// 時間の設定
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: '10px',
  },
  modal: {
    padding: '0 20px',
    margin: '-40px 0 10px',
  },
  progress: {
    padding: '83px',
  },
  noneBg: {
    '& .MuiDialog-container > div.MuiDialog-paper': {
      background: 'none',
      boxShadow: 'none',
    }
  },
}))

const CustomModal = React.memo(() => {
  const history = useHistory()
  const classes = useStyles()

  // 初回mount
  useEffect(() => {
    dispatch({ type: 'CHANGE_DISABLED', data: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { state: { common, shareCase }, dispatch } = useContext(GlobalContext)
  const {
    isOpen, modalState, deletePath, deleteUid, errors, progress, disabled, deleteVideo
  } = common
  const { caseComments } = shareCase

  const pos = deletePath.indexOf('/')
  const type = deletePath.slice(0, pos)
  const key = deletePath.slice(pos + 1)

  const handleDelete = async () => {
    let commentPath, goodPath, childKey, updateCaseKey = ''
    let isUpdateCase = false

    switch (type) {
      case 'caseComments':
        if (deleteVideo) {
          const deleteCmnt = caseComments.find(cmnt => cmnt.key === key)
          updateCaseKey = deleteCmnt.caseKey
          const isOtherFeedbackCmnt = caseComments.find(cmnt => {
            // NOTE: 削除するコメント以外にも動画ありのコメントがあるかどうか
            if (cmnt.key === key) return false
            if (cmnt.caseKey !== updateCaseKey) return false
            if (!cmnt.user.pajaposs) return false
            const notExistVideo = !cmnt.video || !cmnt.video.vimeoUrls || cmnt.video.vimeoUrls.length < 1
            if (notExistVideo) return false
            return true
          })
          // 1このときは、自分自身だから削除する
          if (!isOtherFeedbackCmnt) isUpdateCase = true
        }
        break
      case 'cases':
        commentPath = 'caseComments'
        goodPath = 'goods'
        childKey = 'caseKey'
        break
      case 'consults':
        commentPath = 'consultComments'
        goodPath = 'consultGoods'
        childKey = 'consultKey'
        break
      case 'infoComments':
        // goodPath = 'infoGoods'
        break
      default:
        break
    }

    const deleteData = async (dbPath) => {
      if (process.env.NODE_ENV === "development") { console.log("dbPath", dbPath) }
      let keys = []
      const setKeys = (arr) => (keys = arr)

      await database.ref(dbPath)
        .orderByChild(childKey)
        .startAt(key).endAt(key)
        .once('value', (snapshot) => {
          const values = !snapshot.val() ? {} : snapshot.val()
          return setKeys(Object.keys(values))
        })

      let deletes = {}
      keys.map(k => { return deletes[`${k}`] = null })
      return database.ref(dbPath).update(deletes)
    }

    database.ref(deletePath).remove()
      .then(async () => {
        console.log('deleted')

        if (!!commentPath) {
          // 紐づくコメント削除
          console.log('commentPath', commentPath)
          await deleteData(commentPath)
        }

        if (!!goodPath) {
          // いいねコメント削除
          console.log('goodPath', goodPath)
          await deleteData(goodPath)
        }

        if (!!deleteVideo) {
          deleteVideo.vimeoUrls.map(uri => vimeo.request({
            method: 'DELETE',
            path: uri
          }, (error, body, status_code, headers) => {
            if (error) { console.log('error', error) }
          }))

          // フィードバックタグの更新
          if (isUpdateCase) {
            const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss')
            caseRef.child(updateCaseKey).update({
              updateAt: now,
              isFeedback: false,
            })
          }
        }

        dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.deleted })
      }).catch((error) => {
        console.log(error)
        dispatch({ type: 'ERROR', data: error.message })
        dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.error })
      })
  }

  const handleMemberDelete = async (uid) => {
    dispatch({ type: 'CHANGE_DISABLED', data: true })
    const func = functions.httpsCallable("deleteMember")
    await func({ uid: uid }).then(res => {
      console.log('deleted')
      // userも削除
      database.ref(deletePath).remove()
        .then(() => {
          console.log('deleted')
          dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.deleted })
          dispatch({ type: 'DELETE_MEMBER', data: uid })
        }).catch((error) => {
          console.log(error)
          dispatch({ type: 'ERROR', data: error })
          dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.error })
        })
      // TODO: 投稿もすべて削除する？
    }).catch(error => {
      console.log(error)
      dispatch({ type: 'ERROR', data: '削除に失敗しました。' })
      dispatch({ type: 'CHANGE_MODAL_STAGE', data: MODAL.error })
    })
  }

  const WillDelete = () => {
    return <React.Fragment>
      <DialogTitle id=""> 本当に削除しますか？</DialogTitle>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.modal}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => dispatch({ type: 'IS_CLOSE' })}
          className={classes.submit}
        >いいえ</Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDelete()}
          className={classes.submit}
        >はい</Button>
      </Grid>
    </React.Fragment>
  }

  const WillMemberDelete = () => {
    return <React.Fragment>
      <DialogTitle id=""> 本当に削除しますか？</DialogTitle>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.modal}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => dispatch({ type: 'IS_CLOSE' })}
          className={classes.submit}
        >いいえ</Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleMemberDelete(deleteUid)}
          className={classes.submit}
          disabled={disabled}
        >はい</Button>
      </Grid>
    </React.Fragment>
  }

  const Deleted = () => {
    return <React.Fragment>
      <DialogTitle id="">削除が完了しました。</DialogTitle>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.modal}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            switch (type) {
              case 'caseComments':
                dispatch({ type: 'IS_CLOSE' })
                break
              case 'info':
                history.push('/admin')
                break
              case 'cases':
                history.push('/')
                break
              case 'consults':
                history.push('/consult')
                break
              default:
                dispatch({ type: 'IS_CLOSE' })
                break
            }
          }}
          className={classes.submit}
        >OK</Button>
      </Grid>
    </React.Fragment>
  }

  const Error = () => {
    return <React.Fragment>
      <DialogTitle id="">エラー</DialogTitle>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.modal}
      >
        <Typography variant="subtitle2" color='error'>{!!errors ? errors.message : 'エラー'}</Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            dispatch({ type: 'IS_CLOSE' })
            dispatch({ type: 'ERROR_RESET' })
          }}
          className={classes.submit}
        >OK</Button>
      </Grid>
    </React.Fragment >
  }

  const Progress = () => {
    return <React.Fragment>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.modal}
      >
        <Box className={classes.progress} display="inline-flex" >
          <CircularProgress size={100} variant="determinate" value={Math.round(progress)} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" component="div" color="textSecondary">
              {`${Math.round(progress)}%`}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </React.Fragment >
  }

  return <Dialog
    onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        dispatch({ type: 'IS_CLOSE' })
      }
    }}
    open={isOpen}
    className={(modalState === MODAL.progress) ? classes.noneBg : ''}
  >
    {modalState === MODAL.willMemberDelete && <WillMemberDelete />}
    {modalState === MODAL.willDelete && <WillDelete />}
    {modalState === MODAL.deleted && <Deleted />}
    {modalState === MODAL.error && <Error />}
    {modalState === MODAL.progress && <Progress />}
  </Dialog>
})

export default CustomModal
