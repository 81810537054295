import React, { useContext } from 'react'
import * as Scroll from 'react-scroll'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { GlobalContext } from '../../../hooks/reducer'
import { useLocation } from 'react-router'

const scroll = Scroll.animateScroll

const defaultStyle = {
  tags: {
    marginLeft: '-8px',
  },
  wrap: {
    margin: '15px 0',
  },
  tagWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
  },
  tag: {
    width: 'unset',
    padding: '0 8px',
    opacity: '1!important',
  },
  tagLabel: {
  },
  tagTxt: {
    "& > span": {
      fontSize: '0.9em',
      fontWeight: 'bold',
    }
  },
  label: {
    margin: 0,
    "& > span": {
      fontSize: '0.9em',
      fontWeight: 'normal',
    }
  },
}

const useStyles = makeStyles((theme) => (defaultStyle))
const useAccountStyles = makeStyles((theme) => ({
  label: {
    ...defaultStyle.label,
    "& > span": {
      fontSize: 'inherit',
      fontWeight: 'bold',
    },
  },
  tagTxt: {
    ...defaultStyle.tagTxt,
    "& > span": {
      fontSize: '0.9em',
      fontWeight: 'normal',
    }
  },
}))
const useOtherStyles = makeStyles((theme) => ({
  tagTxt: {
    ...defaultStyle.tagTxt,
    "& > span": {
      color: theme.palette.secondary.main,
    }
  },
}))

// タグ一覧
export const Tags = (props) => {
  const { tags, customStyle } = props
  const classes = useStyles()
  const accountClasses = useAccountStyles()
  const otherClasses = useOtherStyles()
  const location = useLocation()
  const isShareCase = location.pathname === `/`

  // 動的に取得
  const { industryTags, prefTags, memberNumTags, otherTags, isFeedback } = tags
  const { state: { shareCase }, dispatch } = useContext(GlobalContext)
  const { feedbackCaseTag } = shareCase

  const handleSearchTag = (tag) => {
    // 事例共有以外は検索しない
    if (!isShareCase) return
    dispatch({ type: 'CHANGE_SEARCH_TAG', data: tag })
    scroll.scrollToTop()
  }

  return (
    <List className={classes.tags}>
      {
        isFeedback && <div className={classes.wrap}>
          <div className={classes.tagWrap}>
            <ListItem button disabled={!isShareCase} className={classes.tag} onClick={() => handleSearchTag(feedbackCaseTag)}>
              <ListItemText
                primary={"#" + feedbackCaseTag.label}
                className={clsx(classes.tagTxt, otherClasses.tagTxt)}
              />
            </ListItem>
          </div>
        </div>
      }
      {
        industryTags && industryTags.length > 0 && <div className={classes.wrap}>
          <ListItem button disabled className={clsx(classes.tag, classes.tagLabel)}>
            <ListItemText
              primary='業界・業種'
              className={
                customStyle === 'account' ? accountClasses.label : classes.label
              }
            />
          </ListItem>
          <div className={classes.tagWrap}>
            {
              industryTags.map((tag, key) => (
                <ListItem button disabled={!isShareCase} key={key} className={classes.tag} onClick={() => handleSearchTag(tag)}>
                  <ListItemText
                    primary={"#" + tag.label}
                    className={
                      customStyle === 'account' ? accountClasses.tagTxt : classes.tagTxt
                    }
                  />
                </ListItem>
              ))
            }
          </div>
        </div>
      }
      {
        prefTags && prefTags.length > 0 && <div className={classes.wrap} >
          <ListItem button className={clsx(classes.tag, classes.tagLabel)} disabled>
            <ListItemText
              primary='所在地'
              className={
                customStyle === 'account' ? accountClasses.label : classes.label
              }
            />
          </ListItem>
          <div className={classes.tagWrap}>
            {
              prefTags.map((tag, key) => (
                <ListItem button disabled={!isShareCase} key={key} className={classes.tag} onClick={() => handleSearchTag(tag)}>
                  <ListItemText
                    primary={"#" + tag.label}
                    className={
                      customStyle === 'account' ? accountClasses.tagTxt : classes.tagTxt
                    }
                  />
                </ListItem>
              ))
            }
          </div>
        </div>
      }
      {
        memberNumTags && <div className={classes.wrap}>
          <ListItem button disabled className={clsx(classes.tag, classes.tagLabel)}>
            <ListItemText
              primary='社員数'
              className={
                customStyle === 'account' ? accountClasses.label : classes.label
              }
            />
          </ListItem>
          <div className={classes.tagWrap}>
            <ListItem button disabled={!isShareCase} className={classes.tag} onClick={() => handleSearchTag(memberNumTags)}>
              <ListItemText
                primary={"#" + memberNumTags.label}
                className={
                  customStyle === 'account' ? accountClasses.tagTxt : classes.tagTxt
                }
              />
            </ListItem>
          </div>
        </div>
      }
      {
        otherTags && otherTags.length > 0 && <div className={classes.wrap} >
          <ListItem button className={clsx(classes.tag, classes.tagLabel)} disabled>
            <ListItemText
              primary='その他'
              className={
                customStyle === 'account' ? accountClasses.label : classes.label
              }
            />
          </ListItem>
          <div className={classes.tagWrap}>
            {
              otherTags.map((tag, key) => (
                <ListItem button disabled={!isShareCase} key={key} className={classes.tag} onClick={() => handleSearchTag(tag)}>
                  <ListItemText
                    primary={"#" + tag.label}
                    className={
                      customStyle === 'account' ? accountClasses.tagTxt : classes.tagTxt
                    }
                  />
                </ListItem>
              ))
            }
          </div>
        </div>
      }
    </List>
  )
}
