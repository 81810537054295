import React, {
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  useLocation,
  useHistory,
} from 'react-router-dom'
import {
  Button,
} from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: 'bold',
    marginLeft: 'auto',
  }
}))

const HeaderButtons = React.memo((props) => {
  const history = useHistory()
  const classes = useStyles()
  const location = useLocation()

  const isPC = isWidthUp('sm', props.width)

  let button = {}
  switch (location.pathname) {
    case '/':
      button = {
        show: true,
        text: isPC ? '事例を共有する' : '投稿',
        path: '/case/post',
      }
      break
    case '/consult':
      button = {
        show: true,
        text: '相談する',
        path: '/consult/post',
      }
      break
    default:
      button = {
        show: false,
      }
      break
  }

  return (
    button.show && <Button
      variant="contained"
      color="secondary"
      className={classes.button}
      onClick={() => history.push(button.path)}
    >
      {button.text}
    </Button>
  )
})

export default withWidth()(HeaderButtons)
